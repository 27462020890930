import { useMemo } from "react";
import { Country } from "react-country-state-city/dist/esm/types";

import { AddressState } from "@schema";

interface UseAddressInputsRenderingProps {
  field: { value: AddressState };
  allowInternationalAddress: boolean;
  showUseCorrespondenceAddress: boolean;
  fetchError: boolean;
  correspondenceAddressCountryInfo?: Country["iso2"];
  isManualAddressOpen: boolean;
}

export const useAddressInputsRendering = ({
  field,
  allowInternationalAddress,
  showUseCorrespondenceAddress,
  fetchError,
  correspondenceAddressCountryInfo,
  isManualAddressOpen,
}: UseAddressInputsRenderingProps) => {
  const hasSelectedCountry = Boolean(field.value.country);

  /* Render AddressFinder and ManualAddress if:
     - Address can NOT be international
     - Address can be international AND a country is selected
     - There is an error fetching countries 
  */
  const renderAddressFinder =
    !allowInternationalAddress || hasSelectedCountry || fetchError;

  const renderManualAddress = renderAddressFinder;

  // Render CountrySelector if address can be international AND there is NO error fetching countries
  const renderCountrySelector = allowInternationalAddress && !fetchError;

  // Render option to use correspondence address if the country is GB
  const canUseCorrespondenceAddress =
    showUseCorrespondenceAddress && correspondenceAddressCountryInfo === "GB";

  // Render save address button if manual address is open
  const renderSaveAddressButton = isManualAddressOpen;

  // Display country field in manual address if there is an error fetching countries
  const displayCountryField = useMemo(() => fetchError, [fetchError]);

  return {
    renderAddressFinder,
    renderManualAddress,
    renderCountrySelector,
    canUseCorrespondenceAddress,
    renderSaveAddressButton,
    displayCountryField,
  };
};
