import { Container } from "../Container/Container";

import Icons from "@/foundation/Icons/Icons";
import { Button } from "@/components/Button/Button";
import Typography from "@/foundation/Typography/Typography";
import { Help } from "../Help/Help";

interface FooterProps {
  handleBack?: () => void;
  handleContinue?: () => void;
  isValid?: boolean;
  continueTitle?: string;
  hasValidation?: boolean;
  disableContinue?: boolean;
}

export const Footer = ({
  handleBack,
  handleContinue,
  isValid,
  continueTitle = "Continue",
  hasValidation = true,
  disableContinue = false,
}: FooterProps) => (
  <div
    className="w-full border-t-2 border-brand-heavy-warmth-100 min-h-[250px] pt-[24px] mt-[100px]"
    data-testid="footer"
  >
    <Container>
      <div className="flex flex-col-reverse md:flex-row flex-shrink-0 justify-between items-center">
        {handleBack && (
          <Button
            variant="secondary"
            type="button"
            onClick={handleBack}
            className="w-full md:w-[120px]"
          >
            Back
          </Button>
        )}

        {!disableContinue && (
          <div className="w-full flex flex-col items-center md:flex-row md:justify-end mb-[16px]">
            {hasValidation && !isValid && (
              <div className="flex items-center mb-[16px] md:mb-0">
                <div className="mr-[12px]">
                  <Icons iconName="Info" size="24" colour="error" />
                </div>

                <Typography
                  type="span"
                  variant="sm"
                  weight="semi-bold"
                  className="text-ui-error-100 text-left w-full md:max-w-[400px] pr-[16px]"
                >
                  The information you have entered is invalid. <br />
                  Please check each field for errors and try again.
                </Typography>
              </div>
            )}

            <Button
              variant="primary"
              type="submit"
              disabled={hasValidation && !isValid}
              onClick={handleContinue}
              className="w-full md:w-[250px]"
            >
              {continueTitle}
            </Button>
          </div>
        )}
      </div>

      <Help />
    </Container>
  </div>
);
