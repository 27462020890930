import React from "react";
import { Typography } from "@pongo";

interface ConfirmationMessageProps {
  confirmationDescription: string;
  nextStepsDescription: string;
}

export const ConfirmationMessage: React.FC<ConfirmationMessageProps> = ({
  confirmationDescription,
  nextStepsDescription,
}) => (
  <div className="max-w-[700px]">
    <Typography
      type="p"
      variant="base"
      weight="regular"
      className="mt-[24px] mb-[36px] text-brand-heavy-teal-100"
    >
      {confirmationDescription}
    </Typography>

    <Typography
      type="h2"
      variant="h3"
      weight="bold"
      className="mb-[12px] text-brand-heavy-teal-100 font-display"
    >
      Next steps
    </Typography>

    <Typography
      type="p"
      variant="base"
      weight="regular"
      className="mb-[36px] text-brand-heavy-teal-100"
    >
      {nextStepsDescription}
    </Typography>

    <Typography
      type="p"
      variant="base"
      weight="regular"
      className="mb-[24px] text-brand-heavy-teal-100"
    >
      Click the link below to explore your property dashboard and find out more.
    </Typography>
  </div>
);
