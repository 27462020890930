import { z } from "zod";

import { relationshipType } from "@shared/types";

import { PersonalDetailsSchema } from "./PersonalDetailsSchema";
import { internationalAddressSchema } from "./AddressSchema";
import { requiredField } from "./helpers";

export const GiftorSchema = z.object({
  personId: z.string(),
  amount: z.string().refine(
    (amount) => {
      const numericAmount = parseFloat(amount.replace(/,/g, "")); // Remove commas and convert to float
      return !isNaN(numericAmount) && numericAmount > 4;
    },
    {
      message: "Amount must be higher than £0",
    },
  ),
  firstName: PersonalDetailsSchema.shape.firstName,
  middleName: PersonalDetailsSchema.shape.middleName,
  lastName: PersonalDetailsSchema.shape.lastName,
  email: PersonalDetailsSchema.shape.email,
  mobileNumber: PersonalDetailsSchema.shape.mobileNumber,
  relationship: requiredField(z.enum(relationshipType)),
  correspondenceAddress: internationalAddressSchema,
});
