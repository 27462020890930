import { ReactElement, ReactNode } from "react";
import { Typography } from "../../foundation/Typography/Typography";

interface ColumnProps<T> {
  key: string;
  title: string | ReactElement;
  rowCustomRender?: (item: T) => ReactElement;
}

interface TableRowCellProps {
  value: string;
  colSpan?: number;
  centerText?: boolean;
}

interface TableHeaderProps {
  name: string;
  isFirstColumn?: boolean;
  isLastColumn?: boolean;
}

interface TableHeadersProps<T> {
  columns: Array<ColumnProps<T>>;
}

export interface TableRow<T> {
  data: T;
  onClick?: () => void;
}

export type TableProps<T> = {
  columns: Array<ColumnProps<T>>;
  rows?: TableRow<T>[];
};

const TableHeader = ({ name }: TableHeaderProps) => (
  <Typography type="p" variant="sm" weight="bold" className="text-left">
    {name}
  </Typography>
);

const TableHeaders = <T,>({ columns }: TableHeadersProps<T>) => (
  <thead className="bg-brand-heavy-warmth-25">
    <tr>
      {columns.map((column: ColumnProps<T>, index) => {
        const isFirstColumn = index === 0;
        const isLastColumn = index === columns.length - 1;

        let thClasses = "py-[8px] px-[12px] text-nowrap";

        if (isFirstColumn) {
          thClasses += " rounded-tl-lg";
        }

        if (isLastColumn) {
          thClasses += " rounded-tr-lg";
        }

        return (
          <th className={thClasses} key={`column-${column.key}`}>
            {typeof column.title === "string" ? (
              <TableHeader name={column.title} />
            ) : (
              (column.title as ReactNode)
            )}
          </th>
        );
      })}
    </tr>
  </thead>
);

const TableRowCell = ({ value, centerText = false }: TableRowCellProps) => (
  <Typography
    type="p"
    variant="sm"
    weight="regular"
    className={`min-w-[100px] ${centerText ? "text-center" : ""}`}
  >
    {value}
  </Typography>
);

const TableRows = <T,>({ columns, rows }: TableProps<T>) => {
  return !rows?.length ? (
    <tr>
      <td className="p-[12px]" colSpan={columns.length}>
        <TableRowCell colSpan={columns.length} value="No Data" centerText />
      </td>
    </tr>
  ) : (
    rows?.map((row, index) => {
      const { data, onClick } = row;

      let rowClasses = "border-t border-brand-heavy-teal-10 text-nowrap";

      if (onClick) {
        rowClasses +=
          " cursor-pointer hover:bg-brand-heavy-teal-10 hover:text-brand-heavy-teal-100";
      }

      return (
        <tr key={`row-${index}`} onClick={onClick} className={rowClasses}>
          {columns.map((column, index2) => (
            <td className="p-[12px]" key={`cell-${index2}`}>
              {column.rowCustomRender ? (
                column.rowCustomRender(data as T)
              ) : (
                <TableRowCell
                  value={data[column.key as keyof typeof data] as string}
                />
              )}
            </td>
          ))}
        </tr>
      );
    })
  );
};

export const Table = <T,>({ columns, rows }: TableProps<T>) => (
  <div className="flex flex-col bg-ui-white-100 rounded-lg overflow-x-scroll">
    <table>
      <TableHeaders columns={columns} />

      <tbody>
        <TableRows columns={columns} rows={rows} />
      </tbody>
    </table>
  </div>
);
