import { useState } from "react";
import { FindSuggestedAddressesResponse } from "../../../../service/ApiController/findSuggestedAddresses";

export const useAddressFinderState = () => {
  const [query, setQuery] = useState("");
  const [suggestedAddresses, setSuggestedAddresses] =
    useState<FindSuggestedAddressesResponse>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return {
    query,
    setQuery,
    suggestedAddresses,
    setSuggestedAddresses,
    loading,
    setLoading,
    error,
    setError,
    activeIndex,
    setActiveIndex,
    isDropdownOpen,
    setIsDropdownOpen,
  };
};
