import { useEffect } from "react";
import { useFormikContext } from "formik";

import { Input } from "../../../Formik/Input/Input";
import { formatPostcode } from "@shared/utils";

interface ManualAddressFormProps {
  namespace: string;
  displayCountryField?: boolean;
  isUKAddress?: boolean;
}

export const ManualAddressForm = ({
  namespace,
  displayCountryField = false,
  isUKAddress,
}: ManualAddressFormProps) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (isUKAddress) {
      setFieldValue(`${namespace}.country_iso_2`, "GB");
      setFieldValue(`${namespace}.country`, "United Kingdom");
    }
  }, [isUKAddress, setFieldValue, namespace]);

  return (
    <>
      <Input fieldName={`${namespace}.line_1`} label="Address line 1" />
      <Input
        fieldName={`${namespace}.line_2`}
        label="Address Line Two (optional)"
      />
      <Input
        fieldName={`${namespace}.line_3`}
        label="Address Line Three (optional)"
      />
      <Input fieldName={`${namespace}.post_town`} label="Town" />
      {displayCountryField && (
        <Input fieldName={`${namespace}.country`} label="Country" />
      )}
      <Input
        fieldName={`${namespace}.postcode`}
        label="Postcode"
        onChange={(newVal) => {
          setFieldValue(
            `${namespace}.postcode`,
            formatPostcode(newVal.target.value, isUKAddress ? "GB" : ""),
          );
        }}
      />
    </>
  );
};
