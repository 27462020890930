import { Input } from "../../../Formik/Input/Input";
import { FieldSet } from "../../FieldSet/FieldSet";
import { PhoneInputField } from "./PhoneNumberField";

interface BasicDetailsFormProps {
  isAdditionalSeller: boolean;
}

export const BasicDetailsForm = ({
  isAdditionalSeller,
}: BasicDetailsFormProps) => {
  return (
    <>
      <FieldSet
        legend="Legal name"
        description={
          !isAdditionalSeller
            ? "As shown on your passport or other form of identification."
            : ""
        }
      >
        <div
          className={`flex flex-col md:flex-row md:flex-wrap gap-[16px] w-[100%] ${!isAdditionalSeller && "gap-[16px]"}`}
        >
          <div className="w-[100%] md:max-w-[320px]">
            <Input fieldName="firstName" label="First name" />
          </div>
          <div className="w-[100%] md:max-w-[320px]">
            <Input fieldName="middleName" label="Middle name (optional)" />
          </div>
          <div className="w-[100%] md:max-w-[320px]">
            <Input fieldName="lastName" label="Last name" />
          </div>
        </div>
      </FieldSet>

      <FieldSet legend="Contact details">
        <div className="flex flex-col md:flex-row md:flex-wrap gap-[16px] w-full">
          <div className="w-[100%] md:max-w-[320px]">
            <PhoneInputField />
          </div>
          {isAdditionalSeller && (
            <div>
              <Input fieldName="email" label="Email" />
            </div>
          )}
        </div>
      </FieldSet>
    </>
  );
};
