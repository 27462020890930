/**
 * @file useFetchTransactions.tsx
 * @summary Custom hook to fetch Transactions and Purchase Intents for a user using the user's ID.
 *
 */

import { useCallback, useEffect } from "react";

import { sortMostRecent } from "../utils/sortMostRecent";
import { ApiController } from "../service/ApiController";
import { UserType } from "../store/auth/auth";
import { TransactionResponse } from "@shared/types";

export const useFetchTransactions = (
  userData: UserType | null,
  setSales: React.Dispatch<React.SetStateAction<TransactionResponse[]>>,
  setPurchases: React.Dispatch<React.SetStateAction<TransactionResponse[]>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const fetchTransactions = useCallback(async () => {
    if (!userData) return;

    try {
      const [salesResponse, purchasesResponse] = await Promise.all([
        ApiController.findTransactionsByUserId(userData._id),
        ApiController.findTransactionsByPurchaseIntentUserId(userData._id),
      ]);

      setSales(sortMostRecent(salesResponse));
      setPurchases(sortMostRecent(purchasesResponse));
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
    } finally {
      setIsLoading(false);
    }
  }, [userData, setSales, setPurchases, setIsLoading]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);
};
