import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icons, Typography } from "@pongo";

import { useAuthStore } from "../../store/auth/auth";
import { useSignOut } from "../../hooks";

import Logo from "/logos/horizontal.svg";

interface NavProps {
  showSwitcher?: boolean;
}

export const Nav: React.FC<NavProps> = ({ showSwitcher = true }) => {
  const signOut = useSignOut();
  const { userData } = useAuthStore();
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    if (userData?.active && userData?.email) {
      setIsSignedIn(true);
    }
  }, [userData, isSignedIn]);

  return (
    <div
      className="w-full bg-brand-heavy-warmth-75 h-20 flex justify-between items-center px-[16px] md:px-[40px]"
      data-testid="nav"
    >
      <img src={Logo} width={120} />

      <div className="flex flex-row space-x-[12px] items-center">
        {isSignedIn && (
          <>
            {showSwitcher && (
              <Link to={"/welcome-back"} className="flex items-center">
                <Icons iconName="AllTransactions" size="18" colour="#40656D" />

                <Typography
                  type="p"
                  variant="xs"
                  weight="regular"
                  className="test-[#1C274C] text-nowrap overflow-clip mx-[4px] hidden md:inline"
                >
                  Switch Properties
                </Typography>
              </Link>
            )}

            <div className="flex items-center rounded-lg bg-brand-heavy-warmth-100/80 p-[8px]">
              <Icons iconName="UserAccount" size="18" colour="#40656D" />

              <Typography
                type="p"
                variant="xs"
                weight="regular"
                className="test-[#1C274C] text-nowrap overflow-clip mx-[4px] hidden md:inline"
              >
                {userData?.first_name} {userData?.last_name}
              </Typography>
            </div>

            {userData?.type === "admin" && (
              <Link
                to={"/admin"}
                className="flex items-center rounded-lg bg-brand-heavy-teal-100/10 p-[8px]"
              >
                <Icons iconName="Inbox" size="18" colour="#40656D" />

                <Typography
                  type="p"
                  variant="xs"
                  weight="regular"
                  className="test-[#1C274C] text-nowrap overflow-clip mx-[4px] hidden md:inline"
                >
                  Admin
                </Typography>
              </Link>
            )}

            <button onClick={() => signOut()}>
              <div className="flex items-center group relative">
                <div className="transform rotate-180">
                  <Icons iconName="SignOut" size="18" colour="#40656D" />
                </div>

                <Typography
                  variant="xs"
                  weight="regular"
                  className="test-[#1C274C] text-nowrap overflow-clip mx-[4px] hidden md:inline"
                >
                  Sign Out
                </Typography>
              </div>
            </button>
          </>
        )}
      </div>
    </div>
  );
};
