import React from "react";
import { Typography } from "@pongo";

import { AdminTransaction } from "../../TransactionDetails/types";
import { formatCreatedDate } from "@shared/utils";

interface FeedItemHeaderProps {
  transaction: AdminTransaction;
}

export const FeedItemHeader: React.FC<FeedItemHeaderProps> = ({
  transaction,
}) => {
  const createdAt = new Date(transaction.createdAt);
  const displayDate = formatCreatedDate(createdAt);

  return (
    <div className="flex flex-row mt-[12px]">
      <Typography
        type="p"
        variant="xs"
        weight="semi-bold"
        className="text-brand-heavy-teal-100/30"
      >
        {displayDate}
      </Typography>
    </div>
  );
};
