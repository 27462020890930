export const typosErrorPostcodeMessage =
  "We couldn't find that postcode. Please check for any typos and try again. If you're sure the postcode is right, then before continuing, please contact our team at ";

export const propertyNotListedErrorMessage =
  "Sorry, we couldn't find your property in our database. Before you continue, please contact our team at ";

export const invalidPostcodeErrorMessage = "Invalid postcode";

export const typosErrorAddressMessage =
  "We couldn't find that address. Please check for any typos and try again. If you're sure the address is right, then before continuing, please contact our team at ";
