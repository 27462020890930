import React, { useEffect, useState } from "react";
import { Typography } from "@pongo";

import { AdminTransaction } from "../../TransactionDetails/types";
import { ApiController } from "../../../../service/ApiController";
import { EventType } from "../../../types/types";
import { toTitleCase, formatEventName } from "@shared/utils";

// Cache for events per transaction id
const eventCache: Record<string, EventType | null> = {};

interface FeedItemLatestEventProps {
  transaction: AdminTransaction;
}

export const FeedItemLatestEvent: React.FC<FeedItemLatestEventProps> = ({
  transaction,
}) => {
  const [latestEvent, setLatestEvent] = useState<EventType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const fetchLatestEvent = async () => {
      if (!transaction) {
        setError("Transaction not found");
        return;
      }

      // Use the cached event if it exists (even if null)
      if (eventCache[transaction._id] !== undefined) {
        setLatestEvent(eventCache[transaction._id]);
        return;
      }

      setLoading(true);
      setError("");

      try {
        const events: EventType[] =
          await ApiController.fetchTransactionEventsTimeline(transaction._id);

        // Determine the latest event by comparing date_time values
        const latest = events.reduce<EventType | null>(
          (prev, curr) =>
            !prev || new Date(curr.date_time) > new Date(prev.date_time)
              ? curr
              : prev,
          null,
        );

        setLatestEvent(latest);
        // Cache the result (null indicates no event found)
        eventCache[transaction._id] = latest;
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred",
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLatestEvent();
  }, [transaction]);

  const formattedEvent =
    latestEvent && latestEvent.type && latestEvent.event_name
      ? `${toTitleCase(latestEvent.type)} - ${formatEventName(
          latestEvent.event_name,
        )}`
      : "No event found";

  const content = loading ? "Loading events..." : error || formattedEvent;

  return (
    <div className="flex flex-row items-center">
      <Typography
        type="h2"
        variant="xs"
        weight="bold"
        className="text-brand-heavy-teal-100/30 mr-[8px]"
      >
        Latest Event
      </Typography>

      <div className="w-fit flex flex-col items-start bg-brand-heavy-warmth-100/20 rounded-lg p-[12px] pr-[12px]">
        <Typography
          type="p"
          variant="xs"
          weight="semi-bold"
          className="text-brand-heavy-teal-100"
        >
          {content}
        </Typography>
      </div>
    </div>
  );
};
