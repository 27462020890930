import { useState, useEffect } from "react";
import { Card, Typography, LinkWrapper } from "@pongo";

import lumonLogoUrl from "@assets/logos/lumon-logo.svg";
import propelrLogoUrl from "@assets/logos/orange-propelr-logo.svg";
import crossUrl from "@assets/cross.svg";

interface ForeignCurrencyCardProps {
  isUsingForeignCurrency: boolean | undefined;
}

export const ForeignCurrencyCard = ({
  isUsingForeignCurrency,
}: ForeignCurrencyCardProps) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (isUsingForeignCurrency) {
      setTimeout(() => setIsVisible(true), 100);
    } else {
      setIsVisible(false);
    }
  }, [isUsingForeignCurrency]);

  return (
    <div className="overflow-hidden">
      {isUsingForeignCurrency && (
        <div
          className={`transition-all duration-500 ease-in-out ${
            isVisible ? "translate-y-0" : "-translate-y-full"
          }`}
        >
          <Card
            backgroundColour="bg-other-lumon-navy"
            containerClasses="border-0 border-b-6 border-other-lumon-red"
          >
            <div className="max-w-[600px]">
              <div className="flex items-end mb-[24px]">
                <img
                  src={propelrLogoUrl}
                  className="relative bottom-[-5px] h-[34px]"
                />

                <img
                  src={crossUrl}
                  className="w-[18px] mb-[4px] ml-[8px] mr-[12px]"
                />

                <img src={lumonLogoUrl} />
              </div>

              <Typography
                variant="base"
                weight="regular"
                className="text-ui-white-100 mb-[12px]"
              >
                As part of the Propelr service, we will introduce you to our
                currency partner,{" "}
                <LinkWrapper>
                  <a
                    href="http://lumonpay.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>Lumon</strong>
                  </a>
                </LinkWrapper>
                , who help clients manage currency risk and international
                payments.
              </Typography>

              <Typography
                variant="base"
                weight="regular"
                className="text-ui-white-100"
              >
                We highly recommend speaking with them, even if you have another
                provider in mind. Many clients find the introduction valuable
                and appreciate the service afterward.
              </Typography>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
