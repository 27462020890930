/**
 * @file useAuthenticateUser.ts
 * @module useAuthenticateUser
 *
 * @description
 * This module exports the custom hook `useAuthenticateUser` which encapsulates the logic for
 * authenticating a user based on URL query parameters. The hook performs the following tasks:
 *
 * - Extracts the authentication token, user type, and user role from the URL query parameters.
 * - Verifies the user's token by calling the API via {@link ApiController.verifyUser}.
 * - Clears specific local storage keys using {@link clearLocalStorage} after successful verification.
 * - Reinitializes the authentication state via the {@link AuthContext} method `reinitializeAuth`.
 * - Navigates the user to an appropriate route based on their user type and role.
 * - Redirects the user to a "link expired" page if authentication fails or the token is missing.
 *
 * @see {@link ApiController} for API-related methods.
 * @see {@link clearLocalStorage} for clearing local storage.
 */

import { useEffect, useContext, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ApiController } from "../service/ApiController";
import { AuthContext } from "../store/auth/AuthContext";
import { useLoginStore } from "../store/Login/login";
import { useAuthStore } from "../store/auth/auth";

import { Role as RoleType } from "@shared/types";
import { clearLocalStorage } from "@shared/utils/clearLocalStorage";

export const useAuthenticateUser = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useContext(AuthContext);
  const { setRole } = useLoginStore();
  const { userData } = useAuthStore();

  // Extract token, type, and role from query parameters
  const { token, type, role } = useMemo(() => {
    const extractedRole = searchParams.get("role") as RoleType | null;
    return {
      token: searchParams.get("token"),
      type: searchParams.get("type"),
      role: extractedRole,
    };
  }, [searchParams]);

  // Helper to navigate the user based on type and role
  const navigateUser = useCallback(
    (type: string | null, role: RoleType | null): void => {
      if (type === "admin") {
        navigate("/admin", { replace: true });
      } else {
        switch (role) {
          case "sell":
            navigate("/sell/welcome", { replace: true });
            break;
          case "buy":
            navigate("/buy/welcome", { replace: true });
            break;
          default:
            navigate("/welcome-back", { replace: true });
            break;
        }
      }
    },
    [navigate],
  );

  // Authenticate the user based on query parameters
  const authenticateUser = useCallback(async (): Promise<void> => {
    if (!token) {
      navigate("/authenticate/link-expired", { replace: true });
      return;
    }

    if (!role && type === "customer") {
      Sentry.captureException(
        new Error(
          `Role is missing in query params for customer. URL: ${window.location.href}`,
        ),
      );
    }

    // Set the user's role
    setRole(role as RoleType);

    try {
      await ApiController.verifyUser(token);
      clearLocalStorage();
      auth?.reinitializeAuth();

      if (userData) {
        // Navigate the user after successful verification
        navigateUser(type, role);
      }
    } catch (error) {
      Sentry.captureException(error, {
        level: "warning",
        extra: {
          message: "User could not be verified, magic link has likely expired",
        },
      });
      navigate("/authenticate/link-expired", { replace: true });
    }
  }, [token, type, role, auth, setRole, navigateUser, navigate, userData]);

  // Trigger the authentication process on mount
  useEffect(() => {
    authenticateUser();
  }, [authenticateUser]);
};
