import { Route } from "react-router-dom";

import { SellerLandingPage } from "../marketing/";
import { PrivacyPolicy } from "../marketing/pages/PrivacyPolicy";
import { CookiePolicy } from "../marketing/pages/CookiePolicy";
import { PartnerSignUp } from "../pages/PartnerSignUp/PartnerSignUp";

export const MarketingRoutes = () => (
  <Route>
    <Route path="/" element={<SellerLandingPage />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/cookie-policy" element={<CookiePolicy />} />
    <Route path="/partner-sign-up" element={<PartnerSignUp />} />
  </Route>
);
