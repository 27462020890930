import React, { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@pongo";

import { Background } from "../../components/Background/Background";
import { Container } from "../../components/Container/Container";
import { Header } from "../../components/Header/Header";
import { Nav } from "../../components/Nav/Nav";
import { TransactionsList } from "./TransactionsList";
import { FullPageLoader } from "../FullPageLoader/FullPageLoader";

import { PurchaseIntentResponse, TransactionResponse } from "@shared/types";
import { useAuthStore } from "../../store/auth/auth";
import { useLoginStore } from "../../store/Login/login";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import { useFetchTransactions } from "../../hooks/useFetchTransactions";
import {
  transformTransactionForLocalStorage,
  transformPurchaseIntentForLocalStorage,
} from "@transformers";

export const WelcomeBack: React.FC = () => {
  const navigate = useNavigate();
  const { setRole } = useLoginStore();
  const { userData } = useAuthStore();
  const { updateTransaction } = useTransactionFormStore();
  const { updatePurchaseIntent } = usePurchaseIntentFormStore();

  const [sales, setSales] = useState<TransactionResponse[]>([]);
  const [purchases, setPurchases] = useState<TransactionResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Call custom hooks and memoized hooks unconditionally.
  useFetchTransactions(userData, setSales, setPurchases, setIsLoading);

  const latestSale = useMemo(
    () => (sales.length > 0 ? sales[0] : null),
    [sales],
  );
  const latestPurchase = useMemo(
    () => (purchases.length > 0 ? purchases[0] : null),
    [purchases],
  );

  const previousSales = useMemo(() => sales.slice(1), [sales]);
  const previousPurchases = useMemo(() => purchases.slice(1), [purchases]);

  const handleResumeTransaction = useCallback(
    (transactionId: string, type: "sale" | "purchase") => {
      if (!userData) {
        console.error("No user data available");
        return;
      }
      const transactions = type === "sale" ? sales : purchases;
      const transaction = transactions.find((t) => t._id === transactionId);

      if (!transaction) {
        console.error("Transaction not found");
        return;
      }

      if (type === "sale") {
        updateTransaction(transformTransactionForLocalStorage(transaction));
        setRole("sell");
        navigate("/sell/payment");
        return;
      }

      // For purchase transactions, find the matching purchase intent.
      const currentPurchaseIntent = transaction.purchase_intent.find((intent) =>
        intent.buyers.some((buyer) => buyer.user._id === userData._id),
      ) as PurchaseIntentResponse | undefined;

      if (!currentPurchaseIntent) {
        console.error("Matching purchase intent not found");
        return;
      }

      const propertyAddress = transaction.property_id.address;
      updatePurchaseIntent(
        transformPurchaseIntentForLocalStorage(
          currentPurchaseIntent,
          propertyAddress,
        ),
      );
      setRole("buy");
      navigate("/buy/whats-included");
    },
    [
      sales,
      purchases,
      updateTransaction,
      updatePurchaseIntent,
      setRole,
      navigate,
      userData,
    ],
  );

  // Conditionally render loader if userData is not available or if data is still loading.
  if (!userData || isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Background className="flex flex-col">
      <Nav showSwitcher={false} />

      <Container>
        <div className="flex flex-col px-[24px] lg:px-0">
          <Header
            title={`Welcome back, ${userData.first_name}!`}
            noBorder
            showTransactionType={false}
          />

          <Typography
            type="p"
            variant="base"
            weight="semi-bold"
            className="text-brand-heavy-teal-100 max-w-[900px] mb-[12px]"
          >
            Ready to pick up where you left off? Choose a property below to
            continue your ongoing sale or purchase.
          </Typography>

          <Typography
            type="p"
            variant="base"
            weight="semi-bold"
            className="text-brand-heavy-teal-100 max-w-[900px] mb-[36px]"
          >
            If you don&apos;t see your property here, simply browse your archive
            to review any previous transactions.
          </Typography>

          <div className="flex flex-col">
            <Typography
              type="h2"
              variant="h3"
              weight="bold"
              className="text-brand-heavy-teal-100 max-w-[900px] mb-[16px]"
            >
              Your sales
            </Typography>

            <TransactionsList
              latestTransactions={latestSale ? [latestSale] : []}
              allTransactions={previousSales}
              type="sell"
              handleResumeTransaction={(id) =>
                handleResumeTransaction(id, "sale")
              }
            />

            <Typography
              type="h2"
              variant="h3"
              weight="bold"
              className="text-brand-heavy-teal-100 max-w-[900px] mb-[16px]"
            >
              Your purchases
            </Typography>

            <TransactionsList
              latestTransactions={latestPurchase ? [latestPurchase] : []}
              allTransactions={previousPurchases}
              type="buy"
              handleResumeTransaction={(id) =>
                handleResumeTransaction(id, "purchase")
              }
            />
          </div>
        </div>
      </Container>
    </Background>
  );
};
