import { create } from "zustand";
import { persist } from "zustand/middleware";

import {
  PersonalDetailsWithAddressState,
  SellerPropertyDetailsState,
} from "@schema";

import {
  DateSolictorGroupsAssigned,
  Order,
} from "../../admin/pages/TransactionDetails/types";
import {
  initialPersonalDetailsStateObj,
  initialSellerPropertyDetailsStateObj,
} from "../initialDetails";

export type TransactionFormState = {
  _id: string;
  primarySeller: PersonalDetailsWithAddressState;
  property: SellerPropertyDetailsState;
  orders: Order[];
  events: Event[];
  date_solicitor_groups_assigned?: DateSolictorGroupsAssigned;
  schemaVersion: number;
};

type State = {
  transaction: TransactionFormState;
  updateTransaction: (
    updatedTransaction: Partial<TransactionFormState>,
  ) => void;
  resetTransaction: () => void;
};

export const initialTransactionStateObj: TransactionFormState = {
  _id: "",
  primarySeller: { ...initialPersonalDetailsStateObj, primaryCustomer: true },
  property: initialSellerPropertyDetailsStateObj,
  orders: [],
  events: [],
  schemaVersion: 1, // This is an initial value, after creating a transaction, this will be updated to the latest schema version in the backend
};

export const useTransactionFormStore = create<State>()(
  persist(
    (set) => ({
      transaction: initialTransactionStateObj,

      updateTransaction: (
        updatedTransaction: Partial<TransactionFormState>,
      ) => {
        set((state) => ({
          transaction: {
            ...state.transaction,
            ...updatedTransaction,
          },
          updateTransaction: state.updateTransaction,
        }));
      },

      resetTransaction: () => {
        set(() => ({
          transaction: initialTransactionStateObj,
        }));
      },
    }),
    {
      name: "transactionForm",
    },
  ),
);
