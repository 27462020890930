// This function transforms the Purchase Intent from the database into
// the Property state value used in the Purchase Intent local storage object

import { BuyerPropertyDetailsState } from "@schema";

import { Address } from "../admin/pages/UserDetails/types";
import { PurchaseIntentResponse } from "@shared/types";

export const transformPurchaseIntentPropertyState = (
  purchaseIntent: PurchaseIntentResponse,
  propertyAddress: Address,
): BuyerPropertyDetailsState => {
  const primaryBuyerGiftors: BuyerPropertyDetailsState["giftors"] =
    purchaseIntent.buyers.flatMap((buyer) => {
      if (buyer.primary_customer) {
        return buyer.giftors.map((giftor) => ({
          personId: giftor.person_id,
          amount: giftor.amount.toString(),
          firstName: giftor.first_name,
          lastName: giftor.last_name,
          middleName: giftor.middle_name,
          email: giftor.email,
          mobileNumber: giftor.phone_number, // FIXME: we should rename mobile number to phone number everywhere
          relationship: giftor.relationship,
          correspondenceAddress: giftor.correspondence_address,
        }));
      }
      return [];
    });

  const additionalBuyers: BuyerPropertyDetailsState["additionalBuyers"] =
    purchaseIntent.buyers
      .map((buyer) => {
        if (!buyer.primary_customer) {
          return {
            personId: buyer.user._id || "",
            firstName: buyer.user.first_name || "",
            middleName: buyer.user.middle_name || "",
            lastName: buyer.user.last_name || "",
            email: buyer.user.email,
            mobileNumber: buyer.user.profile?.phone_number || "",
            termsAndConditions: buyer.terms_and_conditions || false,
            primaryCustomer: buyer.primary_customer,
            customerType: buyer.customer_type,
            correspondenceAddress: {
              line_1: buyer.user.profile?.correspondence_address?.line_1 || "",
              line_2:
                buyer.user.profile?.correspondence_address?.line_2 || undefined,
              line_3: buyer.user.profile?.correspondence_address?.line_3 || "",
              post_town:
                buyer.user.profile?.correspondence_address?.post_town || "",
              country:
                buyer.user.profile?.correspondence_address?.country || "",
              postcode:
                buyer.user.profile?.correspondence_address?.postcode || "",
              country_iso_2:
                buyer.user.profile?.correspondence_address?.country_iso_2 || "",
            },
          };
        }
        return null;
      })
      .filter((buyer): buyer is NonNullable<typeof buyer> => buyer !== null);

  const otherFunding =
    purchaseIntent.funding.other_funding === "" ? false : true;

  return {
    _id: purchaseIntent._id,
    additionalBuyers: additionalBuyers,
    agreedPrice: purchaseIntent.agreed_price.toString(),
    customerType: "individual_owner",
    firstTimeBuyer: purchaseIntent.first_time_buyer,
    funding: {
      gifts: purchaseIntent.funding.gifts,
      savingsFromEmployment: purchaseIntent.funding.savings_from_employment,
      investmentPortfolio: purchaseIntent.funding.investment_portfolio,
      remortgage: purchaseIntent.funding.remortgage,
      inheritance: purchaseIntent.funding.inheritance,
      divorceSettlement: purchaseIntent.funding.divorce_settlement,
      pensionLumpSumWithdrawal:
        purchaseIntent.funding.pension_lump_sum_withdrawal,
      saleOfShares: purchaseIntent.funding.sale_of_shares,
      directorsLoansDividends: purchaseIntent.funding.directors_loans_dividends,
      otherFunding: otherFunding,
      otherFundingOption: purchaseIntent.funding.other_funding,
      otherFundingComments: purchaseIntent.other_funding_comments,
    },
    giftors: primaryBuyerGiftors,
    mortgageRequiredForPurchase: purchaseIntent.mortgage_required_for_purchase,
    mortgageProvider:
      purchaseIntent.mortgage_provider === null
        ? undefined
        : purchaseIntent.mortgage_provider,
    ownershipType: purchaseIntent.ownership_type,
    property_id: purchaseIntent.property_id,
    propertyAddress,
    purchaseDependentOnSale: purchaseIntent.purchase_dependent_on_sale,
    isUsingForeignCurrency: purchaseIntent.is_using_foreign_currency,
  };
};
