import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Icons } from "@pongo";

import { firstResponseData, secondResponseData } from "./data";

export const CharlieOutput = ({ response }: { response: number }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const [phraseIndex, setPhraseIndex] = useState(0);
  const phrases = [
    "Finding the transaction...",
    "Reviewing the solicitors notes...",
    "Considering next steps...",
  ];

  // Wait for 5 seconds before showing the component.
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  // Rotate through phrases while waiting.
  useEffect(() => {
    if (isVisible) return;

    const interval = setInterval(() => {
      setPhraseIndex((prev) => (prev + 1) % phrases.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [isVisible, phrases.length]);

  // Once visible, trigger a gentle fade-in transition and scroll to the bottom after the fade completes.
  useEffect(() => {
    if (isVisible) {
      // Start fade-in almost immediately.
      setTimeout(() => setFadeIn(true), 50);
      // After the fade-in (1000ms duration), scroll to the bottom.
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 200);
    }
  }, [isVisible]);

  // Display a rotating loading message during the delay.
  if (!isVisible) {
    return (
      <Typography
        variant="base"
        type="p"
        weight="semi-bold"
        className="text-brand-heavy-teal-100/50 mt-[5px]"
      >
        {phrases[phraseIndex]}
      </Typography>
    );
  }

  // Render the main content after 5 seconds with a gentle fade-in.
  if (response === 1) {
    return (
      <div
        className={`transition-opacity duration-1000 ease-in-out ${
          fadeIn ? "opacity-100" : "opacity-0"
        }`}
      >
        <div className="flex items-center mb-[24px]">
          <Icons
            iconName="Seconds"
            size="20"
            className="relative top-[-1px] mr-[5px]"
          />

          <Typography
            variant="base"
            weight="regular"
            className="text-brand-heavy-teal-100/50"
          >
            Reviewed transaction for under 10 seconds
          </Typography>
        </div>

        <Typography
          variant="h4"
          weight="semi-bold"
          className="text-brand-heavy-teal-100 mb-[12px] leading-[150%]"
        >
          Engrossment Fee Issue on 3A Chetwode Road, London, SW17 7RF
        </Typography>

        <Typography
          variant="base"
          type="p"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          {firstResponseData.engrossmentFeeIssue.description}
        </Typography>

        <Typography
          variant="h4"
          type="p"
          weight="semi-bold"
          className="text-brand-heavy-teal-100 mb-[12px] leading-[150%]"
        >
          Buyer Concerns
        </Typography>

        <ul className="list-disc pl-5 space-y-2 mb-[24px] leading-[150%]">
          {firstResponseData.buyerConcerns.map((concern, index) => (
            <li key={index}>
              <Typography
                variant="base"
                type="p"
                weight="regular"
                className="text-brand-heavy-teal-100 mb-[12px] leading-[150%]"
              >
                <strong>{concern.issue}</strong>: {concern.details}
              </Typography>
            </li>
          ))}
        </ul>

        <Typography
          variant="h4"
          type="p"
          weight="semi-bold"
          className="text-brand-heavy-teal-100 mb-[12px] leading-[150%]"
        >
          Resolution Status
        </Typography>

        <Typography
          variant="base"
          type="p"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          {firstResponseData.resolutionStatus.details}
        </Typography>

        <Typography
          variant="h4"
          type="p"
          weight="semi-bold"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          Action Required
        </Typography>

        <Typography
          variant="base"
          type="p"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          No action required.
        </Typography>

        <Typography
          variant="base"
          type="p"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          {firstResponseData.actionRequired.details}
        </Typography>

        <Typography
          variant="base"
          type="p"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          Can I help you understand the next steps in this transaction?
        </Typography>

        {response === 1 && (
          <div className="w-[275px] border rounded-lg p-4 bg-brand-sand-100/50 border-brand-heavy-warmth-100 hover:border-brand-heavy-teal-100/20 mb-[24px]">
            <Link
              to="/admin/transactions/"
              className="cursor flex items-center justify-between"
            >
              <Typography
                variant="base"
                type="p"
                weight="regular"
                className="text-brand-heavy-teal-100/80"
              >
                View full transaction details
              </Typography>

              <Icons iconName="Notify" size="18" colour="#01323c80" />
            </Link>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div>
        <Typography
          variant="base"
          type="p"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          The engrossment fee issue has been resolved based on these key
          communications:
        </Typography>

        <ol className="list-none mb-[24px]">
          {secondResponseData.engrossmentFeeIssueResolution.communications.map(
            (text, index) => (
              <li key={index} className="mb-[32px]">
                <Typography
                  variant="h4"
                  type="p"
                  weight="semi-bold"
                  className="text-brand-heavy-teal-100 mb-[12px] leading-[150%]"
                >
                  {text.status}
                </Typography>

                <Typography
                  variant="base"
                  type="p"
                  weight="regular"
                  className="text-brand-heavy-teal-100 mb-[12px] leading-[150%]"
                >
                  <strong>{text.timestamp}</strong>,{" "}
                  <a
                    href=""
                    className="cursor underline text-brand-teal-75 font-semibold"
                  >
                    {text.actor}
                  </a>{" "}
                  confirmed, &quot;{text.message}&quot;
                </Typography>
              </li>
            ),
          )}
        </ol>

        <Typography
          variant="base"
          type="p"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          {secondResponseData.engrossmentFeeIssueResolution.conclusion.details}
        </Typography>

        <Typography
          variant="base"
          type="p"
          weight="regular"
          className="text-brand-heavy-teal-100 mb-[24px] leading-[150%]"
        >
          Can I help you with anything else today?
        </Typography>

        {response === 2 && (
          <div className="w-[275px] border rounded-lg p-4 bg-brand-sand-100/50 border-brand-heavy-warmth-100 hover:border-brand-heavy-teal-100/20 mb-[24px]">
            <Link
              to="/admin/transactions/"
              className="cursor flex items-center justify-between"
            >
              <Typography
                variant="base"
                type="p"
                weight="regular"
                className="text-brand-heavy-teal-100/80"
              >
                View full transaction details
              </Typography>

              <Icons iconName="Notify" size="18" colour="#01323c80" />
            </Link>
          </div>
        )}
      </div>
    );
  }
};
