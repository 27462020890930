import { StepperGroup } from "@pongo";

import { Header } from "../../components/Header/Header";
import { Footer } from "../../components/Footer/Footer";
import { Nav } from "../../components/Nav/Nav";
import { OverviewSeller } from "../../components/TermsAndConditions/OverviewSeller";
import { OverviewBuyer } from "../../components/TermsAndConditions/OverviewBuyer";
import { TermsAndConditionsOfUse } from "../../components/TermsAndConditions/TermsAndConditionsOfUse";
import { buyTerms, sellTerms } from "../../components/Header/allStepperOptions";
import { Background } from "../../components/Background/Background";
import { Container } from "../../components/Container/Container";
import { ErrorMessageWithEmail } from "../../components/Form/DetailsForm/AddressLookup/ErrorMessageWithEmail";
import { useTermsAndConditions } from "./useTermsAndConditions";

export const TermsAndConditions = () => {
  const { role, error, handleFormSubmit, navigate } = useTermsAndConditions();

  return (
    <Background className="flex flex-col">
      <Nav />

      <Container>
        <div className="flex flex-col px-[24px] lg:px-0">
          <Header title="Our Terms & Conditions">
            <StepperGroup
              options={role === "buy" ? buyTerms : sellTerms}
            ></StepperGroup>
          </Header>

          {error ? (
            <ErrorMessageWithEmail propertyErrorMessage={error} />
          ) : (
            <>
              <div className="flex flex-col md:flex-row">
                <div>
                  {role === "sell" ? <OverviewSeller /> : <OverviewBuyer />}
                </div>

                <TermsAndConditionsOfUse />
              </div>

              <Footer
                handleBack={() => navigate(`/${role}/about-the-property`)}
                handleContinue={handleFormSubmit}
                continueTitle="Agree and Continue"
                hasValidation={false}
              />
            </>
          )}
        </div>
      </Container>
    </Background>
  );
};
