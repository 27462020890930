import { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import { ApiController } from "../../service/ApiController";
import {
  CompanySellerState,
  ManualTransactionState,
  PersonalDetailsWithAddressState,
  TransactionsGroupState,
} from "@schema";
import {
  initialCompanySellerStateObj,
  initialManualTransactionsStateArray,
  initialTransactionsGroup,
  initialTransactionsGroupStateObj,
} from "../components/TransactionsGroupDetailsBlocks/initialDetails";

export interface ApiResponse {
  error?: string;
  id?: string;
}

export type TransactionsGroupStateForAdminPage = TransactionsGroupState & {
  estateAgent?: string;
  lawyerGroups?: {
    buyer: string;
    seller: string;
  };
  hasActivePurchaseIntent: boolean;
};

interface TransactionsGroupContext {
  transactionsGroup: TransactionsGroupStateForAdminPage;
  setTransactionsGroup: React.Dispatch<
    React.SetStateAction<TransactionsGroupStateForAdminPage>
  >;
  representative: PersonalDetailsWithAddressState;
  setRepresentative: (newData: PersonalDetailsWithAddressState) => void;
  createRepresentative: () => Promise<ApiResponse>;
  companySeller: CompanySellerState;
  setCompanySeller: (newData: CompanySellerState) => void;
  createCompanySeller: () => Promise<ApiResponse>;
  stripeTransactionId: string;
  setStripeTransactionId: (newData: string) => void;
  manualTransactions: ManualTransactionState[];
  setManualTransactions: (newData: ManualTransactionState[]) => void;
  addManualTransaction: (
    updatedManualTransaction: ManualTransactionState,
    index: number,
  ) => void;
  updateManualTransaction: (
    updatedManualTransaction: ManualTransactionState,
    index: number,
  ) => void;
  resetManualTransactions: () => void;
  removeManualTransaction: (index: number) => void;
  reset: () => void;
  loading?: boolean;
  error?: string;
}

export const TransactionsGroupContext = createContext<TransactionsGroupContext>(
  {
    ...initialTransactionsGroupStateObj,
    transactionsGroup: { name: "", _id: "", hasActivePurchaseIntent: false },
    stripeTransactionId: "",
    setTransactionsGroup: () => {},
    setRepresentative: () => {},
    setCompanySeller: () => {},
    setManualTransactions: () => {},
    addManualTransaction: () => {},
    updateManualTransaction: () => {},
    removeManualTransaction: () => {},
    resetManualTransactions: () => {},
    reset: () => {},
    setStripeTransactionId: () => {},
    createRepresentative: async () => ({}),
    createCompanySeller: async () => ({}),
  },
);

export const useTransactionsGroupContext = () => {
  const context = useContext(TransactionsGroupContext);
  if (!context) {
    throw new Error(
      "useTransactionsGroupContext must be used within a TransactionsGroupProvider",
    );
  }
  return context;
};

interface TransactionsGroupProviderProps {
  children: React.ReactNode;
  transactionsGroupId?: string;
}

const getStoredTransactionsGroup = (): TransactionsGroupState & {
  hasActivePurchaseIntent: boolean;
} => {
  const savedGroup = localStorage.getItem("transactionsGroup");
  return savedGroup
    ? JSON.parse(savedGroup)
    : { name: "", _id: "", hasActivePurchaseIntent: false };
};

const getStoredRepresentative = (): PersonalDetailsWithAddressState => {
  const savedRep = localStorage.getItem("representative");
  return savedRep ? JSON.parse(savedRep) : undefined;
};

const getStoredCompanySeller = (): CompanySellerState => {
  const savedComp = localStorage.getItem("companySeller");
  return savedComp ? JSON.parse(savedComp) : undefined;
};

const getStoredManualTransactions = (): ManualTransactionState[] => {
  const savedTransactions = localStorage.getItem("manualTransactions");
  return savedTransactions
    ? JSON.parse(savedTransactions)
    : initialManualTransactionsStateArray;
};

export const TransactionsGroupProvider = ({
  children,
  transactionsGroupId,
}: TransactionsGroupProviderProps) => {
  const [transactionsGroup, setTransactionsGroup] = useState<
    TransactionsGroupState & { hasActivePurchaseIntent: boolean }
  >(getStoredTransactionsGroup);

  const [representative, setRepresentative] =
    useState<PersonalDetailsWithAddressState>(getStoredRepresentative);

  const [companySeller, setCompanySeller] = useState<CompanySellerState>(
    getStoredCompanySeller,
  );

  const [stripeTransactionId, setStripeTransactionId] = useState<string>("");

  const [manualTransactions, setManualTransactions] = useState<
    ManualTransactionState[]
  >(getStoredManualTransactions);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    async function getTransactionsGroup(id: string) {
      if (!id || transactionsGroup._id === id) return;

      try {
        setError("");
        setLoading(true);

        const transactionsGroupRes =
          await ApiController.findTransactionsGroup(id);

        setTransactionsGroup({
          ...transactionsGroupRes,
          hasActivePurchaseIntent: false,
        });
      } catch (error) {
        Sentry.captureException(error);
        setError(
          "There was an error while fetching the transactions group details",
        );
      } finally {
        setLoading(false);
      }
    }

    if (transactionsGroupId) {
      getTransactionsGroup(transactionsGroupId);
    }
  }, [transactionsGroupId, transactionsGroup]);

  const createRepresentative = async (): Promise<ApiResponse> => {
    try {
      setLoading(true);

      if (representative.personId !== "") {
        return { id: representative.personId };
      }

      const user = {
        email: representative.email,
        first_name: representative.firstName,
        middle_name: representative.middleName,
        last_name: representative.lastName,
        profile: {
          phone_number: representative.mobileNumber,
          correspondence_address: {
            line_1: representative.correspondenceAddress.line_1,
            line_2: representative.correspondenceAddress.line_2,
            line_3: representative.correspondenceAddress.line_3,
            post_town: representative.correspondenceAddress.post_town,
            country: representative.correspondenceAddress.country,
            postcode: representative.correspondenceAddress.postcode,
            country_iso_2: representative.correspondenceAddress.country_iso_2,
          },
        },
      };

      const newRepresentative = await ApiController.createActiveCustomer(user);
      setRepresentative({ ...representative, personId: newRepresentative._id });
      return { id: newRepresentative._id };
    } catch (error) {
      Sentry.captureException(error);
      return {
        error: "There was an error while creating representative",
      };
    } finally {
      setLoading(false);
    }
  };

  const createCompanySeller = async (): Promise<ApiResponse> => {
    try {
      setLoading(true);

      const formatedCompany = {
        name: companySeller.name,
        company_number: companySeller.companyNumber,
        address: companySeller.address,
      };

      let company;

      if (companySeller.companyId && companySeller.companyId !== "") {
        company = await ApiController.updateCompany(
          companySeller.companyId,
          formatedCompany,
        );
      } else {
        company = await ApiController.createCompany(formatedCompany);
      }

      setCompanySeller({
        name: company.name,
        companyId: company._id,
        companyNumber: company.company_number,
        address: company.address,
      });

      return { id: company._id };
    } catch (error) {
      Sentry.captureException(error);
      return {
        error: "There was an error while creating company",
      };
    } finally {
      setLoading(false);
    }
  };

  const updateManualTransaction = (
    updatedManualTransaction: ManualTransactionState,
    index: number,
  ) => {
    // Get the index and update only that transaction:
    setManualTransactions((prev) =>
      prev.map((transaction, i) =>
        i === index
          ? { ...transaction, ...updatedManualTransaction }
          : transaction,
      ),
    );
  };

  const addManualTransaction = (
    updatedManualTransaction: ManualTransactionState,
    index: number,
  ) => {
    if (manualTransactions.length === 1) {
      // Set the first manual transaction:
      setManualTransactions([{ ...updatedManualTransaction }]);
    }
    setManualTransactions((prev) => {
      const updatedTransactions = [...prev];
      updatedTransactions[index + 1] = {
        ...updatedTransactions[index + 1],
        ...updatedManualTransaction,
      };
      return updatedTransactions;
    });
  };

  const removeManualTransaction = (index: number) => {
    setManualTransactions((prev) => prev.filter((_, idx) => idx !== index));
  };

  const resetManualTransactions = () => {
    setManualTransactions(initialManualTransactionsStateArray);
  };

  const reset = () => {
    setManualTransactions(initialManualTransactionsStateArray);
    setTransactionsGroup(initialTransactionsGroup);
    setRepresentative(initialTransactionsGroupStateObj.representative);
    setCompanySeller(initialCompanySellerStateObj);
    setStripeTransactionId("");
  };

  useEffect(() => {
    if (transactionsGroup) {
      localStorage.setItem(
        "transactionsGroup",
        JSON.stringify(transactionsGroup),
      );
    }
  }, [transactionsGroup]);

  useEffect(() => {
    if (representative && Object.keys(representative).length > 0) {
      localStorage.setItem("representative", JSON.stringify(representative));
    } else {
      localStorage.removeItem("representative");
    }
  }, [representative]);

  useEffect(() => {
    if (companySeller) {
      localStorage.setItem("companySeller", JSON.stringify(companySeller));
    } else {
      localStorage.removeItem("companySeller");
    }
  }, [companySeller]);

  useEffect(() => {
    if (manualTransactions.length) {
      localStorage.setItem(
        "manualTransactions",
        JSON.stringify(manualTransactions),
      );
    } else {
      localStorage.removeItem("manualTransactions");
    }
  }, [manualTransactions]);

  return (
    <TransactionsGroupContext.Provider
      value={{
        transactionsGroup,
        setTransactionsGroup,
        representative,
        setRepresentative,
        createRepresentative,
        companySeller,
        setCompanySeller,
        createCompanySeller,
        manualTransactions,
        setManualTransactions,
        addManualTransaction,
        updateManualTransaction,
        removeManualTransaction,
        resetManualTransactions,
        reset,
        stripeTransactionId,
        setStripeTransactionId,
        loading,
        error,
      }}
    >
      {children}
    </TransactionsGroupContext.Provider>
  );
};
