import { AddressInputs } from "./AddressInputs";

export const SellerPropertyAddress = () => (
  <AddressInputs
    addressTitle="Sale property address"
    addressDescription="This is your sale property address. Please check carefully that it is correct. An error at this stage can result in delays and extra expense later on."
    cardAddressTitle="Sale property address"
    namespace="propertyAddress"
    showUseCorrespondenceAddress
  />
);
