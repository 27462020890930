import { useState, useEffect, useMemo, useRef } from "react";
import { Button, Typography } from "@pongo";
import { debounce } from "lodash";

import { AdminTransaction } from "../TransactionDetails/types";
import { ApiController } from "../../../service/ApiController";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { GetTransactionsQueryParams } from "../../service/AdminApiController/findAllTransactions";
import { ThirdfortProvider } from "../../context/ThirdfortProvider";

import { FeedFilter, FilterOption } from "./FeedFilter";
import { FeedItem } from "./FeedItem/FeedItem";

const TRANSACTIONS_PER_PAGE = 5;

export const Feed = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const [transactions, setTransactions] = useState<AdminTransaction[]>([]);
  const [visibleCount, setVisibleCount] = useState(TRANSACTIONS_PER_PAGE);

  const [filter, setFilter] = useState<FilterOption[]>([]);

  // Cache for transactions based on the filter query
  const cacheRef = useRef<Record<string, AdminTransaction[]>>({});
  // Store last query key to avoid duplicate network requests
  const lastQueryKeyRef = useRef<string>("");

  useEffect(() => {
    async function fetchTransactions() {
      const query = buildQueryFromFilters(filter);
      const queryKey = JSON.stringify(query);

      // If the filter query is unchanged, skip the network request
      if (queryKey === lastQueryKeyRef.current) {
        return;
      }

      lastQueryKeyRef.current = queryKey;
      setLoading(true);
      setError("");

      try {
        if (cacheRef.current[queryKey]) {
          setTransactions(cacheRef.current[queryKey]);
        } else {
          const existingTransactions =
            await ApiController.findAllTransactions(query);
          setTransactions(existingTransactions);
          cacheRef.current[queryKey] = existingTransactions;
        }
      } catch (error: unknown) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "There was an error while fetching transactions";
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }

    fetchTransactions();
  }, [filter]);

  // Conditional logging for development only
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      console.log("Filter set to:", filter);
    }
  }, [filter]);

  // Debounced Load More function to prevent rapid clicks
  const debouncedLoadMoreTransactions = useMemo(
    () =>
      debounce(() => {
        setVisibleCount((prevCount) => prevCount + TRANSACTIONS_PER_PAGE);
      }, 500),
    [], // dependencies: setVisibleCount and TRANSACTIONS_PER_PAGE are stable
  );

  // Cleanup debounced function on unmount to avoid memory leaks
  useEffect(() => {
    return () => {
      debouncedLoadMoreTransactions.cancel();
    };
  }, [debouncedLoadMoreTransactions]);

  return (
    <>
      {loading && <FullPageLoader />}
      {error && <div>{error}</div>}

      <div className="flex flex-row space-x-[12px] justify-center">
        <div className="flex flex-col w-full max-w-[1000px]">
          <div className="flex flex-row space-x-[12px] justify-start items-center overflow-x-scroll">
            <FeedFilter handleFilter={setFilter} />

            {/* Display the number of visible transactions */}
            <div className="mb-[24px] text-right">
              <Typography
                type="span"
                variant="xs"
                className="text-brand-heavy-teal-100/80 text-nowrap"
              >
                Showing {Math.min(visibleCount, transactions.length)} of{" "}
                {transactions.length} transactions
              </Typography>
            </div>
          </div>

          {transactions.slice(0, visibleCount).map((transaction) => (
            <ThirdfortProvider
              transactionId={transaction._id}
              key={transaction._id}
            >
              <FeedItem transaction={transaction} />
            </ThirdfortProvider>
          ))}

          {visibleCount < transactions.length && (
            <div className="flex justify-center mt-[24px] pb-[48px]">
              <Button
                variant="primary"
                onClick={debouncedLoadMoreTransactions}
                className="w-full lg:w-[300px]"
              >
                Load {TRANSACTIONS_PER_PAGE} More
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function buildQueryFromFilters(
  filterOptions: FilterOption[],
): GetTransactionsQueryParams {
  const query: GetTransactionsQueryParams = {};

  filterOptions.forEach(({ filter, checked }) => {
    if (!checked) return;

    // Normalize the filter value by removing the "no " prefix if present.
    const normalizedFilter = filter.startsWith("no ")
      ? filter.replace("no ", "")
      : filter;

    switch (normalizedFilter) {
      case "unpaid":
        query.isPaid = false;
        break;
      case "estate agents":
        // For "No Estate Agents" filter: expect estateAgentAssigned to be false
        query.estateAgentAssigned = false;
        break;
      case "solicitors":
        // For "No Solicitors" filter: expect solicitorsAssigned to be false
        query.solicitorsAssigned = false;
        break;
      case "searches":
        // For "No Searches" filter: expect searchesCompleted to be false
        query.searchesCompleted = false;
        break;
      case "thirdfort checks":
        // For "No Thirdfort Checks" filter: expect thirdfortChecksCompleted to be false
        query.thirdfortChecksCompleted = false;
        break;
      default:
        break;
    }
  });

  return query;
}
