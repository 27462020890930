export const navigationItems = [
  {
    label: "Transaction Feed",
    path: "/admin/feed",
    icon: "Feed",
    isDisabled: false,
  },
  {
    label: "Transactions",
    path: "/admin/transactions",
    icon: "AdminTransactions",
    isDisabled: false,
  },
  {
    label: "Transactions Groups",
    path: "/admin/transactions-groups",
    icon: "AdminTransactionGroups",
    isDisabled: false,
  },
  {
    label: "Law Firms",
    path: "/admin/law-firms",
    icon: "FeedSolicitors",
    isDisabled: false,
  },
  {
    label: "Estate Agents",
    path: "/admin/estate-agents",
    icon: "FeedEstateAgents",
    isDisabled: false,
  },
  {
    label: "Users",
    path: "/admin/users",
    icon: "AdminUsers",
    isDisabled: false,
  },
  {
    label: "Ask Charlie",
    path: "/admin/ask-charlie",
    icon: "Chat",
    isDisabled: true,
  },
];
