import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Table, TableRow, Button, ErrorText } from "@pongo";
import { toTitleCase } from "@shared/utils";

import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { ApiController } from "../../../service/ApiController";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import {
  TransactionsGroupProvider,
  useTransactionsGroupContext,
} from "../../context/TransactionsGroupProvider";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

interface TransactionsGroupRow {
  name: string;
}

export const TransactionsGroups = () => (
  <TransactionsGroupProvider>
    <TransactionsGroupsComponent />
  </TransactionsGroupProvider>
);

const TransactionsGroupsComponent = () => {
  const navigate = useNavigate();
  const { reset } = useTransactionsGroupContext();

  const columns = [{ key: "name", title: "Group Name" }];

  const [displayedTransactions, setDisplayedTransactions] =
    useState<TableRow<TransactionsGroupRow>[]>();
  const [transactionsGroups, setTransactionsGroups] = useState<
    {
      name: string;
      _id: string;
    }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [isResetComplete, setIsResetComplete] = useState(false);

  useEffect(() => {
    const getDataRows = (
      transactionsGroups: {
        _id: string;
        name: string;
      }[],
    ): TableRow<TransactionsGroupRow>[] => {
      return transactionsGroups.map((group) => {
        const { _id, name } = group;
        const titleCaseName = name
          .split(" ")
          .map((name) => toTitleCase(name))
          .join(" ");

        return {
          data: {
            name: titleCaseName,
          },
          onClick: () => navigate(`/admin/transactions-groups/${_id}`),
        };
      });
    };

    setDisplayedTransactions(getDataRows(transactionsGroups));
  }, [setDisplayedTransactions, navigate, transactionsGroups]);

  useEffect(() => {
    async function getAllTransactionsGroups() {
      setLoading(true);
      setError("");

      try {
        const existingGroups = await ApiController.findAllTransactionsGroups();

        setTransactionsGroups(existingGroups);
        setLoading(false);
      } catch (error) {
        if (error instanceof Error) {
          setError("There was an error while fetching transactions groups");
          setLoading(false);
        }
      }
    }
    getAllTransactionsGroups();
  }, [setTransactionsGroups]);

  const handleAddGroup = () => {
    reset();
    setIsResetComplete(true);
  };

  useEffect(() => {
    if (isResetComplete) {
      navigate("/admin/transactions-groups/add");
      setIsResetComplete(false);
    }
  }, [isResetComplete, navigate]);

  return (
    <>
      <Breadcrumbs items={[{ name: "Transactions Groups" }]} />

      <div className="flex">
        <AdminTitle title="Transactions Groups" />

        <Button
          className="mt-[20px]"
          variant="primary"
          onClick={handleAddGroup}
        >
          Add group
        </Button>
      </div>

      {loading && <FullPageLoader />}

      {error && <ErrorText>{error}</ErrorText>}

      <Table columns={columns} rows={displayedTransactions} />
    </>
  );
};
