import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useLoginStore } from "../../store/Login/login";
import { usePurchaseIntentHandler, useTransactionHandler } from "@hooks";

export const useTermsAndConditions = () => {
  const [error, setError] = useState<string>("");
  const { role } = useLoginStore();
  const { processTransaction } = useTransactionHandler();
  const { processPurchaseIntent } = usePurchaseIntentHandler();
  const navigate = useNavigate();

  const handleFormSubmit = async () => {
    try {
      if (role === "sell") {
        await processTransaction();
      } else {
        await processPurchaseIntent();
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(`${error.message}. Please contact support at`);
      }
    }
  };

  return { role, error, handleFormSubmit, navigate };
};
