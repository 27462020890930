import { BuyerSellerBlock } from "../BuyerSellerBlock";
import { useTransactionContext } from "../../context/TransactionProvider";
import { Card } from "@/components/Card/Card";
import { Table } from "../Table/Table";
import { getAddressAsString } from "@shared/utils";

export const SellersTab = () => {
  const { transaction } = useTransactionContext();
  const companySeller = transaction?.company_seller;

  return (
    <>
      {companySeller && (
        <Card
          title={companySeller.name}
          titleBackgroundColor="brand-heavy-warmth-25"
          titleColor="brand-heavy-teal-100"
        >
          <>
            <Table
              key="name"
              label={"Company Name"}
              value={companySeller.name}
            />
            <Table
              key="company_number"
              label={"Company Number"}
              value={companySeller.company_number}
            />
            <Table
              key="company_address"
              label={"Registered Address"}
              value={getAddressAsString(companySeller.address)}
            />
          </>
        </Card>
      )}

      {transaction?.sellers.map((seller, index) => (
        <BuyerSellerBlock
          key={`seller-${index}`}
          buyerSeller={seller}
          isSeller
        />
      ))}
    </>
  );
};
