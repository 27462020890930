import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Typography, Icons } from "@pongo";

import { AdminSearch, AdminNavigationLabel, navigationItems } from ".";
import { useAuthStore } from "../../../store/auth/auth";
import { useSignOut } from "../../../hooks";

import logoSrc from "@assets/logos/small-logo.svg";

const ADMIN_HOME_URL = "/admin";
const CONFIG_URL = "/admin/config";
const WELCOME_BACK_URL = "/welcome-back";

export const AdminNavigation = () => (
  <div className="z-50 fixed top-0 left-0 right-0 w-full border-b border-brand-heavy-warmth-100 bg-brand-heavy-warmth-75 px-[8px] lg:px-[16px]">
    <div className="w-full flex justify-between items-center py-[8px]">
      <div className="flex">
        <Logo />
        <LeftNavItems />
      </div>

      <div className="hidden lg:block">
        <AdminSearch />
      </div>

      <RightNavItems />
    </div>

    <div className="lg:hidden mb-[8px]">
      <AdminSearch />
    </div>
  </div>
);

const LeftNavItems = () => (
  <div className="flex items-center mr-[8px]">
    {navigationItems.map(({ label, path, icon, isDisabled }) => {
      if (!isDisabled)
        return (
          <div
            key={label}
            className={`relative p-[8px] rounded-lg ${location.pathname === path && "bg-brand-heavy-teal-5/50"}`}
          >
            <Link to={path}>
              <div className="flex items-center group relative">
                <Icons
                  iconName={icon as keyof typeof Icons}
                  size="18"
                  colour="#40656D"
                />

                <AdminNavigationLabel label={label} />

                <Typography
                  variant="sm"
                  weight="semi-bold"
                  className={`hidden text-brand-heavy-teal-100`}
                >
                  {label}
                </Typography>
              </div>
            </Link>
          </div>
        );
    })}
  </div>
);

const RightNavItems = () => {
  const signOut = useSignOut();
  const location = useLocation();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const { userData } = useAuthStore();

  useEffect(() => {
    if (userData?.active && userData?.email) {
      setIsSignedIn(true);
    }
  }, [userData, isSignedIn]);

  return (
    <div className="flex justify-end items-center relative z-50">
      <div className="hidden lg:inline-block">
        <Link to={WELCOME_BACK_URL} className="flex items-center p-[8px]">
          <Icons iconName="AllTransactions" size="18" colour="#40656D" />

          <Typography
            type="p"
            variant="xs"
            weight="regular"
            className="test-[#1C274C] text-nowrap overflow-clip mx-[4px]"
          >
            Switch Properties
          </Typography>
        </Link>
      </div>

      <div className="hidden lg:inline-block">
        <div className="flex items-center rounded-lg bg-brand-heavy-warmth-100/80 p-[8px]">
          <Icons iconName="UserAccount" size="18" colour="#40656D" />

          <Typography
            type="p"
            variant="xs"
            weight="regular"
            className="test-[#1C274C] text-nowrap overflow-clip mx-[4px]"
          >
            {userData?.first_name} {userData?.last_name}
          </Typography>
        </div>
      </div>

      <div
        className={`relative p-[8px] rounded-lg ${location.pathname === "/admin/config" && "bg-brand-heavy-teal-5/50"}`}
      >
        <Link to={CONFIG_URL}>
          <div className="flex items-center group relative">
            <Icons iconName="Settings" size="18" colour="#40656D" />

            <AdminNavigationLabel label="Config" />

            <Typography
              variant="sm"
              weight="semi-bold"
              className="hidden pl-[16px]"
            >
              Config
            </Typography>
          </div>
        </Link>
      </div>

      <div className="flex items-center relative px-[8px] rounded-lg">
        <button onClick={() => signOut()}>
          <div className="flex items-center group relative">
            <div className="transform rotate-180">
              <Icons iconName="SignOut" size="18" colour="#40656D" />
            </div>

            <AdminNavigationLabel label="Sign Out" />

            <Typography
              variant="sm"
              weight="semi-bold"
              className="hidden pl-[16px]"
            >
              Sign Out
            </Typography>
          </div>
        </button>
      </div>
    </div>
  );
};

const Logo = () => (
  <Link
    to={ADMIN_HOME_URL}
    className="flex items-center justify-center bg-brand-electric-100 rounded-lg h-[34px] w-[34px] shrink-0 mr-[8px]"
  >
    <div className="flex items-center group relative z-50">
      <div className="relative left-[-1px]">
        <img src={logoSrc} width={18} />
      </div>

      <AdminNavigationLabel label="Home" />
    </div>
  </Link>
);
