import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from ".";

export type FindSuggestedAddressesResponse =
  paths["/api/address/search"]["get"]["responses"]["200"]["content"]["application/json"];

export const findSuggestedAddresses = async (
  query: string,
  country?: string,
  containerId?: string,
): Promise<FindSuggestedAddressesResponse> => {
  try {
    const response = await api.get(
      `/address/search?query=${query}${country ? `&country=${country}` : ""}${containerId ? `&containerId=${containerId}` : ""}`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Address not found");
  }
};
