import { Routes, Route } from "react-router-dom";

import { BuyerAboutTheProperty } from "../pages/BuyerAboutTheProperty/BuyerAboutTheProperty";
import { BuyerAboutYou } from "../pages/BuyerAboutYou/BuyerAboutYou";
import { Fees } from "../pages/Fees/Fees";
import { OnboardingSuccess } from "../pages/Success/OnboardingSuccess";
import { PageNotFound } from "../pages/PageNotFound/PageNotFound";
import { TermsAndConditions } from "../pages/TermsAndConditions/TermsAndConditions";
import { Welcome } from "../pages/Welcome/Welcome";

export const BuyerRoutes = () => (
  <Routes>
    <Route path="success" element={<OnboardingSuccess />} />
    <Route path="whats-included" element={<Fees />} />
    <Route path="terms-and-conditions" element={<TermsAndConditions />} />
    <Route path="about-the-property" element={<BuyerAboutTheProperty />} />
    <Route path="about-you" element={<BuyerAboutYou />} />
    <Route path="welcome" element={<Welcome />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
