import { Outlet, Route } from "react-router-dom";

import { ProtectedRoute } from "../components/ProtectedRoute/ProtectedRoute";
import { WelcomeBack } from "../pages/WelcomeBack/WelcomeBack";
import { AdminRoutes } from "../admin/routes/AdminRoutes";

import { DashboardRoutes } from "./DashboardRoutes";
import { BuyerRoutes } from "./BuyerRoutes";
import { SellerRoutes } from "./SellerRoutes";

const ProtectedLayout = () => (
  <ProtectedRoute>
    <Outlet />
  </ProtectedRoute>
);

export const ProtectedRoutes = () => (
  <Route element={<ProtectedLayout />}>
    <Route path="/welcome-back" element={<WelcomeBack />} />
    <Route path="/sell/*" element={<SellerRoutes />} />
    <Route path="/buy/*" element={<BuyerRoutes />} />
    <Route path="/dashboard/*" element={<DashboardRoutes />} />
    <Route path="/admin/*" element={<AdminRoutes />} />
  </Route>
);
