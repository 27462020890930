import { useNavigate } from "react-router-dom";

import { usePurchaseIntentFormStore } from "../store/PurchaseIntentForm/purchaseIntentForm";
import {
  ApiController,
  transformPurchaseIntentForApi,
} from "../service/ApiController";
import { transformUpdatePrimaryCustomerForUpdateCustomerApi } from "@transformers";

export const usePurchaseIntentHandler = () => {
  const { purchaseIntent, updatePurchaseIntent } = usePurchaseIntentFormStore();
  const navigate = useNavigate();

  const updatePrimaryBuyer = async () => {
    const transformedPrimaryBuyer =
      transformUpdatePrimaryCustomerForUpdateCustomerApi(
        purchaseIntent.primaryBuyer,
      );
    return await ApiController.updateUser(transformedPrimaryBuyer);
  };

  // Determines whether to create or update a purchase intent:
  const processPurchaseIntent = async () => {
    const existingPurchaseIntent = purchaseIntent._id
      ? await ApiController.findPurchaseIntent(purchaseIntent._id)
      : null;

    // Update customer (primary buyer):
    const { _id: buyerId } = await updatePrimaryBuyer();

    if (existingPurchaseIntent) {
      await updateExistingPurchaseIntent(buyerId);
    } else {
      await createNewPurchaseIntent(buyerId);
    }
  };

  const updateExistingPurchaseIntent = async (buyerId: string) => {
    const transformedPurchaseIntent = transformPurchaseIntentForApi(
      purchaseIntent,
      buyerId,
    );
    await ApiController.updatePurchaseIntent(
      purchaseIntent._id,
      transformedPurchaseIntent,
    );
    navigate("/buy/whats-included");
  };

  const createNewPurchaseIntent = async (buyerId: string) => {
    purchaseIntent.primaryBuyer.termsAndConditions = true;

    const transformedPurchaseIntent = transformPurchaseIntentForApi(
      purchaseIntent,
      buyerId,
    );
    const createdPurchaseIntent = await ApiController.createPurchaseIntent(
      transformedPurchaseIntent,
    );

    updatePurchaseIntent({
      ...purchaseIntent,
      _id: createdPurchaseIntent.purchaseIntent._id,
    });
    navigate("/buy/whats-included");
  };

  return { processPurchaseIntent };
};
