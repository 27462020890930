import Stepper, { StepperProps } from "./Stepper";

export type StepperGroupProps = {
  variant?: "success" | "active" | "static";
  time?: string;
  details?: string;
  options: StepperProps[];
};

export const StepperGroup = ({ options, ...props }: StepperGroupProps) => (
  <div className="flex flex-col md:flex-row">
    {options.map((option) => (
      <div key={option.details} className="mr-[24px] mb-[16px] md:mb-0">
        <Stepper
          {...props}
          variant={option.variant}
          time={option.time}
          details={option.details}
        />
      </div>
    ))}
  </div>
);
