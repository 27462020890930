import { Routes, Route } from "react-router-dom";

import { PageNotFound } from "../pages/PageNotFound/PageNotFound";
import { Payment } from "../pages/Payment/Payment";
import { PaymentSuccess } from "../pages/Success/PaymentSuccess";
import { TermsAndConditions } from "../pages/TermsAndConditions/TermsAndConditions";
import { Welcome } from "../pages/Welcome/Welcome";
import { SellerAboutYou } from "../pages/SellerAboutYou/SellerAboutYou";
import { SellerAboutTheProperty } from "../pages/SellerAboutTheProperty/SellerAboutTheProperty";

export const SellerRoutes = () => (
  <Routes>
    <Route path="payment-success" element={<PaymentSuccess />} />
    <Route path="payment" element={<Payment />} />
    <Route path="terms-and-conditions" element={<TermsAndConditions />} />
    <Route path="about-the-property" element={<SellerAboutTheProperty />} />
    <Route path="about-you" element={<SellerAboutYou />} />
    <Route path="welcome" element={<Welcome />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
