import { useEffect, useState } from "react";
import { Typography, Icons } from "@pongo";

import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import {
  TransactionsGroupProvider,
  useTransactionsGroupContext,
} from "../../context/TransactionsGroupProvider";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { RenderTabContent } from "./RenderTabContent";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

export type Tabs = "details" | "ops";

interface Tab {
  value: Tabs;
  label: string;
  count?: number;
  alert?: boolean;
}

const TransactionsGroupDetailsTabs = ({
  tabs,
  currentTab,
  setCurrentTab,
}: {
  tabs: Tab[];
  currentTab: Tabs;
  setCurrentTab: (tab: Tabs) => void;
}) => (
  <div className="w-full flex my-[20px] border-b overflow-x-scroll">
    {tabs.map((tab) => (
      <button
        key={tab.value}
        onClick={() => setCurrentTab(tab.value)}
        className={`mr-4 p-[16px] rounded-t-lg flex items-center
                  ${
                    tab.value === currentTab
                      ? "bg-brand-heavy-teal-100 text-brand-heavy-warmth-100"
                      : ""
                  }`}
      >
        <Typography type="p" variant="lg" weight="bold">
          {tab.label}
        </Typography>

        <div className="ml-[8px]">
          {tab.count !== undefined && (
            <div
              className={`flex justify-center items-center rounded-full h-[20px] w-[20px]  ${
                tab.value === currentTab
                  ? "bg-brand-heavy-warmth-100 text-brand-heavy-teal-100"
                  : "bg-brand-heavy-teal-100 text-brand-heavy-warmth-100"
              }`}
            >
              <Typography type="p" variant="xs" weight="bold" className="m-0">
                {tab.count}
              </Typography>
            </div>
          )}
        </div>

        {tab.alert && (
          <div className="ml-[8px]">
            <Icons iconName="Alert" colour="error" size="18" />
          </div>
        )}
      </button>
    ))}
  </div>
);

export const TransactionsGroupDetailsContent = () => {
  const [currentTab, setCurrentTab] = useState<Tabs>("details");
  const [tabs, setTabs] = useState<Tab[]>([]);

  const { transactionsGroup, error, loading } = useTransactionsGroupContext();

  useEffect(() => {
    if (transactionsGroup) {
      setTabs([
        { value: "details", label: "Details" },
        {
          value: "ops",
          label: "Ops",
        },
      ]);
    }
  }, [transactionsGroup]);

  return (
    <>
      <Breadcrumbs
        items={[
          {
            name: "Transactions Groups",
            url: "/admin/transactions-groups",
          },
          { name: transactionsGroup._id || "Unknown ID" },
        ]}
      />
      <AdminTitle title={transactionsGroup.name} />

      {loading && <FullPageLoader />}

      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      {!error && (
        <>
          <TransactionsGroupDetailsTabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          <div className="flex flex-col space-y-5">
            <RenderTabContent currentTab={currentTab} />
          </div>
        </>
      )}
    </>
  );
};

export const TransactionsGroupDetails = () => {
  return (
    <TransactionsGroupProvider>
      <TransactionsGroupDetailsContent />
    </TransactionsGroupProvider>
  );
};
