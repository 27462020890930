import { useState } from "react";
import { Country } from "react-country-state-city/dist/esm/types";

export const useAddressInputsState = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isManualAddressOpen, setIsManualAddressOpen] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(true);
  const [
    correspondenceAddressCountryInfo,
    setCorrespondenceAddressCountryInfo,
  ] = useState<Country["iso2"]>();
  const [fetchError, setFetchError] = useState(false);

  return {
    isPageLoaded,
    setIsPageLoaded,
    isManualAddressOpen,
    setIsManualAddressOpen,
    isEditingAddress,
    setIsEditingAddress,
    correspondenceAddressCountryInfo,
    setCorrespondenceAddressCountryInfo,
    fetchError,
    setFetchError,
  };
};
