import { useNavigate } from "react-router-dom";

import { StepperGroup } from "@pongo";

import { Background } from "../../components/Background/Background";
import { BuyerWelcomeMessage } from "./BuyerWelcomeMessage";
import { Container } from "../../components/Container/Container";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { Nav } from "../../components/Nav/Nav";
import { SellerWelcome } from "./SellerWelcome";
import {
  buyWelcome,
  sellWelcome,
} from "../../components/Header/allStepperOptions";
import { useLoginStore } from "../../store/Login/login";

export const Welcome = () => {
  const navigate = useNavigate();
  const { role } = useLoginStore();

  const handleNextPage = () => {
    navigate(`/${role}/about-you`);
  };

  return (
    <Background className="flex flex-col">
      <Nav />

      <Container>
        <div className="flex flex-col px-[24px] lg:px-0">
          <Header title="Welcome to Propelr">
            <StepperGroup
              options={role === "buy" ? buyWelcome : sellWelcome}
            ></StepperGroup>
          </Header>

          {role === "buy" ? <BuyerWelcomeMessage /> : <SellerWelcome />}

          <Footer
            handleContinue={handleNextPage}
            continueTitle="Start"
            hasValidation={false}
          />
        </div>
      </Container>
    </Background>
  );
};
