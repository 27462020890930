import React from "react";
import { Typography } from "@pongo";

interface Person {
  first_name: string;
  last_name: string;
}

interface FeedItemPersonProps {
  person: Person;
}

export const FeedItemPerson: React.FC<FeedItemPersonProps> = ({ person }) => {
  const { first_name, last_name } = person;

  return (
    <div className="flex flex-row items-center mr-[12px] rounded-lg p-[8px] bg-brand-heavy-warmth-100/20">
      <Typography
        type="p"
        variant="xs"
        weight="semi-bold"
        className="text-brand-heavy-teal-100"
      >
        {first_name} {last_name}
      </Typography>
    </div>
  );
};
