import { useNavigate } from "react-router-dom";

import { useUsersStore } from "../../store/Users/users";
import { Table, TableRow } from "@/components/Table/Table";
import { useEffect, useState } from "react";
import { ApiController } from "../../../service/ApiController";
import { User } from "../UserDetails/types";
import Typography from "@/foundation/Typography/Typography";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import Icons from "@/foundation/Icons/Icons";
import {
  ActiveFilter,
  UserFilterModal,
} from "../../components/UserFilterModal/UserFilterModal";
import { Breadcrumbs } from "../../components/Breadcrumbs";

interface UserRow {
  name: string;
  email: string;
  address: string;
}

export const Users = () => {
  const navigate = useNavigate();
  const { updateUsers } = useUsersStore();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [tableRows, setTableRows] = useState<TableRow<UserRow>[]>([]);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [activeFilterOption, setActiveFilterOption] =
    useState<ActiveFilter>(true);

  const tableHeaders = [
    { key: "name", title: "Full Name" },
    { key: "email", title: "Email" },
    { key: "address", title: "Address" },
  ];

  useEffect(() => {
    const getTableRows = (input: User[]) => {
      return input.map((user) => ({
        data: {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          address: user.profile?.correspondence_address.line_1
            ? `${user.profile.correspondence_address.line_1}, ${user.profile.correspondence_address.line_2 ? `${user.profile.correspondence_address.line_2}, ` : ""}${user.profile.correspondence_address.postcode ? `${user.profile.correspondence_address.postcode}, ` : ""}`
            : "",
        },
        onClick: () => navigate(`/admin/users/${user._id}`),
      }));
    };

    async function getAllUsers() {
      setError("");
      try {
        const existingUsers = await ApiController.findAllUsers({
          isActive: activeFilterOption,
        });
        setTableRows(getTableRows(existingUsers));
      } catch (error) {
        if (error instanceof Error) {
          setError("There was an error fetching users.");
          console.error(error.message);
        }
      }
    }

    setLoading(true);
    getAllUsers();
    setLoading(false);
  }, [updateUsers, navigate, activeFilterOption]);

  return (
    <>
      <Breadcrumbs items={[{ name: "Users" }]} />

      <AdminTitle title="Users"></AdminTitle>

      {loading && (
        <Typography variant="lg" weight="bold" type="p">
          Loading...
        </Typography>
      )}
      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      {!loading && !error && (
        <div>
          <div className="mb-[16px]">
            <div className="relative h-[64px]">
              <button
                className="flex items-center justify-center border h-[64px] w-[64px] rounded-lg text-brand-heavy-teal-100 border-brand-heavy-teal-100 hover:text-brand-heavy-teal-75"
                onClick={() => setFilterModalOpen(!filterModalOpen)}
              >
                <Icons iconName="Filter" size="24" />
              </button>

              {filterModalOpen && (
                <div className="top-[68px] absolute">
                  <UserFilterModal
                    activeFilterOption={activeFilterOption}
                    setActiveFilterOption={setActiveFilterOption}
                    onCloseModal={() => setFilterModalOpen(false)}
                  />
                </div>
              )}
            </div>
          </div>
          <Table columns={tableHeaders} rows={tableRows} />
        </div>
      )}
    </>
  );
};
