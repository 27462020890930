export const clearLocalStorage = () => {
  const keys = [
    "representative",
    "transactionForm",
    "transactionsGroup",
    "purchaseIntentForm",
    "manualTransactions",
    "companySeller",
  ];

  keys.forEach((key) => localStorage.removeItem(key));
};
