import React from "react";
import { Typography } from "@pongo";

export const TransactionListTooltip: React.FC<{ label: string }> = ({
  label,
}) => (
  <Typography
    variant="xs"
    className="hidden md:group-hover:block absolute left-[50%] top-[50%] bg-brand-heavy-teal-100 text-ui-white-100 px-[8px] py-[4px] rounded-[4px] text-nowrap z-50"
  >
    {label}
  </Typography>
);
