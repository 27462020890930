/**
 * @file auth.tsx
 * @module AuthStore
 *
 * @description
 * This module provides the authentication store for managing user authentication state using Zustand.
 * It uses Zod to define and validate the schemas for user and profile data. The store maintains state
 * such as {@link isInitialized}, {@link isLoading}, and {@link userData} and offers actions to initialize, update, and reset
 * the authentication state. A custom hook {@link useAuthStore} is exported for easy access to the store within
 * React components.
 *
 */

import { create } from "zustand";
import { z } from "zod";

import { ApiController } from "../../service/ApiController";

export const UserProfileSchema = z.object({
  _id: z.string(),
  phone_number: z.string(),
  correspondence_address: z.object({
    line_1: z.string(),
    line_2: z.string().nullish(),
    line_3: z.string().nullish(),
    post_town: z.string(),
    country: z.string(),
    postcode: z.string().optional(),
    county: z.string().nullish(),
    uprn: z.string().nullish(),
    district: z.string().nullish(),
    building_number: z.string().nullish(),
    sub_building_name: z.string().nullish(),
    building_name: z.string().nullish(),
    premise: z.string().nullish(),
    organisation_name: z.string().nullish(),
    po_box: z.string().nullish(),
    country_iso_2: z.string().nullish(),
  }),
  stripe_customer_id: z.string().optional().nullable(),
});

export const CustomerProfileSchema = UserProfileSchema.extend({
  user_id: z.string(),
});

export type CustomerProfileType = z.infer<typeof CustomerProfileSchema>;

export const UserSchema = z.object({
  _id: z.string(),
  active: z.boolean(),
  createdAt: z.string(),
  email: z.string(),
  first_name: z.string().optional().nullable(),
  last_name: z.string().optional().nullable(),
  middle_name: z.string().optional().nullable(),
  profile: UserProfileSchema.optional().nullable(),
  type: z.string().optional().nullable(),
  updatedAt: z.string(),
  schema_version: z.number().optional().nullable(),
});

export type UserType = z.infer<typeof UserSchema>;

type State = {
  isInitialized: boolean;
  isLoading: boolean;
  userData: UserType | null;
};

export type Actions = {
  initialize: () => void;
  reset: () => void;
  setUserData: (userData: UserType) => void;
};

export type AuthStore = State & { actions: Actions };

// Setting the initial state for the authentication store.
const initialState: State = {
  isInitialized: false,
  isLoading: true,
  userData: null,
};

// Creating the authentication store using Zustand. The store includes state, actions for modifying the state, and an asynchronous initialize function that fetches user data from an API.
export const createAuthStore = create<AuthStore>((set) => ({
  ...initialState,
  actions: {
    initialize: async () => {
      try {
        const user = await ApiController.fetchCurrentUser();
        set({
          userData: user,
          isLoading: false,
          isInitialized: true,
        });
      } catch (error) {
        console.error("Failed to initialize user data:", error);
        set({
          isLoading: false,
          isInitialized: false,
        });
      }
    },
    setUserData: (userData: UserType) => set({ userData }),
    reset: () => set({ ...initialState }),
  },
}));

// A hook to access the authentication store from React components.
export const useAuthStore = () => createAuthStore((store) => store);
