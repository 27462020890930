import React from "react";

import { Container } from "../../components/Container/Container";
import { Help } from "../../components/Help/Help";
import { Nav } from "../../components/Nav/Nav";
import { Role } from "@shared/types";

import { DashboardHomeSale } from "./DashboardHomeSale";
import { DashboardHomePurchase } from "./DashboardHomePurchase";

interface DashboardHomeProps {
  role: Role;
}

export const DashboardHome: React.FC<DashboardHomeProps> = ({ role }) => (
  <>
    <Nav />

    <Container>
      <div className="h-screen px-[40px] pt-[36px]">
        {role === "sell" ? (
          <DashboardHomeSale role="sell" />
        ) : (
          <DashboardHomePurchase role="buy" />
        )}
      </div>

      <Help />
    </Container>
  </>
);
