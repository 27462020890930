import { useEffect, useState } from "react";
import { useTransactionContext } from "../../context/TransactionProvider";
import { CreateThirdfortRequestBody } from "../../service/AdminApiController/createThirdfortCheck";
import { Buyer, Giftors } from "../../pages/TransactionDetails/types";
import { ThirdfortTable } from "./ThirdfortTable";
import { Card } from "@/components/Card/Card";
import { Lozenge } from "@/components/Lozenge/Lozenge";

interface PurchaseIntentUser {
  purchaseIntentId: string;
  buyers: CreateThirdfortRequestBody[];
  giftors: CreateThirdfortRequestBody[];
  aborted: boolean;
}

export const ThirdfortPurchasersV2 = () => {
  const { transaction } = useTransactionContext();

  const [purchaseIntentUsers, setPurchaseIntentUsers] = useState<
    PurchaseIntentUser[]
  >([]);
  const [hasAssignedBuyerSolicitor, setHasAssignedBuyerSolicitor] =
    useState(false);

  const transformBuyerToThirdfortRequestBody = (
    buyer: Buyer,
  ): CreateThirdfortRequestBody => {
    return {
      first_name: buyer.user.first_name,
      last_name: buyer.user.last_name,
      email: buyer.user.email,
      phone_number:
        buyer.user.phone_number || buyer.user.profile?.phone_number || "",
      person_type: "buyer",
    };
  };

  const transformGiftorToThirdfortRequestBody = (
    giftors: Giftors[],
  ): CreateThirdfortRequestBody[] => {
    return giftors.map((giftor) => ({
      first_name: giftor.first_name,
      last_name: giftor.last_name,
      email: giftor.email,
      phone_number: giftor.phone_number,
      person_type: "giftor",
    }));
  };

  useEffect(() => {
    if (!transaction) return;

    const transformPurchaseIntentUsers = transaction.purchase_intent.map(
      (i) => {
        const buyers: CreateThirdfortRequestBody[] = [];
        const giftors: CreateThirdfortRequestBody[] = [];

        i.buyers.forEach((buyer) => {
          buyers.push(transformBuyerToThirdfortRequestBody(buyer));
          giftors.push(
            ...transformGiftorToThirdfortRequestBody(buyer.giftors || []),
          );
        });

        return {
          buyers,
          giftors,
          aborted: !!i.significant_dates?.aborted,
          purchaseIntentId: i._id,
        };
      },
    );

    setPurchaseIntentUsers(transformPurchaseIntentUsers);

    setHasAssignedBuyerSolicitor(!!transaction.lawyer_group?.buyer);
  }, [transaction]);

  if (!transaction) return null;

  return purchaseIntentUsers.map(
    ({ buyers, giftors, purchaseIntentId, aborted }, index) => {
      return (
        <Card
          title={`Purchase Intent ${index + 1}`}
          key={purchaseIntentId}
          titleBackgroundColor="brand-heavy-warmth-25"
          titleColor="brand-heavy-teal-100"
          titleRightContent={
            <Lozenge>{aborted ? "Inactive" : "Active"}</Lozenge>
          }
        >
          <>
            <div className="mb-[24px]">
              <ThirdfortTable
                cardTitle="Buyers"
                persons={buyers}
                actionButtonsDisabled={aborted || !hasAssignedBuyerSolicitor}
              />
            </div>

            <div className="mb-[24px]">
              {!!giftors.length && (
                <ThirdfortTable
                  cardTitle="Giftors"
                  persons={giftors}
                  actionButtonsDisabled={aborted || !hasAssignedBuyerSolicitor}
                />
              )}
            </div>
          </>
        </Card>
      );
    },
  );
};
