import React from "react";
import { Typography, Icons, TextInputIconProps } from "@pongo";

interface FilterItem {
  iconName: string;
  label: string;
}

// Filter configuration including the "Show All" option
const filters: FilterItem[] = [
  { iconName: "FeedShowAll", label: "Show All" },
  { iconName: "FeedPaymentStatus", label: "Unpaid" },
  { iconName: "FeedEstateAgents", label: "No Estate Agents" },
  { iconName: "FeedSolicitors", label: "No Solicitors" },
  { iconName: "FeedSearches", label: "No Searches" },
  { iconName: "FeedThirdfort", label: "No Thirdfort Checks" },
];

export interface FilterOption {
  filter: string;
  checked: boolean;
}

interface FeedFilterProps {
  handleFilter: (selectedFilters: FilterOption[]) => void;
}

export const FeedFilter: React.FC<FeedFilterProps> = ({ handleFilter }) => {
  // State to track if filter options are visible
  const [showOptions, setShowOptions] = React.useState<boolean>(false);

  // Set initial selected filter to "show all"
  const [selectedFilter, setSelectedFilter] =
    React.useState<string>("show all");

  const onFilterChange = (filterLabel: string) => {
    // Toggle selection: if the same filter is clicked, revert to "show all"
    const newSelectedFilter =
      filterLabel === selectedFilter ? "show all" : filterLabel;
    setSelectedFilter(newSelectedFilter);

    if (newSelectedFilter === "show all") {
      // "Show All" selected: no filters are applied
      handleFilter([]);
    } else {
      // Only send the selected filter option in the array (excluding "Show All")
      const selectedFilters: FilterOption[] = filters
        .filter((filter) => filter.label.toLowerCase() !== "show all")
        .map((filter) => ({
          filter: filter.label.toLowerCase(),
          checked: newSelectedFilter === filter.label.toLowerCase(),
        }));
      handleFilter(selectedFilters);
    }
  };

  return (
    <div className="flex flex-row items-center shrink-0 space-x-[12px] justify-start mb-[24px]">
      {/* Toggle Filter button */}
      <div className="relative">
        <button
          onClick={() => setShowOptions(!showOptions)}
          className={`flex flex-row items-center bg-brand-heavy-warmth-100/80 rounded-lg p-[4px] cursor-pointer ${showOptions ? "bg-brand-heavy-teal-100/10" : ""}`}
        >
          <div className="flex items-center justify-center w-[28px] h-[28px]">
            <Icons iconName="FeedFilter" size="18" />
          </div>

          <Typography
            type="p"
            variant="xs"
            weight="semi-bold"
            className="hidden text-brand-heavy-teal-100 ml-[4px] pr-[12px]"
          >
            Filter
          </Typography>
        </button>
      </div>

      {showOptions &&
        filters.map((filter, index) => {
          const key = filter.label.toLowerCase();
          return (
            <div key={key} className="relative">
              {/* Hidden radio input for single selection */}
              <input
                id={`filter-${index}`}
                type="radio"
                name="filter"
                className="hidden peer"
                checked={selectedFilter === key}
                onChange={() => onFilterChange(key)}
              />

              {/* Label styled as a button */}
              <label
                htmlFor={`filter-${index}`}
                className="flex flex-row items-center bg-brand-heavy-warmth-100/80 rounded-lg p-[4px] pr-[12px] cursor-pointer peer-checked:bg-brand-heavy-teal-100/10"
              >
                <div className="flex items-center justify-center w-[28px] h-[28px] mr-[4px]">
                  <Icons
                    iconName={filter.iconName as TextInputIconProps}
                    size="18"
                  />
                </div>

                <Typography
                  type="p"
                  variant="xs"
                  weight="semi-bold"
                  className="text-brand-heavy-teal-100"
                >
                  {filter.label}
                </Typography>
              </label>
            </div>
          );
        })}
    </div>
  );
};
