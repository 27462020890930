import { Typography, Icons } from "@pongo";

import { EMAIL_ADDRESS } from "../../constants";

export const Help = () => (
  <div className="fixed bottom-[24px] right-[24px]">
    <a
      href={`mailto:${EMAIL_ADDRESS.SUPPORT}`}
      className="flex items-center rounded-lg bg-brand-heavy-warmth-100 p-[12px]"
    >
      <Icons iconName="OpenEmail" size="18" />

      <Typography
        type="p"
        variant="xs"
        weight="regular"
        className="mx-[4px] text-brand-heavy-teal-100"
      >
        Email Support
      </Typography>
    </a>
  </div>
);
