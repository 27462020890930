import React from "react";
import { Typography } from "@pongo";

import { FeedItemPerson } from "./FeedItemPerson";
import { AdminTransaction } from "../../TransactionDetails/types";

interface FeedItemSellersBuyersProps {
  transaction: AdminTransaction;
}

export const FeedItemSellersBuyers: React.FC<FeedItemSellersBuyersProps> = ({
  transaction,
}) => {
  // Get the active purchase intent (if any)
  const activeIntent = transaction.purchase_intent.find(
    (intent) => intent.is_active,
  );

  // Extract the buyers from the active purchase intent
  const buyers = activeIntent?.buyers || [];

  return (
    <div className="flex flex-col justify-end">
      {/* Sellers */}
      <div className="flex flex-row justify-end items-center mb-[12px]">
        <Typography
          type="h2"
          variant="xs"
          weight="bold"
          className="text-brand-heavy-teal-100/30 mr-[12px]"
        >
          Sellers
        </Typography>

        {transaction.sellers.map((seller, index) => (
          <FeedItemPerson
            person={seller.user}
            key={`${seller.user._id}-${index}`}
          />
        ))}
      </div>

      {/* Buyers */}
      <div className="flex flex-row justify-end items-center mb-[12px]">
        <Typography
          type="h2"
          variant="xs"
          weight="bold"
          className="text-brand-heavy-teal-100/30 mr-[12px]"
        >
          Buyers
        </Typography>

        {buyers.length > 0 ? (
          buyers.map((buyer, index) => (
            <FeedItemPerson
              person={buyer.user}
              key={`${buyer.user._id}-${index}`}
            />
          ))
        ) : (
          <Typography
            type="p"
            variant="xs"
            weight="semi-bold"
            className="text-brand-heavy-teal-100 mr-[20px]"
          >
            None
          </Typography>
        )}
      </div>
    </div>
  );
};
