import React from "react";

import { buySuccess } from "../../components/Header/allStepperOptions";
import { Success } from "./Success";

export const OnboardingSuccess: React.FC = () => (
  <Success
    confirmationDescription="We've successfully onboarded you to Propelr. You will receive an email confirmation shortly. Please read it as it contains important information and next steps."
    nextStepsDescription="We will now go ahead and introduce you to your solicitors and kickoff the process of completing ID checks with our partner, Thirdfort."
    stepperOptions={buySuccess}
  />
);
