import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";

import { BuyerPropertyDetailsSchema, BuyerPropertyDetailsState } from "@schema";
import { Roles } from "@shared/types";
import { Button } from "@pongo";

import { Footer } from "../../../Footer/Footer";
import { PurchaseFunded } from "../PurchaseFunded/PurchaseFunded";
import { Price } from "../Price/Price";
import { RadioButtonQuestion } from "../RadioButtonQuestion/RadioButtonQuestion";
import { AdditionalPersonSummaryCard } from "../../../AdditionalPerson/AdditionalPersonSummaryCard";
import { AddBuyerModal } from "../AddPersonModal/AddBuyerModal";
import { BuyerPropertyAddress } from "../AddressLookup/BuyerPropertyAddress";
import { ForeignCurrency } from "../ForeignCurrency/ForeignCurrency";
import { ForeignCurrencyCard } from "../ForeignCurrency/ForeignCurrencyCard";

interface BuyerAboutThePropertyFormProps {
  state: BuyerPropertyDetailsState;
  onSubmit: (values: BuyerPropertyDetailsState) => void;
}

export const BuyerAboutThePropertyForm = ({
  state,
  onSubmit,
}: BuyerAboutThePropertyFormProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const SOLE = "Sole ownership";
  const JOINT = "Joint ownership";

  return (
    <Formik
      initialValues={state}
      onSubmit={(values) => onSubmit(values)}
      validateOnMount
      validate={withZodSchema(BuyerPropertyDetailsSchema)}
    >
      {(formik) => (
        <form
          className="flex flex-col items-start"
          onSubmit={formik.handleSubmit}
          data-testid="login-form"
        >
          {/* Sale Property Address */}

          <BuyerPropertyAddress />

          {/* Agreed Price */}

          <Price
            typeOfPrice="agreedPrice"
            placeholder="Agreed purchase price"
            priceTitle="What is the agreed purchase price?"
          />

          {/* Purchase Funded */}

          <PurchaseFunded formik={formik} />

          {/* Purchase Dependent On Sale */}

          <RadioButtonQuestion
            title="Is this purchase dependent on the sale of your existing property?"
            label="purchaseDependentOnSale"
            options={["Yes", "No"]}
            selectedRadioButtonQuestionOption={
              formik.values.purchaseDependentOnSale === undefined
                ? undefined
                : formik.values.purchaseDependentOnSale
                  ? "Yes"
                  : "No"
            }
            setSelectedRadioButtonQuestionOption={(newValue) => {
              formik.setFieldValue(
                "purchaseDependentOnSale",
                newValue === "Yes" ? true : false,
              );
            }}
            radioButtonQuestionError={
              formik.touched.purchaseDependentOnSale
                ? formik.errors.purchaseDependentOnSale
                : ""
            }
          />

          {/* Ownership */}

          <RadioButtonQuestion
            title="Who will own the property?"
            label="ownershipType"
            options={[SOLE, JOINT]}
            selectedRadioButtonQuestionOption={
              formik.values.ownershipType === undefined
                ? undefined
                : formik.values.ownershipType === "Joint"
                  ? JOINT
                  : SOLE
            }
            setSelectedRadioButtonQuestionOption={(newValue) => {
              formik.setFieldValue(
                "ownershipType",
                newValue === JOINT ? "Joint" : "Sole",
              );
              if (newValue === JOINT) {
                setIsModalOpen(true);
              }
              if (newValue === SOLE) {
                formik.setFieldValue("additionalBuyers", []);
              }
            }}
            description="Sole ownership means you will own the property by yourself only. Joint ownership means other people will be on the title. If you are going to own the property by yourself but you are going to get a joint mortgage, choose sole ownership and provide more details to your solicitor."
            radioButtonQuestionError={
              formik.touched.ownershipType ? formik.errors.ownershipType : ""
            }
          />

          <ForeignCurrency description="As part of your planned purchase or wider finances, will you be moving money internationally?" />
          <ForeignCurrencyCard
            isUsingForeignCurrency={formik.values.isUsingForeignCurrency}
          />

          <div className="flex md:flex-row md:flex-wrap flex-col w-full">
            {formik.values.additionalBuyers.map((buyer, index) => (
              <div
                key={`additional-buyer-summary-${index}`}
                className="mt-[40px]"
              >
                <AdditionalPersonSummaryCard
                  values={buyer}
                  customer={Roles.Buyer}
                  onDeletePerson={(id) => {
                    if (formik.values.additionalBuyers.length <= 1) {
                      formik.setFieldValue("ownershipType", undefined);
                    }
                    formik.setFieldValue(
                      "additionalBuyers",
                      formik.values.additionalBuyers.filter(
                        (buyer) => buyer.personId !== id,
                      ),
                    );
                  }}
                />
              </div>
            ))}
          </div>

          {isModalOpen && (
            <AddBuyerModal
              onCancel={() => {
                setIsModalOpen(false);
                formik.setFieldValue("ownershipType", undefined);
              }}
              onSaved={(newBuyer) => {
                setIsModalOpen(false);
                formik.setFieldValue("additionalBuyers", [
                  ...formik.values.additionalBuyers,
                  newBuyer,
                ]);
              }}
            />
          )}

          {formik.values.additionalBuyers.length > 0 && (
            <div className="flex w-full md:w-[400px]">
              <Button
                variant="primary"
                className="w-[320px]"
                type="button"
                onClick={() => setIsModalOpen(true)}
                icon="PlusWhite"
              >
                Add another buyer
              </Button>
            </div>
          )}

          {/* First Time Buyer */}

          <RadioButtonQuestion
            title="Are all buyers first time buyers?"
            label="firstTimeBuyer"
            options={["Yes", "No"]}
            selectedRadioButtonQuestionOption={
              formik.values.firstTimeBuyer === undefined
                ? undefined
                : formik.values.firstTimeBuyer
                  ? "Yes"
                  : "No"
            }
            setSelectedRadioButtonQuestionOption={(newValue) => {
              formik.setFieldValue(
                "firstTimeBuyer",
                newValue === "Yes" ? true : false,
              );
            }}
            radioButtonQuestionError={
              formik.touched.firstTimeBuyer ? formik.errors.firstTimeBuyer : ""
            }
          />

          <Footer
            isValid={formik.isValid}
            handleBack={() => navigate("/buy/about-you")}
          />
        </form>
      )}
    </Formik>
  );
};
