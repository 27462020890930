import React, { useEffect, useState, useMemo } from "react";
import { Link as ScrollToLink } from "react-scroll";
import { Icons } from "@pongo";

import { Container } from "../../../components/Container/Container";

import LogoWhite from "../../../assets/logo-horizontal-white.svg";
import LogoTeal from "../../../assets/logo-horizontal-teal.svg";

interface HeaderProps {
  theme: "light" | "dark";
}

export const Header: React.FC<HeaderProps> = ({ theme }) => {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  // Handle updating scroll state
  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 200);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Compute navigation background based on scroll and theme
  const navBackground = useMemo(() => {
    if (!scrolled) return "";
    return theme === "light"
      ? "bg-ui-black-100/60"
      : "bg-brand-heavy-warmth-100/80";
  }, [scrolled, theme]);

  // Base styles for link elements
  const linkBaseStyles = "border-opacity-0 hover:border-opacity-100 shrink-0";
  const linkStyles = useMemo(() => {
    return theme === "light"
      ? `${linkBaseStyles} text-ui-white-100 border-b-2 border-b-ui-white-100`
      : `${linkBaseStyles} text-brand-heavy-teal-100 border-b-2 border-b-brand-heavy-teal-100`;
  }, [theme]);

  // Base styles shared by buttons
  const baseButtonStyles =
    "text-base text-center rounded-lg px-[16px] md:px-[32px] md:w-[150px] h-12 flex leading-default items-center justify-center focus:outline-none";
  const buttonStyles =
    theme === "light"
      ? `text-ui-white-100 bg-transparent border border-ui-white-100 hover:bg-ui-white-100 hover:text-brand-heavy-teal-75 ${baseButtonStyles}`
      : `border border-brand-heavy-teal-100 text-brand-heavy-teal-100 bg-transparent hover:bg-brand-heavy-teal-100 hover:text-ui-white-100 ${baseButtonStyles}`;

  // Modal-specific styles
  const modalLinkStyles = `${linkBaseStyles} text-brand-heavy-teal-100 border-b-2 border-b-brand-heavy-teal-100`;
  const modalButtonStyles = `border border-brand-heavy-teal-100 text-ui-white-100 bg-brand-heavy-teal-100 ${baseButtonStyles}`;

  return (
    <>
      <nav
        className={`fixed left-0 right-0 top-0 z-20 transition-all duration-300 ease-in-out ${navBackground}`}
      >
        <Container maxWidth={1440}>
          <div className="flex items-center justify-between w-full py-[16px] px-[16px] md:py-[24px] md:px-[40px]">
            <div
              className="w-full flex justify-between items-center"
              data-testid="nav"
            >
              <a href="/" className="w-[130px] md:w-[166px]">
                <img
                  src={theme === "light" ? LogoWhite : LogoTeal}
                  alt="Logo"
                />
              </a>
            </div>

            <div className="flex items-center shrink-0">
              <div className="mr-[16px] md:mr-[28px] hidden md:block">
                <a href="/partner-sign-up" className={linkStyles}>
                  Estate Agents
                </a>
              </div>

              <div className="mr-[16px] md:mr-[28px] hidden md:block">
                <ScrollToLink
                  to="contact"
                  smooth
                  duration={1000}
                  className={buttonStyles}
                >
                  Get Started
                </ScrollToLink>
              </div>

              <div className="mr-[16px] md:mr-[28px] hidden md:block">
                <a href="/sign-in" className={linkStyles}>
                  Sign In
                </a>
              </div>

              {/* Mobile Menu Button (visible below md) */}
              <div className="md:hidden">
                <button
                  onClick={() => setMenuOpen(true)}
                  aria-label="Open menu"
                >
                  <Icons
                    iconName={theme === "light" ? "MenuWhite" : "Menu"}
                    size="36"
                  />
                </button>
              </div>
            </div>
          </div>
        </Container>
      </nav>

      {/* Mobile Menu Modal (only visible below md) */}
      {menuOpen && (
        <div className="fixed inset-0 z-50 bg-brand-heavy-warmth-75 flex items-center justify-center md:hidden">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg space-y-4 w-11/12 max-w-sm">
            <div className="absolute top-[16px] right-[16px]">
              <button
                onClick={() => setMenuOpen(false)}
                aria-label="Close menu"
                className="text-xl"
              >
                <Icons iconName="Close" size="36" />
              </button>
            </div>

            <a
              href="/partner-sign-up"
              className={`block ${modalLinkStyles} text-center`}
              onClick={() => setMenuOpen(false)}
            >
              Estate Agents
            </a>

            <ScrollToLink
              to="contact"
              smooth
              duration={1000}
              className={`block ${modalButtonStyles} text-center`}
              onClick={() => setMenuOpen(false)}
            >
              Get Started
            </ScrollToLink>

            <a
              href="/sign-in"
              className={`block ${modalLinkStyles} text-center`}
              onClick={() => setMenuOpen(false)}
            >
              Sign In
            </a>
          </div>
        </div>
      )}
    </>
  );
};
