import { z } from "zod";
import parsePhoneNumber from "libphonenumber-js/mobile";

export const refineMobileNumber = (value: string) => {
  const phoneNumber = parsePhoneNumber(value);
  if (
    phoneNumber &&
    phoneNumber.isValid() &&
    phoneNumber.getType() === "MOBILE"
  ) {
    return phoneNumber.format("E.164");
  }

  return false;
};

export const requiredField = <
  T extends z.ZodType<unknown, z.ZodTypeDef, unknown>,
>(
  schema: T,
  message = "Please select an option",
) => schema.refine((value) => value !== undefined, { message });
