import * as Sentry from "@sentry/react";

import { api } from ".";
import { paths } from "@shared/types.d";

export type CreatePurchaseIntentRequest =
  paths["/api/purchase-intent"]["post"]["requestBody"]["content"]["application/json"];

export const createPurchaseIntent = async (
  purchaseIntent: CreatePurchaseIntentRequest,
) => {
  try {
    const response = await api.post("/purchase-intent", purchaseIntent);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Create purchase intent failed");
  }
};
