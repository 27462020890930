import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ErrorText, Table } from "@pongo";
import { formatCurrency, getAddressAsString } from "@shared/utils";

import { ApiController } from "../../../service/ApiController";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";

interface TransactionsRow {
  _id: string;
  propertyAddress: string;
  askingPrice: string;
  estateAgent?: string;
  sellerSolicitor?: string;
  buyerSolicitor?: string;
}

interface TransactionsTable {
  transactionsGroupId?: string;
}

export const TransactionsTable = ({
  transactionsGroupId,
}: TransactionsTable) => {
  const navigate = useNavigate();
  const { setTransactionsGroup } = useTransactionsGroupContext();

  const [transactionInfo, setTransactionInfo] = useState<TransactionsRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const { id: routeId } = useParams<{ id: string }>();
  const groupId = transactionsGroupId || routeId;

  const columns = useMemo(
    () => [
      { key: "propertyAddress", title: "Property Address" },
      { key: "askingPrice", title: "Asking Price" },
      { key: "sellerSolicitor", title: "Seller Solicitor" },
      { key: "buyerSolicitor", title: "Buyer Solicitor" },
      { key: "estateAgent", title: "Estate Agent" },
    ],
    [],
  );

  const displayedTransactions = useMemo(() => {
    return transactionInfo.map(
      ({
        _id,
        propertyAddress,
        askingPrice,
        estateAgent,
        sellerSolicitor,
        buyerSolicitor,
      }) => ({
        data: {
          _id,
          propertyAddress,
          askingPrice,
          sellerSolicitor,
          buyerSolicitor,
          estateAgent,
        },
        onClick: () => navigate(`/admin/transactions/${_id}`),
      }),
    );
  }, [transactionInfo, navigate]);

  const getTransactionsFromGroup = useCallback(async () => {
    const errorMsg = "There was an error while fetching transactions groups";

    if (!groupId) {
      setError(`${errorMsg}. No valid group ID provided.`);
      setLoading(false);
      return;
    }

    setLoading(true);
    setError("");
    try {
      const transactions = await ApiController.findAllTransactions({
        groupId,
      });

      if (!transactions || transactions.length === 0) return;

      const transactionInfo = transactions.map((transaction) => {
        const { _id, property_id, asking_price, estate_agent, lawyer_group } =
          transaction;
        return {
          _id,
          propertyAddress: getAddressAsString(property_id.address, true),
          askingPrice: formatCurrency(asking_price, true, true),
          estateAgent: estate_agent
            ? `${estate_agent?.brand}-${estate_agent?.office.name}`
            : "",
          sellerSolicitor: lawyer_group?.seller
            ? `${lawyer_group?.seller.solicitor?.first_name} ${lawyer_group?.seller.solicitor?.last_name}`
            : "",
          buyerSolicitor: lawyer_group?.buyer
            ? `${lawyer_group?.buyer.solicitor?.first_name} ${lawyer_group?.buyer.solicitor?.last_name}`
            : "",
          purchaseIntents: transaction.purchase_intent,
        };
      });
      setTransactionInfo(transactionInfo);

      const hasEstateAgent = transactionInfo.some((transaction) =>
        Boolean(transaction.estateAgent),
      );
      const hasLawyerGroups = transactionInfo.some((transaction) =>
        Boolean(transaction.sellerSolicitor && transaction.buyerSolicitor),
      );
      const hasActivePurchaseIntent = transactionInfo.some((transaction) =>
        transaction.purchaseIntents.some((intent) => intent.is_active),
      );

      const groupEstateAgent = hasEstateAgent
        ? transactionInfo[0].estateAgent
        : undefined;
      const groupLawyerGroups = hasLawyerGroups
        ? {
            seller: transactionInfo[0].sellerSolicitor,
            buyer: transactionInfo[0].buyerSolicitor,
          }
        : undefined;

      setTransactionsGroup((prevGroup) => ({
        ...prevGroup,
        _id: groupId,
        estateAgent: groupEstateAgent,
        lawyerGroups: groupLawyerGroups,
        hasActivePurchaseIntent,
      }));
    } catch (error) {
      if (error instanceof Error) {
        setError(`${errorMsg}: ${error.message}`);
      } else {
        setError(errorMsg);
      }
    } finally {
      setLoading(false);
    }
  }, [groupId, setTransactionsGroup]);

  useEffect(() => {
    getTransactionsFromGroup();
  }, [groupId, getTransactionsFromGroup]);

  return (
    <>
      {loading && <FullPageLoader />}
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <Table columns={columns} rows={displayedTransactions} />
      )}
    </>
  );
};
