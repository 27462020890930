import { Route, Routes } from "react-router-dom";

import { PageNotFound } from "../pages/PageNotFound/PageNotFound";
import { DashboardHome } from "../pages/DashboardHome/DashboardHome";

export const DashboardRoutes = () => (
  <Routes>
    <Route path="/buy/:id" element={<DashboardHome role="buy" />} />
    <Route path="/sell/:id" element={<DashboardHome role="sell" />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
