import { LabelValue } from "@shared/types";
import { Property } from "../types";

export const buildPropertyDropdownOptions = (
  properties: Property[],
): LabelValue[] =>
  properties.map(({ address, _id }, index) => ({
    label: `${address.line_1}, ${address.line_2}`,
    value: _id || index.toString(),
  }));
