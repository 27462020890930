import { useEffect, useState } from "react";
import { Button, Typography } from "@pongo";

import { useTransactionContext } from "../../context/TransactionProvider";
import { ThirdfortBlock } from "../TransactionBlocks/ThirdfortBlock";
import { PurchaseIntentUser } from "../TransactionDetailsTabContent/ThirdfortTab";
import { AdminUser } from "../../pages/UserDetails/types";
import { Giftors } from "../../pages/TransactionDetails/types";
import { ThirdfortPurchasersV2 } from "./ThirdfortPurchasersV2";

export const ThirdfortPurchasersV1 = () => {
  const { transaction } = useTransactionContext();

  const [purchaseIntentUsers, setPurchaseIntentUsers] = useState<
    PurchaseIntentUser[]
  >([]);

  useEffect(() => {
    if (!transaction) return;
    const transformPurchaseIntentUsers = transaction.purchase_intent.map(
      (i) => {
        const buyers: AdminUser[] = [];
        const giftors: Giftors[] = [];
        i.buyers.forEach((seller) => {
          buyers.push(seller.user);
          giftors.push(...(seller.giftors || []));
        });
        return { buyers, giftors };
      },
    );
    setPurchaseIntentUsers(transformPurchaseIntentUsers);
  }, [transaction]);

  return transaction
    ? purchaseIntentUsers.map(({ buyers, giftors }, purchaseIntentIndex) => {
        return (
          <>
            {buyers.map((buyer, index) => (
              <ThirdfortBlock
                key={`thirdfort-buyer-${purchaseIntentIndex}-${index}`}
                title={`Buyer ${index + 1}`}
                address={transaction.property_id.address}
                profile={buyer}
                solicitorEmail={
                  transaction.lawyer_group?.buyer?.solicitor.email.toLowerCase() ||
                  ""
                }
                thirdfortEmail={
                  transaction.lawyer_group?.buyer?.thirdfortRecipientEmails
                    ?.map((email) => email.toLowerCase())
                    .join(", ") || ""
                }
                leftLabel={`Purchase Intent ${purchaseIntentIndex + 1} of ${purchaseIntentUsers.length} | Buyer ${index + 1} of ${buyers.length}`}
                userType="Buyer"
              />
            ))}

            {giftors.map((giftor, index) => (
              <ThirdfortBlock
                key={`thirdfort-giftor-${purchaseIntentIndex}-${index}`}
                title={`Giftor ${index + 1}`}
                address={transaction.property_id.address}
                profile={giftor}
                solicitorEmail={
                  transaction.lawyer_group?.buyer?.solicitor.email.toLowerCase() ||
                  ""
                }
                thirdfortEmail={
                  transaction.lawyer_group?.buyer?.thirdfortRecipientEmails
                    ?.map((email) => email.toLowerCase())
                    .join(", ") || ""
                }
                leftLabel={`Purchase Intent ${purchaseIntentIndex + 1} of ${purchaseIntentUsers.length} | Giftor ${index + 1} of ${giftors.length}`}
                userType="Buyer"
              />
            ))}
          </>
        );
      })
    : null;
};

interface ThirdfortPurchasersProps {
  showV2Purchasers: boolean;
}

export const ThirdfortPurchasers = ({
  showV2Purchasers,
}: ThirdfortPurchasersProps) => {
  const { transaction } = useTransactionContext();
  const [showV1Purchasers, setShowV1Purchasers] = useState(false);

  return (
    <>
      {showV2Purchasers && <ThirdfortPurchasersV2 />}
      {showV1Purchasers && <ThirdfortPurchasersV1 />}

      {transaction?.purchase_intent?.length === 1 && (
        <div className="flex justify-end pb-[24px]">
          <Button
            variant="link"
            onClick={() => setShowV1Purchasers((prev) => !prev)}
            className="px-0 w-auto mt-[-24px]"
          >
            <Typography
              variant="sm"
              type="span"
              className="text-brand-heavy-teal-100"
            >
              {showV1Purchasers ? "Hide" : "Show"} Full Details
            </Typography>
          </Button>
        </div>
      )}
    </>
  );
};
