/**
 * @file useSignOut.ts
 * @module useSignOut
 *
 * @description
 * This module exports the custom hook `useSignOut` which encapsulates the logic for signing out a user.
 * The hook performs the following tasks:
 *
 * - Calls an API endpoint via {@link ApiController.signOut} to terminate the user session.
 * - Clears specific keys from local storage using {@link clearLocalStorage}.
 * - Resets the authentication state through the Zustand auth store and reinitializes authentication via the
 *   {@link AuthContext}.
 * - Redirects the user to the sign-in page with a query parameter indicating that a sign-out has occurred.
 *
 * @see {@link ApiController} for API-related methods.
 * @see {@link clearLocalStorage} for clearing local storage.
 */

import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ApiController } from "../service/ApiController";
import { useAuthStore } from "../store/auth/auth";
import { AuthContext } from "../store/auth/AuthContext";
import { clearLocalStorage } from "@shared/utils/";

export const useSignOut = () => {
  const navigate = useNavigate();
  const { actions: authStoreActions } = useAuthStore();
  const authStoreContext = useContext(AuthContext);

  const signOut = useCallback(async () => {
    try {
      await ApiController.signOut();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error signing out:", error);
    }

    try {
      clearLocalStorage();
      authStoreActions.reset();
      authStoreContext?.reinitializeAuth();
    } catch (error) {
      Sentry.captureException(error);
      console.error("Error resetting state during sign-out:", error);
    }

    navigate("/sign-in?sign-out=true", { replace: true });
  }, [navigate, authStoreActions, authStoreContext]);

  return signOut;
};
