import React, { useMemo } from "react";
import { TextInputIconProps } from "@pongo";

import { AdminTransaction } from "../../TransactionDetails/types";
import { FeedItemStatus, Status } from "./FeedItemStatus";
import { useThirdfortContext } from "../../../context/ThirdfortProvider";
import { getOrderType } from "@utils";

interface FeedStatusesProps {
  transaction: AdminTransaction;
}

export const FeedStatuses: React.FC<FeedStatusesProps> = ({ transaction }) => {
  const { thirdfortChecks } = useThirdfortContext();

  // Memoize the combined emails array so that its reference doesn't change on every render.
  const allEmails = useMemo(() => {
    // Extract seller email addresses from the transaction.
    const sellerEmailAddresses = transaction.sellers.map(
      (seller) => seller.user.email,
    );

    // Find the active purchase intent.
    const activePurchaseIntent = transaction.purchase_intent.find(
      (intent) => intent.is_active,
    );

    // Extract buyer email addresses; if none exist, default to an empty array.
    const buyerEmailAddresses =
      activePurchaseIntent?.buyers.map((buyer) => buyer.user.email) ?? [];

    // Extract giftor email addresses from each buyer; default to empty array if not present.
    const giftorEmailAddresses =
      activePurchaseIntent?.buyers.flatMap(
        (buyer) => buyer.giftors?.map((giftor) => giftor.email) ?? [],
      ) ?? [];

    // Combine all email addresses from sellers, buyers, and giftors.
    return [
      ...sellerEmailAddresses,
      ...buyerEmailAddresses,
      ...giftorEmailAddresses,
    ];
  }, [transaction]);

  // Build a memoized email status map:
  // Each email is initialized to false, then updated to true if a thirdfort check for that email
  // has a status of "pending" or "complete".
  const emailStatusMap = useMemo(() => {
    const map = Object.fromEntries(allEmails.map((email) => [email, false]));
    thirdfortChecks?.forEach((check) => {
      const email = check.person_details.email;
      if (
        ["pending", "complete"].includes(check.status) &&
        map[email] !== undefined
      ) {
        map[email] = true;
      }
    });
    return map;
  }, [allEmails, thirdfortChecks]);

  // Compute the overall thirdfort status based on emailStatusMap values:
  // - If there are no emails or all flags are false, then "outstanding"
  // - If all flags are true, then "complete"
  // - Otherwise, if some are true and some false, then "inProgress"
  const flags = useMemo(() => Object.values(emailStatusMap), [emailStatusMap]);

  let thirdfortStatus: Status;
  if (flags.length === 0 || flags.every((flag) => flag === false)) {
    thirdfortStatus = "outstanding";
  } else if (flags.every((flag) => flag === true)) {
    thirdfortStatus = "complete";
  } else {
    thirdfortStatus = "inProgress";
  }

  // For display purposes, extract the first order.
  const order = transaction.orders[0];

  // Helper: Convert a boolean status to a status string.
  const getStatus = (status: boolean): Status =>
    status ? "complete" : "outstanding";

  // Build an array of statuses for rendering.
  const statuses = [
    {
      status: getStatus(order?.stripe_transaction_status === "succeeded"),
      label: getOrderType(order),
      icon: "FeedPaymentStatus" as TextInputIconProps,
    },
    {
      status: getStatus(!!transaction.estate_agent),
      label: "Estate Agent",
      icon: "FeedEstateAgents" as TextInputIconProps,
    },
    {
      status: getStatus(!!transaction.lawyer_group),
      label: "Solicitors",
      icon: "FeedSolicitors" as TextInputIconProps,
    },
    {
      status: getStatus(!!transaction.searches),
      label: "Searches",
      icon: "FeedSearches" as TextInputIconProps,
    },
    {
      status: thirdfortStatus,
      label: "Thirdfort Checks",
      icon: "FeedThirdfort" as TextInputIconProps,
    },
  ];

  return (
    <div className="w-fit flex flex-row items-start rounded-xl">
      {statuses.map((item, index) => (
        <FeedItemStatus
          key={`${item.label}-${index}`}
          status={item.status}
          label={item.label}
          icon={item.icon}
        />
      ))}
    </div>
  );
};
