import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "@pongo";

import { ApiController } from "../../../service/ApiController";

interface SearchResult {
  id: string;
  address: string;
  seller: CustomerSearchResult;
  buyer?: CustomerSearchResult;
}

interface CustomerSearchResult {
  first_name: string;
  last_name: string;
  email: string;
}

export const AdminSearch = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<SearchResult[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    // Clear results if query is too short
    if (query.trim().length < 2) {
      setResults([]);
      return;
    }

    // Trigger search on every letter typed using debouncing
    const delayDebounce = setTimeout(async () => {
      try {
        const response = await ApiController.search(query);
        setResults(response);
      } catch (error) {
        console.error("Search error:", error);
      }
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [query]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  // Clear search when page location changes
  useEffect(() => {
    setQuery("");
    setResults([]);
  }, [location]);

  // Close results when clicking outside the search container
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setQuery("");
        setResults([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={containerRef} className="w-full lg:w-[750px] relative">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search for a transaction using name, email, or property address"
        className="transition-colors duration-500 text-center focus:text-left w-full px-[16px] py-[8px] rounded-lg bg-[#EEE9E3] focus:bg-ui-white-100 border border-brand-heavy-warmth-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent placeholder:text-brand-heavy-teal-100/50 placeholder:font-normal placeholder:text-sm text-sm"
      />

      {results.length > 0 && (
        <div className="absolute top-[52px] w-full lg:w-[750px] bg-ui-white-100 border border-brand-heavy-warmth-100 rounded-lg overflow-hidden">
          {results.map((result: SearchResult) => (
            <div key={result.id}>
              <Link
                to={`/admin/transactions/${result.id}`}
                className="cursor flex flex-col lg:flex-row lg:items-center justify-between bg-ui-white-100 hover:bg-brand-heavy-teal-5 px-[16px] py-[16px]"
              >
                <Typography
                  type="span"
                  variant="sm"
                  weight="semi-bold"
                  className="mb-[8px] lg:mb-0 lg:w-[60%]"
                >
                  {result.address}
                </Typography>

                <div className="flex lg:w-[40%]">
                  <div className="flex flex-col w-[50%]">
                    <Typography
                      type="span"
                      variant="xs"
                      weight="semi-bold"
                      className="text-brand-heavy-teal-100/30"
                    >
                      {result.seller.first_name} {result.seller.last_name}
                    </Typography>

                    <Typography
                      type="span"
                      variant="xxs"
                      weight="regular"
                      className="text-brand-heavy-teal-100/30"
                    >
                      {result.seller.email}
                    </Typography>
                  </div>

                  {result.buyer ? (
                    <div className="flex flex-col w-[50%]">
                      <Typography
                        type="span"
                        variant="xs"
                        weight="semi-bold"
                        className="text-brand-heavy-teal-100/30"
                      >
                        {result.buyer.first_name} {result.buyer.last_name}
                      </Typography>

                      <Typography
                        type="span"
                        variant="xxs"
                        weight="regular"
                        className="text-brand-heavy-teal-100/30"
                      >
                        {result.buyer.email}
                      </Typography>
                    </div>
                  ) : (
                    <div className="flex flex-col w-[50%]">
                      <Typography
                        type="span"
                        variant="xs"
                        weight="semi-bold"
                        className="text-brand-heavy-teal-100/30"
                      >
                        No Buyer
                      </Typography>
                    </div>
                  )}
                </div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
