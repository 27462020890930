import { Route, Routes } from "react-router-dom";

import { AdminDashboard } from "../pages/AdminDashboard/AdminDashboard";
import { AdminPageTemplate } from "../components/AdminPageTemplate/AdminPageTemplate";
import { AskCharlie } from "../pages/AskCharlie/AskCharlie";
import { Config } from "../pages/Config/Config";
import { EstateAgentAdd } from "../pages/EstateAgentAdd/EstateAgentAdd";
import { EstateAgentBrands } from "../pages/EstateAgentBrands/EstateAgentBrands";
import { EstateAgentDetails } from "../pages/EstateAgentDetails/EstateAgentDetails";
import { EstateAgents } from "../pages/EstateAgents/EstateAgents";
import { LawFirmDetails } from "../pages/LawFirmDetails/LawFirmDetails";
import { LawFirms } from "../pages/LawFirms/LawFirms";
import { LawyerGroupDetails } from "../pages/LawyerGroupDetails/LawyerGroupDetails";
import { PageNotFound } from "../../pages/PageNotFound/PageNotFound";
import { TransactionDetails } from "../pages/TransactionDetails/TransactionDetails";
import { Transactions } from "../pages/Transactions/Transactions";
import { TransactionsGroupAdd } from "../pages/TransactionsGroupAdd/TransactionsGroupAdd";
import { TransactionsGroupDetails } from "../pages/TransactionsGroupDetails/TransactionsGroupDetails";
import { TransactionsGroups } from "../pages/TransactionsGroups/TransactionsGroups";
import { TransactionsGroupSuccess } from "../pages/TransactionsGroupSuccess/TransactionsGroupSuccess";
import { UserDetails } from "../pages/UserDetails/UserDetails";
import { Users } from "../pages/Users/Users";
import { Feed } from "../pages/Feed/Feed";

export const AdminRoutes = () => (
  <Routes>
    <Route path="/" element={<AdminPageTemplate />}>
      <Route path="ask-charlie" element={<AskCharlie />} />
      <Route path="feed" element={<Feed />} />
      <Route path="users" element={<Users />} />
      <Route path="users/:id" element={<UserDetails />} />
      <Route path="transactions" element={<Transactions />} />
      <Route path="transactions/:id" element={<TransactionDetails />} />
      <Route path="transactions-groups" element={<TransactionsGroups />} />
      <Route
        path="transactions-groups/add"
        element={<TransactionsGroupAdd />}
      />
      <Route
        path="transactions-groups/:id"
        element={<TransactionsGroupDetails />}
      />
      <Route
        path="transactions-groups/success"
        element={<TransactionsGroupSuccess />}
      />
      <Route path="estate-agents" element={<EstateAgents />} />
      <Route path="estate-agents/add" element={<EstateAgentAdd />} />
      <Route path="estate-agents/:id" element={<EstateAgentDetails />} />
      <Route path="estate-agent-brands" element={<EstateAgentBrands />} />
      <Route path="law-firms" element={<LawFirms />} />
      <Route path="law-firms/:id" element={<LawFirmDetails />} />
      <Route path="lawyer-groups/:id" element={<LawyerGroupDetails />} />
      <Route path="config" element={<Config />} />
      <Route path="/" element={<AdminDashboard />} />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  </Routes>
);
