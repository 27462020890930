import { AddressState } from "@schema";
import { RetrieveAddressResponse } from "../../../../../service/ApiController/retrieveAddress";

export const getLoqateResponseAndFormat = (
  values: RetrieveAddressResponse,
): AddressState => {
  const address = values[0];

  return {
    line_1: address.Line1,
    line_2: address.Line2 || "",
    line_3: address.Line3 || "",
    post_town: address.City,
    country: address.CountryName,
    postcode: address.PostalCode || "",
    district: address.District || "",
    building_number: address.BuildingNumber || "",
    sub_building_name: address.SubBuilding || "",
    building_name: address.BuildingName || "",
    po_box: address.POBoxNumber || "",
    country_iso_2: address.CountryIso2 || "",
  };
};
