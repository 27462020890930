import React from "react";
import { Typography, Icons } from "@pongo";

import { AdminTransaction } from "../../TransactionDetails/types";

interface FeedItemTitleProps {
  transaction: AdminTransaction;
}

export const FeedItemTitle: React.FC<FeedItemTitleProps> = ({
  transaction,
}) => {
  const {
    type,
    property_id: { address },
  } = transaction;
  const { line_1, line_2, postcode } = address;

  const iconName = type === "resale" ? "FeedHouse" : "FeedDevelopment";
  const addressLine2 = line_2 ? `${line_2}, ` : "";

  return (
    <div className="flex flex-row shrink-0">
      <div className="w-[60px] h-[60px] bg-ui-white-100/70 rounded-xl flex items-center justify-center mb-[12px] mr-[12px]">
        <Icons iconName={iconName} size="40" colour="complement" />
      </div>

      <div className="flex flex-col mr-[24px]">
        <Typography type="h2" variant="h4" weight="bold" className="mb-[5px]">
          {line_1}
        </Typography>

        <Typography type="h2" variant="base" weight="bold" className="mb-[5px]">
          {`${addressLine2}${postcode || ""}`}
        </Typography>
      </div>
    </div>
  );
};
