import * as Sentry from "@sentry/react";

import { api } from ".";
import { PurchaseIntentFormState } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import { paths } from "@shared/types.d";

export type UpdatePurchaseIntentRequest =
  paths["/api/purchase-intent/{id}"]["put"]["requestBody"]["content"]["application/json"];

export const updatePurchaseIntent = async (
  id: string,
  updatedPurchaseIntent: UpdatePurchaseIntentRequest,
): Promise<PurchaseIntentFormState> => {
  try {
    const response = await api.put(
      `/purchase-intent/${id}`,
      updatedPurchaseIntent,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Update purchase intent failed");
  }
};
