import React, { useEffect, useMemo, useState } from "react";

import { PropertyCardHeader } from "./PropertyCardHeader";
import { PropertyCardFooter } from "./PropertyCardFooter";
import { TransactionResponse, Role } from "@shared/types";
import { ApiController } from "../../service/ApiController";

import SaleBackgroundImage from "../../assets/background-propelr-cutout-heavy-teal.png";
import PurchaseBackgroundImage from "../../assets/background-propelr-cutout.png";

const saleBackgroundStyle = {
  backgroundImage: `url(${SaleBackgroundImage})`,
  backgroundPosition: "left -150px top -150px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "120%",
};

const purchaseBackgroundStyle = {
  backgroundImage: `url(${PurchaseBackgroundImage})`,
  backgroundPosition: "left -150px top -150px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "120%",
};

interface PropertyCardProps {
  transaction: TransactionResponse;
  handleResumeTransaction?: (id: string) => void;
  type: Role;
  loadEvents?: boolean;
}

export const PropertyCard: React.FC<PropertyCardProps> = ({
  transaction,
  handleResumeTransaction,
  type,
  loadEvents = true,
}) => {
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { line_1, line_2, post_town, postcode } =
    transaction.property_id.address;

  const firstLineAddress = useMemo(
    () => [line_1, line_2].filter(Boolean).join(", "),
    [line_1, line_2],
  );

  const secondLineAddress = useMemo(
    () => [post_town, postcode].filter(Boolean).join(", "),
    [post_town, postcode],
  );

  const activePurchaseIntentId = useMemo(
    () => transaction.purchase_intent.find((pi) => pi.is_active)?._id || "",
    [transaction.purchase_intent],
  );

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const events = await ApiController.fetchTransactionEvents(
          transaction._id,
        );
        const hasEvent = (eventName: string) =>
          events.some((e) => e.event_name === eventName);

        const isCompleteStatus =
          type === "sell"
            ? hasEvent("payment_successful") ||
              hasEvent("referral_code_applied")
            : hasEvent("purchase_intent_success_email_sent");

        setIsComplete(isCompleteStatus);
      } catch (error) {
        console.error("Error fetching transaction events:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (loadEvents) {
      loadEvents();
    } else {
      setIsComplete(true);
      setIsLoading(false);
    }
  }, [transaction._id, type, loadEvents]);

  const backgroundStyle =
    type === "sell" ? saleBackgroundStyle : purchaseBackgroundStyle;

  // Render nothing until loading is complete
  if (isLoading) return null;

  return (
    <div
      style={backgroundStyle}
      className="shrink-0 relative rounded-2xl block border-b-[1px] border-ui-black-10/50 p-[24px] bg-ui-white-100/70 transition-all duration-500 ease-in-out"
    >
      <div className="flex flex-col justify-between">
        <PropertyCardHeader
          firstLineAddress={firstLineAddress}
          secondLineAddress={secondLineAddress}
          type={type}
          isComplete={isComplete}
        />

        <PropertyCardFooter
          handleResumeTransaction={handleResumeTransaction}
          createdAt={transaction.createdAt}
          isComplete={isComplete}
          id={type === "sell" ? transaction._id : activePurchaseIntentId}
          type={type}
        />
      </div>
    </div>
  );
};
