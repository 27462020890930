import { useEffect, useRef, useMemo, useCallback } from "react";
import { debounce } from "lodash";

import { TextInput, Typography } from "@pongo";
import { AddressState } from "@schema";

import { ApiController } from "../../../../service/ApiController";
import { typosErrorAddressMessage } from "./errorMessages";
import { ErrorMessageWithEmail } from "./ErrorMessageWithEmail";
import { FindSuggestedAddressesResponse } from "../../../../service/ApiController/findSuggestedAddresses";
import { getLoqateResponseAndFormat } from "./utils";
import { useAddressFinderState } from "./useAddressFinderState";

interface AddressFinderProps {
  onAddressSelect: (address: AddressState) => void;
  correspondenceAddressCountryInfo?: string;
}

export const AddressFinder = ({
  onAddressSelect,
  correspondenceAddressCountryInfo,
}: AddressFinderProps) => {
  const {
    query,
    setQuery,
    suggestedAddresses,
    setSuggestedAddresses,
    loading,
    setLoading,
    error,
    setError,
    activeIndex,
    setActiveIndex,
    isDropdownOpen,
    setIsDropdownOpen,
  } = useAddressFinderState();

  const cache = useRef(new Map<string, FindSuggestedAddressesResponse>());
  const dropdownRef = useRef<HTMLUListElement>(null);

  const findAddress = useCallback(
    async (addressId?: string) => {
      try {
        setLoading(true);
        const response = await ApiController.findSuggestedAddresses(
          query,
          correspondenceAddressCountryInfo || "GB",
          addressId,
        );

        if (!response) {
          setError(typosErrorAddressMessage);
          return;
        }

        cache.current.set(query, response);
        setSuggestedAddresses(response);
        setIsDropdownOpen(true);
      } catch (error) {
        console.error("Error finding address:", error);
        setError(typosErrorAddressMessage);
      } finally {
        setLoading(false);
      }
    },
    [
      query,
      correspondenceAddressCountryInfo,
      setSuggestedAddresses,
      setLoading,
      setError,
      setIsDropdownOpen,
    ],
  );

  const fetchSuggestions = useMemo(
    () =>
      debounce(async (query) => {
        if (!query.trim() || query.length < 3) return;

        if (cache.current.has(query)) {
          setSuggestedAddresses(cache.current.get(query) || []);
          return;
        }

        await findAddress();

        setError("");
      }, 700), // Debounce API calls (fetch suggestions 0.7s after typing stops)
    [setSuggestedAddresses, setError, findAddress],
  );

  useEffect(() => {
    fetchSuggestions(query);
    return () => {
      fetchSuggestions.cancel(); // Cleanup debounce on unmount
    };
  }, [query, fetchSuggestions]);

  const handleSelectAddress = async (
    addressId: string,
    addressType: string,
  ) => {
    setLoading(false);
    setError("");

    try {
      // If the address type is not "Address", call the API again passing the address ID returned as 'containerId':
      if (addressType !== "Address") {
        findAddress(addressId);
        return;
      }
      // Proceed with the address retrieval when the "Address" type is selected:
      const addressData = await ApiController.retrieveAddress(addressId);
      if (!addressData || addressData.length === 0) {
        setError(typosErrorAddressMessage);
        return;
      }

      setError("");
      const formattedAddress = getLoqateResponseAndFormat(addressData);
      onAddressSelect(formattedAddress);
      resetSearch();
    } catch (error) {
      console.error("Error retrieving address:", error);
      setError(typosErrorAddressMessage);
    } finally {
      setLoading(false);
    }
  };

  // Reset query and suggestions after successful selection:
  const resetSearch = () => {
    setQuery("");
    setSuggestedAddresses([]);
    setActiveIndex(-1);
    setIsDropdownOpen(false);
    setLoading(false);
  };

  // Close dropdown if click is outside:
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
        setLoading(false);
      }
    },
    [setIsDropdownOpen, setLoading],
  );

  // Event listener for clicks outside:
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // Handle focus to show dropdown again when user clicks back in the input:
  const handleFocus = () => {
    if (query.trim().length >= 3) {
      setIsDropdownOpen(true);
    }
  };

  const renderAddressesSuggestions = () =>
    isDropdownOpen &&
    suggestedAddresses.length > 0 && (
      <div className="w-full relative">
        <ul
          ref={dropdownRef}
          className="absolute w-full md:w-[540px] z-10 bg-ui-white-100 shadow-md mt-[12px] rounded-lg p-[14px] max-h-60 overflow-y-auto"
        >
          {suggestedAddresses.map((address, index) => {
            if (address.Text && address.Description && address.Id)
              return (
                <li
                  key={address.Id}
                  onClick={() =>
                    address.Id &&
                    address.Type &&
                    handleSelectAddress(address.Id, address.Type)
                  }
                  className={`cursor-pointer p-2 hover:bg-gray-200 ${
                    activeIndex === index ? "bg-gray-300" : ""
                  }`}
                >
                  {address.Text}, {address.Description}
                </li>
              );
            return null;
          })}
        </ul>
      </div>
    );

  return (
    <div className="mt-4">
      <TextInput
        showIcon={true}
        icon="Search"
        className="md:w-[320px]"
        name="address"
        variant={error ? "error" : "enabled"}
        onChange={(e) => setQuery(e.target.value)}
        onFocus={handleFocus}
        label="Find Address"
        value={query}
      />

      {loading && (
        <Typography
          variant="lg"
          weight="bold"
          type="p"
          className="text-brand-heavy-teal-100 mt-[12px]"
        >
          Searching...
        </Typography>
      )}

      {error && <ErrorMessageWithEmail propertyErrorMessage={error} />}

      {/* Addresses dropwdown */}
      {renderAddressesSuggestions()}
    </div>
  );
};
