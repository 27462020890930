import { useField, useFormikContext } from "formik";
import PhoneInputWithCountry from "react-phone-number-input/mobile";
import "react-phone-number-input/style.css";

import { ErrorText, inputStatusConfig } from "@pongo";

export const PhoneInputField = () => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField<string>("mobileNumber");

  const variant = meta.touched && meta.error ? "error" : "enabled";
  const statusClasses = inputStatusConfig[variant] || "";

  return (
    <div className="relative">
      <PhoneInputWithCountry
        defaultCountry="GB"
        name="mobileNumber"
        international
        numberInputProps={{
          className: "border-none focus:outline-none",
        }}
        className={`bg-ui-white-100 w-full h-[64px] pl-[24px] pr-[14px] shadow-questionType rounded-lg border-2 hover:border-ui-black-25 flex justify-center items-center text-base text-ui-black-75 leading-none focus:border-brand-heavy-teal-100 focus:outline-none focus:shadow-outline ${statusClasses}`}
        onChange={(value) => {
          setFieldTouched("mobileNumber", true);
          setFieldValue("mobileNumber", value);
        }}
        value={field.value}
      />

      <div className="h-[16px]">
        {meta.touched && meta.error && <ErrorText>{meta.error}</ErrorText>}
      </div>
    </div>
  );
};
