import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Icons } from "@pongo";

import { Role } from "@shared/types";

interface PropertyCardFooterProps {
  createdAt: string;
  handleResumeTransaction?: (id: string) => void;
  isComplete: boolean;
  id: string;
  type: Role;
}

export const PropertyCardFooter: React.FC<PropertyCardFooterProps> = ({
  createdAt,
  handleResumeTransaction,
  isComplete,
  id,
  type,
}) => {
  const navigate = useNavigate();

  const formattedDate = useMemo(
    () =>
      new Date(createdAt).toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
    [createdAt],
  );

  const handleContinue = () => {
    if (!handleResumeTransaction || isComplete) return;
    handleResumeTransaction(id);
  };

  const handleViewDashboard = () => {
    navigate(`/dashboard/${type}/${id}`);
  };

  const actionLabel = isComplete ? "Select this property" : "Continue";
  const actionHandler = isComplete ? handleViewDashboard : handleContinue;
  const buttonClasses =
    "w-[250px] flex justify-between items-center rounded-lg bg-brand-heavy-teal-100 hover:bg-brand-heavy-teal-75 pl-[18px] pr-[12px] py-[12px] transition-all duration-500 ease-in-out";

  return (
    <div className="flex flex-row justify-between items-end">
      <Typography
        type="p"
        variant="xs"
        weight="semi-bold"
        className="text-brand-heavy-teal-100/40"
      >
        {formattedDate}
      </Typography>

      <button onClick={actionHandler} className={buttonClasses}>
        <Typography
          type="p"
          variant="sm"
          weight="semi-bold"
          className="text-ui-white-100"
        >
          {actionLabel}
        </Typography>

        <Icons iconName="SquareArrowRight" size="24" className="ml-[12px]" />
      </button>
    </div>
  );
};
