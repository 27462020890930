import { z } from "zod";

import { PersonalDetailsWithAddressSchema } from "@schema";
import { booleanPlusNotSure, customerType, ownershipType } from "@shared/types";

import { requiredField } from "./helpers";
import { EMAIL_ADDRESS } from "../constants";
import { AddressSchema } from "./AddressSchema";
import { MortgageProviderSchema } from "./MortgageProviderSchema";
import { GiftorSchema } from "./GiftorSchema";
import { FundingSchema } from "./FundingSchema";

export const BuyerPropertyDetailsSchema = z
  .object({
    _id: z.string(),
    property_id: z
      .string()
      .min(2, { message: "Please select an address" })
      .max(255, { message: "Please select an address" })
      .refine((value) => value !== "property_not_listed", {
        message: `Sorry, we couldn't find your property in our database. Before you continue, please contact our team at ${EMAIL_ADDRESS.SUPPORT}`,
      }),
    propertyAddress: AddressSchema.optional(),
    agreedPrice: z.string().refine(
      (price) => {
        const numericPrice = parseFloat(price.replace(/,/g, ""));

        return (
          !isNaN(numericPrice) &&
          numericPrice >= 99000 &&
          numericPrice <= 100000000
        );
      },
      {
        message:
          "Asking price must be higher than £99,000 and less than £100,000,000",
      },
    ),
    mortgageRequiredForPurchase: requiredField(z.enum(booleanPlusNotSure)),
    mortgageProvider: MortgageProviderSchema.optional(),
    giftors: z.array(GiftorSchema),
    funding: FundingSchema,
    purchaseDependentOnSale: requiredField(z.boolean()),
    ownershipType: requiredField(z.enum(ownershipType)),
    firstTimeBuyer: requiredField(z.boolean()),
    customerType: z.enum(customerType).optional(),
    additionalBuyers: z.array(PersonalDetailsWithAddressSchema),
    isUsingForeignCurrency: requiredField(z.boolean()),
  })
  .refine(
    (data) => {
      const isNotValid =
        data.ownershipType === "Joint" && data.additionalBuyers.length < 1;

      return !isNotValid;
    },
    {
      message: "Please add at least one buyer if you are a joint owner",
      path: ["ownershipType"],
    },
  )
  .refine(
    (data) => {
      const isNotValid =
        data.mortgageRequiredForPurchase === "yes" && !data.mortgageProvider;

      return !isNotValid;
    },
    {
      message: "Please select an option",
      path: ["mortgageProvider"],
    },
  )
  .describe(
    "Validates buyer's property details at the 'About the property' page.",
  );
