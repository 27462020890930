import React from "react";
import { Typography, Icons, TextInputIconProps } from "@pongo";

export type Status = "complete" | "inProgress" | "outstanding";

interface FeedItemStatusProps {
  status: Status;
  label: string;
  icon: TextInputIconProps;
}

export const FeedItemStatus: React.FC<FeedItemStatusProps> = ({
  status,
  label,
  icon,
}) => {
  const backgroundColorClass =
    status === "complete"
      ? "bg-ui-success-25"
      : status === "inProgress"
        ? "bg-ui-warning-100/45"
        : "bg-ui-error-25";

  return (
    <div className="flex flex-row items-center shrink-0">
      <div
        className={`flex items-center justify-center w-[28px] h-[28px] ${backgroundColorClass} rounded-lg mr-[8px]`}
      >
        <Icons iconName={icon} size="18" />
      </div>

      <Typography
        type="p"
        variant="xs"
        weight="semi-bold"
        className="text-brand-heavy-teal-100 pr-[24px]"
      >
        {label}
      </Typography>
    </div>
  );
};
