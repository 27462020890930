export type StatusOptions = "pending" | "complete" | "failed" | "aborted";

export const StatusIndicator = ({ status }: { status?: StatusOptions }) => {
  const statusClasses: Record<Exclude<StatusOptions, undefined>, string> = {
    pending: "bg-ui-warning-100 animate-pulse",
    complete: "bg-ui-success-100",
    failed: "bg-ui-error-100",
    aborted: "bg-ui-black-50",
  };

  if (!status) return null;

  return (
    <div
      className={`inline-block rounded-full h-[10px] w-[10px] mr-[8px] ${statusClasses[status]}`}
    />
  );
};
