import { useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";

import { StepperGroup, Typography, PriceCard } from "@pongo";
import { Footer } from "../../components/Footer/Footer";
import { Background } from "../../components/Background/Background";
import { Container } from "../../components/Container/Container";
import { FullPageLoader } from "../FullPageLoader/FullPageLoader";
import { Header } from "../../components/Header/Header";
import { Nav } from "../../components/Nav/Nav";
import { PaymentMethod } from "./PaymentMethod";
import { products } from "./products";
import { sellPayment } from "../../components/Header/allStepperOptions";
import { ApiController } from "../../service/ApiController";
import { UserSchema, UserType } from "../../store/auth/auth";
import { Product, StripeCustomer, StripeCustomerId } from "@shared/types";

export const Payment = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [stripeCustomerId, setStripeCustomerId] =
    useState<StripeCustomerId>("");
  const [stripeError, setStripeError] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>("");

  const handleStripeCustomer = async (validatedUser: UserType) => {
    try {
      const stripeCustomer: StripeCustomer =
        await ApiController.createOrUpdateCustomer(validatedUser);

      if (stripeCustomer.id) {
        setStripeCustomerId(stripeCustomer.id);
      }
    } catch (error) {
      console.error("Error creating or updating Stripe customer:", error);
      setStripeError(true);
    }
  };

  const fetchTransaction = useCallback(
    async (currentUser: UserType) => {
      try {
        const transaction = await ApiController.findLatestTransactionByUserId(
          currentUser._id,
        );

        if (!transaction._id) {
          throw new Error("Transaction has not been saved");
        }

        setTransactionId(transaction._id);
      } catch (error) {
        console.error("Error fetching transaction:", error);
        navigate("/sell/about-you");
      }
    },
    [navigate],
  );

  const fetchCurrentUser = useCallback(async () => {
    setIsLoading(true);

    try {
      const currentUser: UserType = await ApiController.fetchCurrentUser();
      const validatedUser = UserSchema.parse(currentUser);

      await fetchTransaction(validatedUser);
      await handleStripeCustomer(validatedUser);
    } catch (error) {
      console.error("Error fetching current user:", error);
      navigate("/sell/get-started");
    } finally {
      setIsLoading(false);
    }
  }, [navigate, fetchTransaction]);

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <Background className="flex flex-col">
      <Nav />

      <Container>
        <div className="relative flex flex-col px-[24px] lg:px-0">
          <Header title="Payment">
            <div className="md:max-w-[916px] lg:max-w-[1116px]">
              <StepperGroup options={sellPayment}></StepperGroup>
            </div>
          </Header>

          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-[60%] pt-[40px] md:pr-[34px] pb-[24px]">
              <div className="mb-[18px]">
                <Typography
                  type="h2"
                  variant="xl"
                  weight="bold"
                  data-testid="whats-included"
                  className="text-brand-heavy-teal-100"
                >
                  What&apos;s included
                </Typography>
              </div>

              {products.map((product: Product) => (
                <PriceCard product={product} key={product.name}></PriceCard>
              ))}

              <div className="-mt-[12px] max-w-[450px]">
                <Typography
                  type="p"
                  variant="sm"
                  weight="regular"
                  className="text-ui-black-50"
                >
                  Legal fees are paid &lsquo;on completion&lsquo;, which means
                  that they will come out of the proceeds of the sale. Your
                  solicitor will send you more information about this.
                </Typography>
              </div>
            </div>

            <div className="w-full md:w-[40%] pt-[40px] md:pl-[34px] lg:pl-[68px] md:border-l-2 border-brand-heavy-warmth-100">
              <PaymentMethod
                stripeError={stripeError}
                setStripeError={setStripeError}
                stripeCustomerId={stripeCustomerId}
                transactionId={transactionId}
              />
            </div>
          </div>
        </div>

        <div className="mt-[-120px] md:mt-[-100px] px-[24px] lg:px-0">
          <Footer
            handleBack={() => navigate("/sell/terms-and-conditions")}
            hasValidation={false}
            disableContinue={true}
          />
        </div>
      </Container>
    </Background>
  );
};
