import React from "react";
import { Typography, Icons } from "@pongo";

import { Role } from "@shared/types";

interface PropertyHeaderProps {
  propertyAddress: string;
  role: Role;
}

export const PropertyHeader: React.FC<PropertyHeaderProps> = ({
  propertyAddress,
  role,
}) => {
  const propertyTypeIcon = role === "sell" ? "HomeSale" : "HomePurchase";
  const propertyTypeLabel = role === "sell" ? "Sale" : "Purchase";

  return (
    <div className="mb-[36px] flex flex-row items-center">
      <div className="flex flex-row items-center rounded-lg p-[8px] bg-brand-heavy-warmth-100/80 mr-[12px]">
        <Typography
          type="p"
          variant="xs"
          weight="semi-bold"
          className="text-brand-heavy-teal-100 mr-[6px]"
        >
          {propertyTypeLabel}
        </Typography>

        <Icons iconName={propertyTypeIcon} size="18" />
      </div>

      <Typography
        type="h2"
        variant="xs"
        weight="semi-bold"
        className="text-brand-heavy-teal-100/50 mr-[8px]"
      >
        {propertyAddress}
      </Typography>
    </div>
  );
};
