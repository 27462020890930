import React from "react";
import { Typography, Icons } from "@pongo";

import { Role } from "@shared/types";

interface PropertyCardHeaderProps {
  firstLineAddress: string;
  secondLineAddress: string;
  type: Role;
  isComplete: boolean;
}

export const PropertyCardHeader: React.FC<PropertyCardHeaderProps> = ({
  firstLineAddress,
  secondLineAddress,
  type,
  isComplete,
}) => {
  const propertyTypeIcon = type === "sell" ? "HomeSale" : "HomePurchase";
  const propertyTypeLabel = type === "sell" ? "Sale" : "Purchase";
  const completionBackgroundClass = isComplete
    ? "bg-brand-electric-100"
    : "bg-brand-warmth-100";

  return (
    <div className="flex flex-row justify-between items-start">
      <div className="flex flex-col mb-[12px]">
        <div className="w-[80px] h-[80px] bg-ui-white-100 rounded-xl flex items-center justify-center mb-[12px] mr-[24px]">
          <Icons iconName={propertyTypeIcon} size="48" />
        </div>

        <div className="flex flex-col mr-[24px]">
          <Typography type="h2" variant="h4" weight="bold" className="mb-[5px]">
            {firstLineAddress}
          </Typography>
          <Typography type="h2" variant="lg" weight="bold" className="mb-[5px]">
            {secondLineAddress}
          </Typography>
        </div>
      </div>

      <div className="flex flex-row justify-end items-center space-x-[12px] mb-[12px]">
        <div className="flex flex-row items-center rounded-lg p-[8px] bg-brand-heavy-warmth-75/50">
          <Typography
            type="p"
            variant="xs"
            weight="semi-bold"
            className="text-brand-heavy-teal-100 mr-[6px]"
          >
            {propertyTypeLabel}
          </Typography>
          <Icons iconName={propertyTypeIcon} size="18" />
        </div>

        {!isComplete && (
          <div
            className={`flex flex-row items-center rounded-lg p-[8px] ${completionBackgroundClass}`}
          >
            <Typography
              type="p"
              variant="xs"
              weight="semi-bold"
              className="text-brand-heavy-teal-100 mr-[6px]"
            >
              Information Needed
            </Typography>
            <Icons iconName="WarningSquare" size="18" />
          </div>
        )}
      </div>
    </div>
  );
};
