import { BrowserRouter, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { Authenticate, LinkExpired } from "./pages/Authenticate";
import { FullPageLoader } from "./pages/FullPageLoader/FullPageLoader";
import { GetStarted } from "./pages/GetStarted/GetStarted";
import { MarketingRoutes } from "./routes/MarketingRoutes";
import { PageNotFound } from "./pages/PageNotFound/PageNotFound";
import { ProtectedRoutes } from "./routes/ProtectedRoutes";
import { ScrollToTop } from "./utils/ScrollToTop";
import { SignIn } from "./pages/SignIn/SignIn";
import { useAuthStore } from "./store/auth/auth";

// Wrap Routes with Sentry to enable Sentry error tracing for React Router v7
// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/v7/
const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

const AppRoutes = () => {
  const { isInitialized } = useAuthStore();

  if (!isInitialized) {
    return <FullPageLoader />;
  }

  return (
    <SentryRoutes>
      <Route path="/sign-in" element={<SignIn />} />

      <Route path="/authenticate" element={<Authenticate />} />
      <Route path="/authenticate/link-expired" element={<LinkExpired />} />

      <Route path="/sell/get-started" element={<GetStarted />} />
      <Route path="/buy/get-started" element={<GetStarted />} />

      {ProtectedRoutes()}
      {MarketingRoutes()}

      <Route path="*" element={<PageNotFound />} />
    </SentryRoutes>
  );
};

export const App = () => (
  <BrowserRouter>
    <ScrollToTop />
    <AppRoutes />
  </BrowserRouter>
);
