export type ThirdfortStatus = "pending" | "complete" | "failed" | "aborted";

export type ThirdfortAction = "order" | "reorder" | "abort";

export const getActionButtonLabel = (
  status?: ThirdfortStatus,
): ThirdfortAction | undefined => {
  switch (status) {
    case "pending":
      return "abort";
    case "failed":
    case "aborted":
      return "reorder";
    case "complete":
      return "reorder";
    default:
      return "order";
  }
};

export const formatPersonPhoneNumber = (phoneNumber: string): string =>
  phoneNumber.startsWith("0") ? phoneNumber.replace(/^0/, "+44") : phoneNumber;
