// This function transforms the Seller from the database into
// the Property state value used in the Transaction local storage object

import { PersonalDetailsWithAddressState } from "@schema";
import { Seller } from "../admin/pages/TransactionDetails/types";

export const transformSeller = (
  seller: Seller,
): PersonalDetailsWithAddressState => {
  const personId = seller.primary_customer
    ? seller.user._id
    : seller.user.person_id;
  const termsAndConditions = seller.primary_customer
    ? seller.terms_and_conditions
    : true;
  return {
    personId: personId || "",
    firstName: seller.user.first_name,
    middleName: seller.user.middle_name,
    lastName: seller.user.last_name,
    email: seller.user.email,
    mobileNumber:
      seller.user.phone_number || seller.user.profile?.phone_number || "",
    termsAndConditions: termsAndConditions,
    correspondenceAddress: {
      line_1:
        seller.user.correspondence_address?.line_1 ||
        seller.user.profile?.correspondence_address?.line_1 ||
        "",
      line_2:
        seller.user.correspondence_address?.line_2 ||
        seller.user.profile?.correspondence_address?.line_2 ||
        "",
      line_3:
        seller.user.correspondence_address?.line_3 ||
        seller.user.profile?.correspondence_address?.line_3 ||
        "",
      post_town:
        seller.user.correspondence_address?.post_town ||
        seller.user.profile?.correspondence_address?.post_town ||
        "",
      postcode:
        seller.user.correspondence_address?.postcode ||
        seller.user.profile?.correspondence_address?.postcode ||
        "",
      country:
        seller.user.correspondence_address?.country ||
        seller.user.profile?.correspondence_address?.country ||
        "",
      county:
        seller.user.correspondence_address?.county ||
        seller.user.profile?.correspondence_address?.county,
      uprn:
        seller.user.correspondence_address?.uprn ||
        seller.user.profile?.correspondence_address?.uprn,
      district:
        seller.user.correspondence_address?.district ||
        seller.user.profile?.correspondence_address?.district,
      building_number:
        seller.user.correspondence_address?.building_number ||
        seller.user.profile?.correspondence_address?.building_number,
      sub_building_name:
        seller.user.correspondence_address?.sub_building_name ||
        seller.user.profile?.correspondence_address?.sub_building_name,
      building_name:
        seller.user.correspondence_address?.building_name ||
        seller.user.profile?.correspondence_address?.building_name,
      premise:
        seller.user.correspondence_address?.premise ||
        seller.user.profile?.correspondence_address?.premise,
      organisation_name:
        seller.user.correspondence_address?.organisation_name ||
        seller.user.profile?.correspondence_address?.organisation_name,
      po_box:
        seller.user.correspondence_address?.po_box ||
        seller.user.profile?.correspondence_address?.po_box,
      country_iso_2:
        seller.user.correspondence_address?.country_iso_2 ||
        seller.user.profile?.correspondence_address?.country_iso_2 ||
        "",
    },
    primaryCustomer: seller.primary_customer,
    customerType: seller.customer_type || seller.user.customer_type,
  };
};
