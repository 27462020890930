import { Typography } from "@pongo";

import { toTitleCase } from "@shared/utils";
import { ThirdfortStatus } from "./utils";
import { StatusIndicator, StatusOptions } from "./StatusIndicator";

interface StatusCellProps {
  status: ThirdfortStatus;
}

export const StatusCell = ({ status }: StatusCellProps) => (
  <div className="flex items-center">
    <StatusIndicator status={status as StatusOptions} />

    <Typography
      type="p"
      variant="sm"
      weight="regular"
      className="min-w-[100px]"
    >
      {status ? toTitleCase(status) : "Not requested"}
    </Typography>
  </div>
);
