export const firstResponseData = {
  engrossmentFeeIssue: {
    description:
      "The engrossment fee refers to a charge for preparing the final legal version of the contract or deed. In this transaction, the fee was £540 (including VAT) and was contractually assigned to the buyer under Clause 10.5(b) of the contract.",
  },
  buyerConcerns: [
    {
      issue: "Misunderstanding of Fee Responsibility",
      details:
        "The buyer believed all legal fees were covered under their agreement with Propelr. They were not expecting to pay additional costs and saw this fee as unexpected and potentially unfair.",
    },
    {
      issue: "Dispute Over Amount",
      details:
        "The buyer’s solicitor mentioned the client expected an engrossment fee closer to £100-£250, based on typical industry costs for similar transactions. The higher fee of £540 caused frustration, as they believed this exceeded standard charges.",
    },
  ],
  resolutionStatus: {
    resolved: true,
    details:
      "The issue has been fully resolved. After some back and forth, the buyer ultimately paid the £540 engrossment fee. This allowed the transaction to proceed, with funds being released and the TA13 form being attached for exchange.",
  },
  actionRequired: {
    isRequired: false,
    details:
      "The fee dispute has been settled, and the transaction is now progressing toward completion. The next steps involve finalising fund transfers and ensuring all documentation is in place.",
  },
};

export const secondResponseData = {
  engrossmentFeeIssueResolution: {
    communications: [
      {
        status: "Payment Confirmed",
        timestamp: "On 21 February 2025 at 10:53 AM",
        actor: "Holly Kilpatrick (Buyer's Solicitor)",
        message: "We received the engrossment fee.",
      },
      {
        status: "Funds Released",
        timestamp: "Shortly after, at 10:57 AM",
        actor: "Holly Kilpatrick (Buyer's Solicitor)",
        message:
          "I will instruct Accounts to send out funds, these are strictly held to our order pending receipt of the TA13.",
      },
      {
        status: "Transaction Progressing",
        timestamp: "At 12:07 PM on the same day",
        actor: "Priya Shah (Seller’s Solicitor)",
        message:
          "Funds received and TA13 attached. Could you confirm they are released?",
      },
    ],
    conclusion: {
      resolved: true,
      details:
        "The buyer paid the fee, and the transaction proceeded without further issues. No action is required from you.",
    },
  },
};
