import React from "react";
import { StepperGroup, StepperProps } from "@pongo";

import { Background } from "../../components/Background/Background";
import { Container } from "../../components/Container/Container";
import { FullPageLoader } from "../../pages/FullPageLoader/FullPageLoader";
import { Header } from "../../components/Header/Header";
import { Nav } from "../../components/Nav/Nav";
import { PropertyCard } from "../../components/PropertyCard/PropertyCard";
import { useAuthStore } from "../../store/auth/auth";
import { useFetchLatestTransactionAndPurchaseIntent } from "../../hooks";
import { useLoginStore } from "../../store/Login/login";

import { ConfirmationMessage } from "./ConfirmationMessage";

interface SuccessProps {
  confirmationDescription: string;
  stepperOptions: StepperProps[];
  nextStepsDescription: string;
}

export const Success: React.FC<SuccessProps> = ({
  stepperOptions,
  confirmationDescription,
  nextStepsDescription,
}) => {
  const { role } = useLoginStore();
  const { userData } = useAuthStore();
  const { transaction, purchaseIntent } =
    useFetchLatestTransactionAndPurchaseIntent(userData, role);

  const selectedTransaction = role === "sell" ? transaction : purchaseIntent;

  // Show a loading indicator until the required data is available.
  if (!userData || !selectedTransaction) {
    return <FullPageLoader />;
  }

  return (
    <Background className="flex flex-col">
      <Nav />
      <Container>
        <div className="relative flex flex-col px-[24px] lg:px-0">
          <Header title="Congratulations!">
            <div className="md:max-w-[916px] lg:max-w-[1116px]">
              <StepperGroup options={stepperOptions} />
            </div>
          </Header>

          <div className="flex flex-col items-start">
            <ConfirmationMessage
              confirmationDescription={confirmationDescription}
              nextStepsDescription={nextStepsDescription}
            />
            <div className="w-full max-w-[700px] mb-[40px]">
              <PropertyCard
                transaction={selectedTransaction}
                type={role}
                loadEvents={false}
              />
            </div>
          </div>
        </div>
      </Container>
    </Background>
  );
};
