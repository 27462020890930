import React, { useMemo } from "react";
import { Typography } from "@pongo";

import { Container } from "../Container/Container";
import { PropertyHeader } from "../PropertyHeader/PropertyHeader";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";
import { useLoginStore } from "../../store/Login/login";

interface HeaderProps {
  children?: React.ReactNode;
  title: string;
  noBorder?: boolean;
  showTransactionType?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  children,
  noBorder = false,
  showTransactionType = true,
}) => {
  const { role } = useLoginStore();
  const { purchaseIntent } = usePurchaseIntentFormStore();
  const { transaction } = useTransactionFormStore();

  const propertyAddress = useMemo(() => {
    const defaultValue = "Waiting for address...";

    if (role === "sell" && transaction?.property?.propertyAddress.line_1) {
      const { line_1, line_2, post_town, postcode } =
        transaction.property.propertyAddress;
      const displayAddress = [line_1, line_2, post_town, postcode]
        .filter(Boolean)
        .join(", ");

      return displayAddress;
    } else if (
      role === "buy" &&
      purchaseIntent?.property?.propertyAddress?.line_1
    ) {
      const { line_1, line_2, post_town, postcode } =
        purchaseIntent.property.propertyAddress;

      const displayAddress = [line_1, line_2, post_town, postcode]
        .filter(Boolean)
        .join(", ");

      return displayAddress;
    } else {
      return defaultValue;
    }
  }, [role, transaction, purchaseIntent]);

  return (
    <div className="w-full z-10 flex flex-col pt-[20px] md:pt-[40px]">
      <Container>
        {showTransactionType && (
          <PropertyHeader propertyAddress={propertyAddress} role={role} />
        )}

        <Typography
          type="h1"
          variant="h1"
          weight="bold"
          className="mb-[32px] text-brand-heavy-teal-100 font-display"
          data-testid="header-title"
        >
          {title}
        </Typography>

        {children}

        {!noBorder && (
          <hr className="border-t-2 border-brand-heavy-warmth-100 mt-[16px] md:mt-[40px]" />
        )}
      </Container>
    </div>
  );
};
