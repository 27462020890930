import * as Sentry from "@sentry/react";

import { operations } from "@shared/types.d";
import { api } from "../../../service/ApiController";
import { AdminTransaction } from "../../pages/TransactionDetails/types";
import { getSearchParamsStringFromObject } from "@utils";

export type GetTransactionsQueryParams =
  operations["TransactionController_findAll"]["parameters"]["query"];

export const findAllTransactions = async (
  params: GetTransactionsQueryParams,
): Promise<AdminTransaction[]> => {
  try {
    const response = await api.get(
      `/transaction?${getSearchParamsStringFromObject(params)}`,
    );
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Transactions not found");
  }
};
