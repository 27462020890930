import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { sellPaymentSuccess } from "../../components/Header/allStepperOptions";
import { Success } from "./Success";

export const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Extract query parameters
  const paymentIntent = searchParams.get("payment_intent");
  const redirectStatus = searchParams.get("redirect_status");
  const isReferralCodeApplied = searchParams.get("code") === "true";

  // Redirect if payment intent is missing or not succeeded and referral code is not applied
  useEffect(() => {
    if (
      (!paymentIntent || redirectStatus !== "succeeded") &&
      !isReferralCodeApplied
    ) {
      navigate("/sell/payment");
    }
  }, [paymentIntent, redirectStatus, isReferralCodeApplied, navigate]);

  return (
    <Success
      confirmationDescription="We've received your payment and successfully onboarded your property to Propelr. You will receive an email confirmation shortly."
      stepperOptions={sellPaymentSuccess}
      nextStepsDescription="We will now go ahead and order your property searches, introduce you to your solicitors and kickoff the process of completing ID checks with our partner, Thirdfort."
    />
  );
};
