import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@pongo";

interface BreadcrumbItem {
  name: string;
  url?: string;
}

interface BreadcrumbsProps {
  items?: BreadcrumbItem[];
}

export const Breadcrumbs = ({ items = [] }: BreadcrumbsProps) => {
  const homeItem: BreadcrumbItem = { name: "Home", url: "/admin" };
  const allItems = [homeItem, ...items].slice(0, 10);

  return (
    <div className="w-full flex justify-start items-center">
      {allItems.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <BreadcrumbSeparator />}
          {item.url ? (
            <Link to={item.url}>
              <Typography
                weight="semi-bold"
                className="text-brand-heavy-teal-100/50 hover:text-brand-heavy-teal-75 cursor-pointer"
                variant="sm"
              >
                {item.name}
              </Typography>
            </Link>
          ) : (
            <Typography
              weight="semi-bold"
              className="text-brand-heavy-teal-100/80"
              variant="sm"
            >
              {item.name}
            </Typography>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const BreadcrumbSeparator = () => (
  <Typography
    weight="semi-bold"
    className="text-brand-heavy-teal-100/50 cursor-pointer relative top-[0px]"
  >
    <svg
      width="22"
      height="22"
      viewBox="0 0 25 25"
      fill="none"
      stroke="currentColor"
    >
      <path d="M10.5 8L15 12.5L10.5 17" strokeWidth="1.2" />
    </svg>
  </Typography>
);
