import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { MonthlyTransactionCount } from "../../components/MonthlyTransactionsCount/MonthlyTransactionCount";
import { useAuthStore } from "../../../store/auth/auth";

export const AdminDashboard = () => {
  const { userData } = useAuthStore();

  return (
    <>
      <AdminTitle
        title={`Welcome back, ${userData?.first_name || "Unknown user"}`}
        setAsTitleCase={false}
      />

      <div className="flex flex-col md:flex-row md:space-x-5">
        <div className="w-full mb-[24px]">
          <MonthlyTransactionCount />
        </div>
      </div>
    </>
  );
};
