import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

export const initializeSentry = async () => {
  try {
    // Ensure constants are loaded dynamically
    const constants = await import("./constants.ts");
    const { NODE_ENV, SENTRY_DSN, RELEASE_VERSION } = constants;

    if (!SENTRY_DSN) {
      console.warn("SENTRY_DSN is missing. Sentry will not be initialized.");
      return;
    }

    // If environment is Staging or Production, initialize Sentry
    if (NODE_ENV === "staging" || NODE_ENV === "production") {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: NODE_ENV,
        integrations: [
          Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          }),
          Sentry.replayIntegration(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        tracesSampleRate: 1.0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [
          "localhost",
          "https://www.propelr.co.uk",
          "https://staging.propelr.co.uk",
        ],
        release: `borneo-frontend@${RELEASE_VERSION}`,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,

        // Enable debug mode
        debug: false,
      });

      console.log(`NODE_ENV is ${NODE_ENV}. Sentry initialized.`);
    } else {
      console.log(`NODE_ENV is ${NODE_ENV}. Sentry not initialized.`);
    }
  } catch (error) {
    console.error("Error initializing Sentry:", error);
  }
};
