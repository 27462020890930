import { z } from "zod";
import { postcodeValidator } from "postcode-validator";

const baseAddressSchema = z.object({
  line_1: z
    .string()
    .min(2, { message: "Address must be at least 2 characters long" })
    .max(255, { message: "Address must be less than 255 characters long" }),
  line_2: z.string().nullish(),
  line_3: z.string().nullish(),
  post_town: z
    .string()
    .min(2, { message: "Town must be at least 2 characters long" })
    .max(255, { message: "Town must be less than 255 characters long" }),
  country: z
    .string()
    .min(2, { message: "Country must be at least 2 characters long" })
    .max(255, { message: "Country must be less than 255 characters long" }),
  county: z.string().nullish(),
  uprn: z.string().nullish(),
  district: z.string().nullish(),
  building_number: z.string().nullish(),
  sub_building_name: z.string().nullish(),
  building_name: z.string().nullish(),
  premise: z.string().nullish(),
  organisation_name: z.string().nullish(),
  po_box: z.string().nullish(),
});

export const AddressSchema = baseAddressSchema.extend({
  postcode: z
    .string()
    .refine((str) => postcodeValidator(str, "GB"), {
      message: "Invalid postcode",
    })
    .optional(),
  country_iso_2: z.string().nullish(),
});

const internationalAddressBaseSchema = baseAddressSchema.extend({
  postcode: z.string().optional(),
  country_iso_2: z.string().min(2),
});

export const internationalAddressSchema = internationalAddressBaseSchema.refine(
  (data) => {
    // If postcode exists and is not empty, it must be valid for the specified country
    if (
      data.postcode &&
      data.postcode.length > 0 &&
      !postcodeValidator(data.postcode, data.country_iso_2.toUpperCase())
    ) {
      return false;
    }
    return true;
  },
  {
    message: "Invalid postcode for the specified country",
    path: ["postcode"],
  },
);
