import { useCallback, useEffect } from "react";
import { useField } from "formik";

import { AddressState } from "@schema";
import { Typography } from "@pongo";

import { AddressDisplayCard } from "./AddressDisplayCard";
import { FieldSet } from "../../FieldSet/FieldSet";
import { useTransactionFormStore } from "../../../../store/TransactionForm/transactionForm";
import { AddressFinder } from "./AddressFinder";
import { CountrySelector } from "./CountrySelector";
import { useAddressInputsRendering } from "./useAddressInputsRendering";
import { useAddressInputsState } from "./useAddressInputsState";
import { isRequiredAddressComplete } from "./utils/index";

interface AddressInputsProps {
  namespace: string;
  addressTitle: string;
  addressDescription?: string;
  cardAddressTitle: string;
  showUseCorrespondenceAddress?: boolean;
  allowInternationalAddress?: boolean;
}

export const AddressInputs = ({
  namespace,
  addressTitle,
  addressDescription,
  cardAddressTitle,
  showUseCorrespondenceAddress = false,
  allowInternationalAddress = false,
}: AddressInputsProps) => {
  const { transaction } = useTransactionFormStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, _, helpers] = useField<AddressState>(namespace);

  // Address Input state:
  const {
    isPageLoaded,
    setIsPageLoaded,
    isManualAddressOpen,
    setIsManualAddressOpen,
    isEditingAddress,
    setIsEditingAddress,
    correspondenceAddressCountryInfo,
    setCorrespondenceAddressCountryInfo,
    fetchError,
    setFetchError,
  } = useAddressInputsState();

  // Input Address validation:
  // This is commented out because we are hiding manual address:
  // const { validateFields } = useAddressValidation(
  //   namespace,
  //   allowInternationalAddress,
  // );

  // Render Address Inputs:
  const {
    renderAddressFinder,
    renderCountrySelector,
    canUseCorrespondenceAddress,
  } = useAddressInputsRendering({
    field,
    allowInternationalAddress,
    showUseCorrespondenceAddress,
    fetchError,
    correspondenceAddressCountryInfo,
    isManualAddressOpen,
  });

  useEffect(() => {
    // Set countryInfo based on namespace:
    console.log(field.value.country_iso_2);
    if (namespace === "correspondenceAddress") {
      // Set countryInfo on country change:
      if (field.value.country_iso_2) {
        setCorrespondenceAddressCountryInfo(field.value.country_iso_2);
      }
    }
    if (namespace === "propertyAddress") {
      setCorrespondenceAddressCountryInfo(
        transaction.primarySeller.correspondenceAddress.country_iso_2,
      );
    }
  }, [
    setCorrespondenceAddressCountryInfo,
    field.value.country_iso_2,
    transaction,
    namespace,
  ]);

  useEffect(() => {
    if (!isPageLoaded && isRequiredAddressComplete(field.value)) {
      setIsEditingAddress(false);
    }
    setIsPageLoaded(true);
  }, [isPageLoaded, field.value, setIsEditingAddress, setIsPageLoaded]);

  // This is commented out because we are hiding manual address:
  // const disableSaveAddressButton = useCallback(() => {
  //   return !isRequiredAddressComplete(field.value) || validateFields();
  // }, [field.value, validateFields]);

  // const handleSaveAddress = () => {
  //   setIsManualAddressOpen(false);
  //   setIsEditingAddress(false);
  // };

  const handleUseCorrespondenceAddress = useCallback(() => {
    helpers.setValue(transaction.primarySeller.correspondenceAddress);
    setIsEditingAddress(false);
  }, [transaction, helpers, setIsEditingAddress]);

  const clearInvisibleFieldsOnAddress = () => {
    const addressValues: AddressState = { ...field.value };
    helpers.setValue(addressValues);
  };

  const onSetAddress = useCallback(
    (addressValues: AddressState) => {
      helpers.setValue(addressValues);
      setIsEditingAddress(false);
    },
    [helpers, setIsEditingAddress],
  );

  const renderIsEditingAddress = () => (
    <>
      {renderCountrySelector && (
        <CountrySelector
          namespace={namespace}
          onFetchError={() => setFetchError(true)}
        />
      )}

      {renderAddressFinder && (
        <AddressFinder
          onAddressSelect={onSetAddress}
          correspondenceAddressCountryInfo={
            correspondenceAddressCountryInfo || "GB"
          }
        />
      )}
      {/* This is commented out because we are hiding manual address: */}
      {/* 
      {renderManualAddress && (
        <ManualAddress
          namespace={namespace}
          isManualAddressOpen={isManualAddressOpen}
          setIsManualAddressOpen={setIsManualAddressOpen}
          displayCountryField={displayCountryField}
          isUKAddress={
            field.value.country_iso_2 === "GB" ||
            namespace === "propertyAddress" // When property address is being edited, it is always a UK address
          }
        />
      )}

      {renderSaveAddressButton && (
        <Button
          size="2xl"
          variant="primary"
          disabled={disableSaveAddressButton()}
          type="button"
          onClick={handleSaveAddress}
          className="w-full md:w-[200px] h-[64px] my-[16px]"
        >
          Save address
        </Button>
      )} */}

      {canUseCorrespondenceAddress && (
        <div className="my-[16px]">
          <button
            className="underline cursor-pointer text-brand-heavy-teal-75"
            onClick={handleUseCorrespondenceAddress}
            type="button"
          >
            <Typography
              variant="base"
              weight="regular"
              type="span"
              className="text-brand-heavy-teal-75"
            >
              Use correspondence address
            </Typography>
          </button>
        </div>
      )}
    </>
  );

  const renderDisplayAddressCard = () => (
    <AddressDisplayCard
      onEditAddress={() => {
        setIsEditingAddress(true);
        setIsManualAddressOpen(true);
        clearInvisibleFieldsOnAddress();
      }}
      cardAddressTitle={cardAddressTitle}
      address={field.value}
    />
  );

  return (
    <FieldSet legend={addressTitle} description={addressDescription}>
      {isEditingAddress ? renderIsEditingAddress() : renderDisplayAddressCard()}
    </FieldSet>
  );
};
