import { Typography, ErrorText, Icons } from "@pongo";

import { TransactionsTable } from "../TransactionsGroupDetails/TransactionsTable";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";

export const TransactionsGroupSuccessDetails = () => {
  const { transactionsGroup, loading, error } = useTransactionsGroupContext();

  return (
    <>
      {loading && <FullPageLoader />}
      {error && <ErrorText>{error}</ErrorText>}

      <div className="flex items-center mb-[32px] pt-[48px]">
        <Icons iconName="GreenTick" size="24" />
        <Typography
          type="h4"
          variant="h4"
          weight="bold"
          className="ml-[8px] text-brand-heavy-teal-100"
        >
          You&apos;ve successfully created a transaction group
        </Typography>
      </div>

      {transactionsGroup._id && (
        <>
          <div className="flex items-center mb-[32px] pt-[48px]">
            <Typography
              type="h4"
              variant="h4"
              weight="bold"
              className="mr-[8px] text-brand-heavy-teal-100"
            >
              Transactions created:
            </Typography>
          </div>
          <TransactionsTable transactionsGroupId={transactionsGroup._id} />
        </>
      )}
    </>
  );
};
