import React from "react";
import { Link } from "react-router-dom";

import { AdminTransaction } from "../../TransactionDetails/types";
import { FeedItemHeader } from "./FeedItemHeader";
import { FeedItemSellersBuyers } from "./FeedItemSellersBuyers";
import { FeedStatuses } from "./FeedStatuses";
import { FeedItemLatestEvent } from "./FeedItemLatestEvent";
import { FeedItemTitle } from "./FeedItemTitle";

interface FeedItemProps {
  transaction: AdminTransaction;
}

export const FeedItem: React.FC<FeedItemProps> = ({ transaction }) => (
  <Link
    to={`/admin/transactions/${transaction._id}`}
    className="rounded-2xl block bg-ui-white-100/40 border-b-[1px] border-ui-black-10/50 p-[24px] hover:bg-ui-white-100/70 transition-all duration-500 ease-in-out mb-[24px]"
  >
    <div className="flex flex-col space-y-6">
      <div className="flex flex-row justify-between items-start">
        <FeedItemTitle transaction={transaction} />
        <FeedItemSellersBuyers transaction={transaction} />
      </div>

      <FeedItemLatestEvent transaction={transaction} />

      <div className="flex flex-row justify-between items-end">
        <FeedItemHeader transaction={transaction} />
        <FeedStatuses transaction={transaction} />
      </div>
    </div>
  </Link>
);
