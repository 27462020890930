import { useNavigate } from "react-router-dom";

import { useTransactionFormStore } from "../store/TransactionForm/transactionForm";
import { ApiController } from "../service/ApiController";
import { UpdateUserResponse } from "../service/ApiController/updateUser";
import {
  transformTransactionPropertyState,
  transformTransactionSellerState,
  transformUpdateTransactionFormForTransactionUpdateApi,
  transformCreateTransactionFormForTransactionCreateApi,
  transformUpdatePrimaryCustomerForUpdateCustomerApi,
} from "@transformers";

export const useTransactionHandler = () => {
  const { transaction, updateTransaction } = useTransactionFormStore();
  const navigate = useNavigate();

  const updatePrimarySeller = async () => {
    const transformedPrimarySeller =
      transformUpdatePrimaryCustomerForUpdateCustomerApi(
        transaction.primarySeller,
      );
    return await ApiController.updateUser(transformedPrimarySeller);
  };

  // Determines whether to create or update a transaction:
  const processTransaction = async () => {
    const existingTransaction = transaction._id
      ? await ApiController.findTransaction(transaction._id)
      : null;

    // Update customer (primary seller):
    const updatedCustomer = await updatePrimarySeller();

    if (existingTransaction) {
      await updateExistingTransaction(updatedCustomer);
    } else {
      await createNewTransaction(updatedCustomer);
    }
  };

  const updateExistingTransaction = async (
    updatedCustomer: UpdateUserResponse,
  ) => {
    const transformedTransaction =
      transformUpdateTransactionFormForTransactionUpdateApi(
        transaction,
        updatedCustomer,
      );
    await ApiController.updateTransaction(transformedTransaction);
    navigate("/sell/payment");
  };

  const createNewTransaction = async (updatedCustomer: UpdateUserResponse) => {
    transaction.primarySeller.termsAndConditions = true;

    const transformedTransaction =
      transformCreateTransactionFormForTransactionCreateApi(
        transaction,
        updatedCustomer,
      );
    const createdTransaction = await ApiController.createTransaction(
      transformedTransaction,
    );

    if (createdTransaction) {
      updateTransaction({
        ...createdTransaction,
        primarySeller: transformTransactionSellerState(
          createdTransaction.sellers,
        ),
        property: transformTransactionPropertyState(createdTransaction),
      });
      navigate("/sell/payment");
    }
  };

  return { processTransaction };
};
