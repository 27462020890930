import { Country } from "react-country-state-city/dist/esm/types";

import { DropdownOption } from "@shared/types";

export const buildCountriesDropdownOptions = (
  countries: Country[],
): DropdownOption<string>[] => [
  { label: "Select a country", value: "", disabled: true },
  {
    label: "────────",
    value: "lineBreak",
    isLineBreak: true,
  },
  ...countries
    .filter(({ name }) => name === "United Kingdom")
    .map(({ name }) => ({
      label: name,
      value: name,
    })),
  {
    label: "────────",
    value: "lineBreak2",
    isLineBreak: true,
  },
  ...countries
    .filter(({ name }) => name !== "United Kingdom")
    .map(({ name }) => ({
      label: name,
      value: name,
    })),
];
