// APP
export const RELEASE_VERSION = import.meta.env.VITE_RELEASE_VERSION;
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const CLIENT_ORIGIN_URL = import.meta.env.VITE_CLIENT_ORIGIN_URL;
export const NODE_ENV = import.meta.env.VITE_NODE_ENV;

// EMAIL
export const EMAIL_ADDRESS = {
  NO_REPLY: "no-reply@propelr.co.uk",
  SUPPORT: "support@propelr.co.uk",
};

// TODO: Rename this to something more obvious
export enum FieldType {
  Email = "Email",
  MobileNumber = "Mobile Number",
  PostCode = "Post Code",
}

// STRIPE
export const STRIPE_CLIENT_API_KEY = import.meta.env.VITE_STRIPE_CLIENT_API_KEY;

// SENTRY
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

// GOOGLE ReCAPTCHA v3
export const RECAPTCHA_SITE_KEY = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

// Land Registry
export const LAND_REGISTRY_URL =
  "https://search-property-information.service.gov.uk/";

// Thirdfort URLs
export const THIRDFORT_URL = import.meta.env.VITE_THIRDFORT_URL;
