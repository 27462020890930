import Typography from "../../foundation/Typography/Typography";
import Icons from "../../foundation/Icons/Icons";

export interface SolicitorCardProps {
  card: {
    [key: string]: string | number | undefined;
  };
}

export const SolicitorCard = ({ card }: SolicitorCardProps) => {
  const {
    name,
    solicitorBio,
    solicitorName,
    startDate,
    line_1,
    line_2,
    line_3,
  } = card;

  return (
    <div className="relative w-full md:max-w-[600px] bg-ui-white-100 shadow-questionType rounded-lg pb-[24px] mb-[30px] border border-ui-black-10">
      <div className="bg-brand-heavy-teal-100 flex items-center rounded-t-lg px-[32px] py-[16px]">
        <Typography
          type="h4"
          variant="h4"
          weight="bold"
          className="text-ui-white-100"
        >
          {name}
        </Typography>
      </div>

      <div className="flex flex-col px-[24px]">
        <div className="flex justify-center gap-x-[32px] items-center mt-[24px]">
          <div className="flex flex-col">
            <Typography
              type="p"
              variant="h3"
              weight="regular"
              className="text-brand-heavy-teal-75 text-center"
            >
              <strong>We will introduce you via email</strong> <br />
              to your solicitor.
            </Typography>
          </div>
        </div>

        <hr className="border-x border-brand-heavy-teal-25 my-[25px]" />

        {startDate ? (
          <div className="flex justify-center items-center mx-[24px] mt-[8px]">
            <Typography
              type="h2"
              variant="h4"
              weight="regular"
              className="text-brand-heavy-teal-100 text-center"
            >
              {solicitorName} {solicitorBio} this since {startDate}
            </Typography>
          </div>
        ) : (
          <div className="flex justify-center items-center mx-[24px] mt-[8px]">
            <Typography
              type="h2"
              variant="h4"
              weight="regular"
              className="text-brand-heavy-teal-100"
            >
              {solicitorName} {solicitorBio}
            </Typography>
          </div>
        )}

        <div className="flex flex-row gap-[16px] items-start mx-[24px] mt-[32px]">
          <div>
            <div className="mb-[6px]">
              <Icons iconName="GreenTick" size="24" />
            </div>

            <Typography
              type="p"
              variant="base"
              weight="regular"
              className="font-normal text-brand-heavy-teal-75"
            >
              {line_1}
            </Typography>
          </div>

          <div>
            <div className="mb-[6px]">
              <Icons iconName="GreenTick" size="24" />
            </div>

            <Typography
              type="p"
              variant="base"
              weight="regular"
              className="font-normal text-brand-heavy-teal-75"
            >
              {line_2}
            </Typography>
          </div>

          <div>
            <div className="mb-[6px]">
              <Icons iconName="GreenTick" size="24" />
            </div>

            <Typography
              type="p"
              variant="base"
              weight="regular"
              className="font-normal text-brand-heavy-teal-75"
            >
              {line_3}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolicitorCard;
