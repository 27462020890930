import { Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { v4 as uuidv4 } from "uuid";

import {
  PersonalDetailsWithAddressSchema,
  PersonalDetailsWithAddressState,
} from "@schema";

import { Modal } from "../../../Modal/Modal";
import { initialPersonalDetailsStateObj } from "../../../../store/initialDetails";
import { BasicDetailsForm } from "../BasicDetailsForm/BasicDetailsForm";
import { AddressInputs } from "../AddressLookup/AddressInputs";

interface AddSellerModalProps {
  onCancel: () => void;
  onSaved: (newSeller: PersonalDetailsWithAddressState) => void;
}

export const AddSellerModal = ({ onCancel, onSaved }: AddSellerModalProps) => (
  <Formik
    initialValues={{
      ...initialPersonalDetailsStateObj,
      personId: uuidv4(),
      primaryCustomer: false,
      customerType: undefined,
    }}
    onSubmit={(newSeller) => onSaved(newSeller)}
    validate={withZodSchema(PersonalDetailsWithAddressSchema)}
  >
    {(formik) => (
      <Modal
        title="Add seller"
        cancelButtonFunction={onCancel}
        saveButtonFunction={() => formik.handleSubmit()}
      >
        <BasicDetailsForm isAdditionalSeller={true} />
        <AddressInputs
          namespace="correspondenceAddress"
          addressTitle="Correspondence address"
          cardAddressTitle="Correspondence address"
          allowInternationalAddress={true}
        />
      </Modal>
    )}
  </Formik>
);
