import { useField } from "formik";

import { RadioButtonQuestion } from "../RadioButtonQuestion/RadioButtonQuestion";

interface ForeignCurrencyProps {
  description: string;
}

export const ForeignCurrency = ({ description }: ForeignCurrencyProps) => {
  const [
    fieldIsUsingForeignCurrency,
    metaIsUsingForeignCurrency,
    helpersIsUsingForeignCurrency,
  ] = useField("isUsingForeignCurrency");

  return (
    <RadioButtonQuestion
      title="Foreign currency"
      description={description}
      label="isUsingForeignCurrency"
      options={["Yes", "No"]}
      selectedRadioButtonQuestionOption={
        fieldIsUsingForeignCurrency.value === undefined
          ? undefined
          : fieldIsUsingForeignCurrency.value
            ? "Yes"
            : "No"
      }
      setSelectedRadioButtonQuestionOption={(newValue) => {
        helpersIsUsingForeignCurrency.setValue(newValue === "Yes");
      }}
      radioButtonQuestionError={
        metaIsUsingForeignCurrency.touched
          ? metaIsUsingForeignCurrency.error
          : ""
      }
    />
  );
};
