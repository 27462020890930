// This function transforms the Transaction from the database into
// the Property state value used in the Transaction local storage object

import { SellerPropertyDetailsState } from "@schema";
import { Tenure, TransactionResponse } from "@shared/types";

import { isOtherTenure } from "../utils/utils";
import { transformSeller } from "./transformSeller";

export const transformTransactionPropertyState = (
  transaction: TransactionResponse,
): SellerPropertyDetailsState => {
  const primarySeller = transaction.sellers.find(
    (seller) => seller.primary_customer,
  );

  const tenure = transaction.property_id.tenure;
  const tenureOption = isOtherTenure(tenure) ? Tenure.Other : tenure;
  const tenureDetails = isOtherTenure(tenure) ? tenure : "";

  const additionalSellers = transaction.sellers
    .filter((seller) => !seller.primary_customer)
    .map((seller) => transformSeller(seller));

  return {
    propertyAddress: {
      line_1: transaction.property_id.address.line_1,
      line_2: transaction.property_id.address.line_2,
      line_3: transaction.property_id.address.line_3,
      post_town: transaction.property_id.address.post_town,
      country: transaction.property_id.address.country,
      postcode: transaction.property_id.address.postcode,
      county: transaction.property_id.address.county,
      uprn: transaction.property_id.address.uprn,
      district: transaction.property_id.address.district,
      building_name: transaction.property_id.address.building_name,
      building_number: transaction.property_id.address.building_number,
      sub_building_name: transaction.property_id.address.sub_building_name,
      premise: transaction.property_id.address.premise,
      organisation_name: transaction.property_id.address.organisation_name,
      po_box: transaction.property_id.address.po_box,
      country_iso_2: transaction.property_id.address.country_iso_2,
    },
    tenureOption: tenureOption,
    tenureDetails: tenureDetails,
    askingPrice: transaction.asking_price.toString(),
    hasMortgage: transaction.has_mortgage,
    onwardPurchase: transaction.onward_purchase,
    isUsingForeignCurrency: transaction.is_using_foreign_currency,
    customerType: primarySeller?.customer_type,
    additionalSellers,
    _id: transaction.property_id._id,
    isAnotherSellerSelected: additionalSellers.length > 0,
  };
};
