import { useMemo, useState, useCallback } from "react";
import { Button, Card, Table, ErrorText } from "@pongo";

import { CreateThirdfortRequestBody } from "../../service/AdminApiController/createThirdfortCheck";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { StatusCell } from "./StatusCell";
import { THIRDFORT_URL } from "../../../constants";
import { toTitleCase } from "@shared/utils";
import { useThirdfortContext } from "../../context/ThirdfortProvider";
import { useTransactionContext } from "../../context/TransactionProvider";
import {
  formatPersonPhoneNumber,
  getActionButtonLabel,
  ThirdfortStatus,
  ThirdfortAction,
} from "./utils";

interface ThirdfortTableProps {
  persons: CreateThirdfortRequestBody[];
  cardTitle: string;
  actionButtonsDisabled?: boolean;
}

interface ThirdfortRowData {
  name: string;
  status: ThirdfortStatus;
  action?: ThirdfortAction;
  _id?: string;
  thirdfortPersonDetails: CreateThirdfortRequestBody;
  thirdfortId?: string;
}

// Helper function to compare person details (ignores case)
const personMatches = (
  person: CreateThirdfortRequestBody,
  check: {
    person_details: { email: string; first_name: string; last_name: string };
    person_type: string;
  },
): boolean =>
  person.email.toLowerCase() === check.person_details.email.toLowerCase() &&
  person.first_name.toLowerCase() ===
    check.person_details.first_name.toLowerCase() &&
  person.last_name.toLowerCase() ===
    check.person_details.last_name.toLowerCase() &&
  person.person_type === check.person_type;

export const ThirdfortTable = ({
  persons,
  cardTitle,
  actionButtonsDisabled,
}: ThirdfortTableProps) => {
  const { transaction } = useTransactionContext();
  const {
    thirdfortChecks,
    abortCheck,
    orderCheck,
    reorderCheck,
    error: thirdfortError,
    loading,
  } = useThirdfortContext();

  const [error, setError] = useState<string>("");

  // Map persons to table rows
  const rows = useMemo(() => {
    if (!transaction || !thirdfortChecks) return [];

    return persons.map((person) => {
      const check = thirdfortChecks.find((check) =>
        personMatches(person, check),
      );
      return {
        data: {
          name: `${person.first_name} ${person.last_name}`,
          status: check ? check.status : ("" as ThirdfortStatus),
          action: getActionButtonLabel(check?.status),
          thirdfortId: check?.thirdfort_id,
          _id: check?._id,
          thirdfortPersonDetails: {
            ...person,
            phone_number: formatPersonPhoneNumber(person.phone_number),
          },
        },
      };
    });
  }, [persons, thirdfortChecks, transaction]);

  // Handle action button clicks
  const onActionButtonClick = useCallback(
    async (item: ThirdfortRowData) => {
      setError("");

      if (item.action === "abort") {
        if (!item._id) {
          setError("Error getting check id, cannot abort check");
          return;
        }
        await abortCheck?.(item._id);
      } else if (item.action === "order") {
        const { error } =
          (await orderCheck?.(item.thirdfortPersonDetails)) || {};
        if (error) setError(error);
      } else if (item.action === "reorder") {
        if (!item._id) {
          setError("Error getting check id, cannot reorder check");
          return;
        }
        const { error } = (await reorderCheck?.(item._id)) || {};
        if (error) setError(error);
      }
    },
    [abortCheck, orderCheck, reorderCheck],
  );

  // Define table columns with memoization
  const columns = useMemo(
    () => [
      { key: "name", title: "Name" },
      {
        key: "status",
        title: "Status",
        rowCustomRender: (item: ThirdfortRowData) => (
          <StatusCell status={item.status} />
        ),
      },
      {
        key: "action",
        title: "Action",
        rowCustomRender: (item: ThirdfortRowData) => (
          <Button
            variant="primary"
            onClick={() => onActionButtonClick(item)}
            className="px-0 w-auto text-sm"
            disabled={actionButtonsDisabled || item.status === "complete"}
          >
            {toTitleCase(item.action ?? "")}
          </Button>
        ),
      },
      {
        key: "thirdfortId",
        title: "Thirdfort ID",
        rowCustomRender: (item: ThirdfortRowData) => (
          <a
            href={`${THIRDFORT_URL}${item.thirdfortId}`}
            className="underline text-brand-heavy-teal-100"
          >
            {item.thirdfortId}
          </a>
        ),
      },
    ],
    [actionButtonsDisabled, onActionButtonClick],
  );

  return (
    <>
      {loading && <FullPageLoader />}

      <Card
        title={cardTitle}
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
      >
        <>
          <Table columns={columns} rows={rows} />
          {(error || thirdfortError) && (
            <ErrorText>{error || thirdfortError}</ErrorText>
          )}
        </>
      </Card>
    </>
  );
};
