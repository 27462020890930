import React from "react";
import { Typography, FeatureCard, SolicitorCard } from "@pongo";

import { Role } from "@shared/types";

import SolicitorAvatar from "@assets/solicitorAvatar1.png";
import LegalAssistantAvatar from "@assets/legalAssistantAvatar1.png";
import LLGLogo from "@assets/LLGLogo.svg";
import IdCheckImage from "@assets/idCheck.svg";
import SignImage from "@assets/sign.svg";

interface DashboardHomePurchaseProps {
  role: Role;
}

export const DashboardHomePurchase: React.FC<DashboardHomePurchaseProps> = ({
  role,
}) => (
  <div>
    <Typography
      type="h1"
      variant="h1"
      weight="bold"
      className="mb-[32px] text-brand-heavy-teal-100/80 font-display"
    >
      Your Dashboard
    </Typography>

    <Typography
      type="p"
      variant="base"
      weight="regular"
      className="mb-[32px] text-brand-heavy-teal-100"
    >
      Welcome to your dashboard. Here you&apos;ll find everything you need to
      know to manage your {role === "sell" ? "sale" : "purchase"} on Propelr.
    </Typography>

    <div className="flex flex-col items-start">
      <div className="flex flex-col md:flex-row md:gap-[48px]">
        <div className="flex flex-col">
          <Typography
            type="h2"
            variant="h4"
            weight="bold"
            className="mb-[16px] text-brand-heavy-teal-100"
          >
            Next steps for us:
          </Typography>

          {solicitorCards.map((card) => {
            return <SolicitorCard card={card} key={card.id} />;
          })}
        </div>

        <div className="border-l border-brand-heavy-teal-25 flex-shrink-0" />

        <div className="flex flex-col">
          <div className="flex items-center mb-[8px]">
            <Typography
              type="h2"
              variant="h4"
              weight="bold"
              className="mb-[8px] text-brand-heavy-teal-100"
            >
              Next steps for you:
            </Typography>
          </div>

          <div className="flex flex-col">
            {successCards.map((card) => (
              <FeatureCard
                key={card.id}
                description={card.description}
                iconName="ID"
                title={card.name}
                customClass="max-w-[380px]"
                cardColour="bg-brand-warmth-100"
                orientation="portrait"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const solicitorCards = [
  {
    id: 1,
    name: "Introduce you to your dedicated legal team",
    description: "Nabil of Leadenhall Law Group.",
    solicitorName: "They",
    legalAssistantName: "Lisa Richardson",
    solicitorBio: "have already been working on",
    startDate: "this transaction.",
    line_1: "Reviewing the searches, and title",
    line_2: "Raising enquiries with the sellers solicitor",
    line_3: "Preparing the report on the property for you",
    price: "£1,300",
    avatar_1: SolicitorAvatar,
    avatar_2: LegalAssistantAvatar,
    logo: LLGLogo,
  },
];

export const successCards = [
  {
    id: 1,
    name: "Complete ID check",
    description:
      "You'll shortly receive a text message from Thirdfort. Please follow the instructions to complete your ID checks.",
    image: IdCheckImage,
  },
  {
    id: 2,
    name: "Sign engagement letter",
    description:
      "Your solicitor will be in touch shortly to introduce themselves and onboard you.",
    image: SignImage,
  },
];
