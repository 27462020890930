import React from "react";
import { Typography, FeatureCard, LinkWrapper } from "@pongo";

import { EMAIL_ADDRESS } from "../../constants";
import { Role } from "@shared/types";

interface DashboardHomeSaleProps {
  role: Role;
}

export const DashboardHomeSale: React.FC<DashboardHomeSaleProps> = ({
  role,
}) => (
  <div>
    <Typography
      type="h1"
      variant="h1"
      weight="bold"
      className="mb-[32px] text-brand-heavy-teal-100/80 font-display"
    >
      Your Dashboard
    </Typography>

    <Typography
      type="p"
      variant="base"
      weight="regular"
      className="mb-[32px] text-brand-heavy-teal-100"
    >
      Welcome to your dashboard. Here you&apos;ll find everything you need to
      know to manage your {role === "sell" ? "sale" : "purchase"} on Propelr.
    </Typography>

    <Typography
      type="h2"
      variant="h4"
      weight="bold"
      className="mb-[12px] ml-[6px] text-brand-heavy-teal-100"
    >
      Your next steps:
    </Typography>

    <div className="flex flex-col mb-[24px]">
      <FeatureCard
        customClass="max-w-[380px]"
        description="You'll shortly receive a text message from Thirdfort. Please follow the instructions to complete your ID checks."
        iconName="ID"
        title="Complete ID check"
        cardColour="bg-brand-warmth-100"
        orientation="portrait"
      />
      <FeatureCard
        customClass="max-w-[380px]"
        description="Your solicitor will be in touch shortly to introduce themselves and onboard you."
        iconName="Sign"
        title="Sign engagement letter"
        cardColour="bg-brand-warmth-100"
        orientation="portrait"
      />
      <FeatureCard
        customClass="max-w-[380px]"
        description="Your solicitor will give you 'TA' forms to fill in. It's very important that you complete these in as soon as possible."
        iconName="FormWebsite"
        title="Fill in TA forms"
        cardColour="bg-brand-warmth-100"
        orientation="portrait"
      />
    </div>

    <Typography
      type="h2"
      variant="h4"
      weight="bold"
      className="mb-[12px] text-brand-heavy-teal-100"
    >
      Any questions?
    </Typography>

    <Typography
      type="p"
      variant="base"
      weight="semi-bold"
      className="mb-[24px] text-brand-heavy-teal-100"
    >
      If you have any questions regarding the next steps please get in touch
      with us by emailing{" "}
      <LinkWrapper>
        <a href={`mailto:${EMAIL_ADDRESS.SUPPORT}`}>{EMAIL_ADDRESS.SUPPORT}</a>
      </LinkWrapper>
      .
    </Typography>
  </div>
);
