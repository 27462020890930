import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import { z } from "zod";

import Typography from "@/foundation/Typography/Typography";
import ErrorText from "@/foundation/Typography/ErrorText";
import { components } from "@shared/types.d";
import { Modal } from "../../../components/Modal/Modal";
import { CheckPropertyDetails } from "./CheckPropertyDetails";
import { CustomiseEmail } from "./CustomiseEmail";
import { ConfirmCard } from "./ConfirmCard";
import { AssignSolicitors } from "./AssignSolicitors";
import { LawyerGroupIds } from "../../pages/TransactionDetails/types";
import { LawyerGroup } from "../../../types";
import { getSelectedLawyerGroup, isSolicitorNonPanel } from "./utils";
import { WarningCard } from "../WarningCard/WarningCard";
import { entityAlreadyAssignedWarning } from "../WarningCard/WarningTexts";

import {
  TransactionsGroupStateForAdminPage,
  useTransactionsGroupContext,
} from "../../context/TransactionsGroupProvider";
import { useTransactionContext } from "../../context/TransactionProvider";
import { ApiController } from "../../../service/ApiController";
import { isTransactionGroupAndEntityAssigned } from "../../pages/TransactionsGroupDetails/utils";

export type SendCheckedEmailsRequest =
  components["schemas"]["AssignLawyerGroupToTransactionDto"]["sendCheckedEmails"];

interface AssignSolicitorsBlockProps {
  isTransactionsGroup: boolean;
  onCloseModal: () => void;
  isEntityAssigned?: boolean;
}

const LawyerGroupFormSchema = z.object({
  buyer_lawyer_group: z.string().min(1, { message: "Solicitor is required" }),
  seller_lawyer_group: z.string().min(1, { message: "Solicitor is required" }),
  seller_solicitor_comments: z.string().optional(),
  buyer_solicitor_comments: z.string().optional(),
  introductionEmailToSellerChecked: z.boolean(),
  plotPlanRequestToSellerChecked: z.boolean(),
  furtherDetailsEmailToSellerSolicitorChecked: z.boolean(),
  introductionEmailToBuyerChecked: z.boolean(),
  furtherDetailsEmailToBuyerSolicitorChecked: z.boolean(),
  additionalInfoEmailToBuyerSolicitorChecked: z.boolean(),
});

export type LawyerGroupFormState = z.infer<typeof LawyerGroupFormSchema>;

export const AssignSolicitorsBlock = ({
  isTransactionsGroup,
  onCloseModal,
  isEntityAssigned,
}: AssignSolicitorsBlockProps) => {
  const { transaction, setTransaction } = useTransactionContext();
  const { setTransactionsGroup } = useTransactionsGroupContext();

  const { id: groupId } = useParams<{ id: string }>();

  const [displayConfirmCard, setDisplayConfirmCard] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [lawyerGroups, setLawyerGroups] = useState<LawyerGroup[]>([]);
  const [errorFetching, setErrorFetching] = useState("");
  const [error, setError] = useState("");
  const [sellerLawyerGroupId, setSellerLawyerGroupId] = useState("");
  const [buyerLawyerGroupId, setBuyerLawyerGroupId] = useState("");

  useEffect(() => {
    async function fetchLawyerGroups() {
      try {
        const existingLawyerGroups = await ApiController.findAllLawyerGroups();

        setLawyerGroups(existingLawyerGroups);
      } catch (error) {
        if (error instanceof Error) {
          setErrorFetching(error.message);
        }
      }
    }

    fetchLawyerGroups();
  }, []);

  useEffect(() => {
    setSellerLawyerGroupId(transaction?.lawyer_group?.seller._id || "");
    setBuyerLawyerGroupId(transaction?.lawyer_group?.buyer._id || "");
  }, [transaction]);

  const handleConfirmAssignSolicitors = async (
    values: LawyerGroupFormState,
  ) => {
    setIsInputDisabled(true);

    const {
      seller_lawyer_group,
      buyer_lawyer_group,
      buyer_solicitor_comments: buyerSolicitorComments,
      seller_solicitor_comments: sellerSolicitorComments,
      ...emailsChecked
    } = values;

    const sellerLawyerGroup = getSelectedLawyerGroup(
      lawyerGroups,
      seller_lawyer_group,
    );
    const buyerLawyerGroup = getSelectedLawyerGroup(
      lawyerGroups,
      buyer_lawyer_group,
    );

    if (sellerLawyerGroup && buyerLawyerGroup) {
      const lawyerGroupsIds: LawyerGroupIds = {
        seller: sellerLawyerGroup._id,
        buyer: buyerLawyerGroup._id,
      };

      if (isTransactionsGroup) {
        assignLawyerGroupsToTransactionsGroup(
          lawyerGroupsIds,
          emailsChecked,
          sellerSolicitorComments,
          buyerSolicitorComments,
        );
      } else {
        assignLawyerGroupsToSingleTransaction(
          lawyerGroupsIds,
          { ...emailsChecked, plotPlanRequestToSellerChecked: false },
          sellerSolicitorComments,
          buyerSolicitorComments,
        );
      }
    }

    setIsInputDisabled(false);
  };

  const assignLawyerGroupsToSingleTransaction = async (
    lawyerGroupsIds: LawyerGroupIds,
    sendCheckedEmails: SendCheckedEmailsRequest,
    sellerSolicitorComments?: string,
    buyerSolicitorComments?: string,
  ) => {
    try {
      if (!transaction || !setTransaction) {
        throw new Error(`Transaction not found with id ${transaction?._id}`);
      }

      // Add lawyer groups to transaction in DB
      const updatedTransaction =
        await ApiController.assignLawyerGroupTransaction(transaction?._id, {
          lawyerGroupsIds,
          sendCheckedEmails,
          seller_comments: sellerSolicitorComments,
          buyer_comments: buyerSolicitorComments,
        });

      setTransaction(updatedTransaction);
      onCloseModal();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an error assigning solicitors");
      }
      setDisplayConfirmCard(false);
    }
  };

  const assignLawyerGroupsToTransactionsGroup = async (
    lawyerGroupsIds: LawyerGroupIds,
    sendCheckedEmails: SendCheckedEmailsRequest,
    sellerSolicitorComments?: string,
    buyerSolicitorComments?: string,
  ) => {
    try {
      // Add lawyer groups to group of transactions in DB
      if (!groupId) {
        throw new Error("Transaction group not found");
      }

      await ApiController.assignLawyerGroupTransactionsGroup(groupId, {
        lawyerGroupsIds,
        sendCheckedEmails,
        seller_comments: sellerSolicitorComments,
        buyer_comments: buyerSolicitorComments,
      });

      setTransactionsGroup((prev: TransactionsGroupStateForAdminPage) => ({
        ...prev,
        lawyerGroups: {
          seller: sellerLawyerGroupId,
          buyer: buyerLawyerGroupId,
        },
      }));

      onCloseModal();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an error assigning solicitors");
      }
      setDisplayConfirmCard(false);
    }
  };

  return (
    <Formik
      initialValues={{
        buyer_lawyer_group: buyerLawyerGroupId || "",
        seller_lawyer_group: sellerLawyerGroupId || "",
        seller_solicitor_comments: "",
        buyer_solicitor_comments: "",
        introductionEmailToSellerChecked: true,
        plotPlanRequestToSellerChecked: true,
        furtherDetailsEmailToSellerSolicitorChecked: true,
        introductionEmailToBuyerChecked: true,
        furtherDetailsEmailToBuyerSolicitorChecked: true,
        additionalInfoEmailToBuyerSolicitorChecked: true,
      }}
      validate={withZodSchema(LawyerGroupFormSchema)}
      onSubmit={handleConfirmAssignSolicitors}
    >
      {({ handleSubmit, validateForm, setFieldError, values }) => {
        return (
          <Form>
            <Modal
              title="Assign Solicitors"
              cancelButtonFunction={() => onCloseModal()}
              saveButtonFunction={async () => {
                const errors = await validateForm(values);
                if (Object.keys(errors).length > 0) {
                  if (errors.seller_lawyer_group) {
                    setFieldError(
                      "seller_lawyer_group",
                      errors.seller_lawyer_group,
                    );
                  }
                  if (errors.buyer_lawyer_group) {
                    setFieldError(
                      "buyer_lawyer_group",
                      errors.buyer_lawyer_group,
                    );
                  }
                  return;
                }
                setDisplayConfirmCard(true);
              }}
              hideSaveButton={
                displayConfirmCard ||
                isTransactionGroupAndEntityAssigned(
                  isTransactionsGroup,
                  isEntityAssigned,
                )
              }
            >
              <>
                {isTransactionGroupAndEntityAssigned(
                  isTransactionsGroup,
                  isEntityAssigned,
                ) ? (
                  <WarningCard
                    text={entityAlreadyAssignedWarning("Solicitor")}
                  />
                ) : (
                  <>
                    {transaction || isTransactionsGroup ? (
                      <>
                        <Typography
                          type="h3"
                          variant="h3"
                          weight="bold"
                          className="text-brand-heavy-teal-100 mb-[16px] mt-[40px]"
                        >
                          Assign solicitors
                        </Typography>

                        {transaction && !isTransactionsGroup ? (
                          <CheckPropertyDetails transaction={transaction} />
                        ) : null}

                        {errorFetching ? (
                          <ErrorText>{errorFetching}</ErrorText>
                        ) : (
                          <>
                            <AssignSolicitors
                              lawyerGroups={lawyerGroups}
                              isInputDisabled={isInputDisabled}
                              displayConfirmCard={displayConfirmCard}
                              isTransactionsGroup={isTransactionsGroup}
                              isSelectedSellerSolicitorNonPanel={isSolicitorNonPanel(
                                lawyerGroups,
                                values.seller_lawyer_group,
                              )}
                            />
                            <CustomiseEmail isDisabled={isInputDisabled} />
                            <ErrorText>{error}</ErrorText>
                          </>
                        )}

                        {displayConfirmCard && (
                          <ConfirmCard
                            setDisplayConfirmCard={setDisplayConfirmCard}
                            handleConfirmAssign={handleSubmit}
                            extraInfo={
                              <WarningCard
                                text="This will assign solicitors and send the emails
                              checked"
                              />
                            }
                            type="solicitors"
                          />
                        )}
                      </>
                    ) : (
                      <ErrorText>Transaction not found</ErrorText>
                    )}
                  </>
                )}
              </>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
