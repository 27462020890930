import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { Field, FieldProps, useFormikContext } from "formik";
import { GetCountries } from "react-country-state-city";
import { Country } from "react-country-state-city/dist/esm/types";

import { ErrorText, Select } from "@pongo";

import { buildCountriesDropdownOptions, getCountryByName } from "./utils/index";

interface CountrySelectorProps {
  namespace: string;
  onFetchError: () => void;
}

export const CountrySelector = ({
  namespace,
  onFetchError,
}: CountrySelectorProps) => {
  const { setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    const fetchCountries = async (
      setCountries: (countries: Country[]) => void,
    ) => {
      try {
        const result = await GetCountries();
        setCountries(result);
      } catch {
        onFetchError();
      }
    };

    fetchCountries(setCountries);
  }, [onFetchError]);

  const countriesOptions = useMemo(
    () => buildCountriesDropdownOptions(countries),
    [countries],
  );

  const onCountryChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = getCountryByName(countries, e.target.value);

    if (selectedCountry) {
      setFieldValue(`${namespace}.country`, selectedCountry.name);
      setFieldValue(`${namespace}.country_iso_2`, selectedCountry.iso2);
    }

    // Validate postcode after setting the country:
    setFieldTouched(`${namespace}.postcode`, true, true);
  };

  const fieldName = [namespace, "country"].join(".");
  const error = errors[fieldName as keyof typeof errors];
  const isTouched = touched[fieldName as keyof typeof touched];

  return (
    <div className="flex flex-col w-auto">
      <Field name={fieldName}>
        {({ field }: FieldProps) => (
          <Select
            {...field}
            data-testid={fieldName}
            name={fieldName}
            id={fieldName}
            options={countriesOptions}
            variant={isTouched && error ? "error" : "enabled"}
            onChange={onCountryChange}
            value={field.value}
          />
        )}
      </Field>
      {isTouched && error && <ErrorText>{error}</ErrorText>}
    </div>
  );
};
