import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from "../../../service/ApiController";

export type SearchResultsResponse =
  paths["/api/search"]["get"]["responses"]["200"]["content"]["application/json"];

export const search = async (query: string): Promise<SearchResultsResponse> => {
  try {
    const response = await api.get(`/search?q=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("There was an error submitting a search");
  }
};
