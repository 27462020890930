import * as Sentry from "@sentry/react";

import { paths } from "@shared/types.d";
import { api } from ".";

export type RetrieveAddressResponse =
  paths["/api/address/details"]["get"]["responses"]["200"]["content"]["application/json"];

export const retrieveAddress = async (
  id: string,
): Promise<RetrieveAddressResponse> => {
  try {
    const response = await api.get(`/address/details/?id=${id}`);
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error("Failed to retrieve address details");
  }
};
