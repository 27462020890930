import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Icons } from "@pongo";

import { TransactionInfo } from "../../components/TransactionDetailsTabContent";
import Typography from "@/foundation/Typography/Typography";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { Tabs } from "../../components/TransactionDetailsTabContent/types";
import { RenderTabContent } from "../../components/TransactionDetailsTabContent/RenderTabContent";
import { Lozenge } from "@/components/Lozenge/Lozenge";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import {
  TransactionProvider,
  useTransactionContext,
} from "../../context/TransactionProvider";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";

import { getAddressAsString } from "@shared/utils";
import { getOrderType, getPaymentStatus } from "@utils";

interface Tab {
  value: Tabs;
  label: string;
  count?: number;
  alert?: boolean;
}

const TransactionDetailsTabs = ({
  tabs,
  currentTab,
  setCurrentTab,
}: {
  tabs: Tab[];
  currentTab: Tabs;
  setCurrentTab: (tab: Tabs) => void;
}) => (
  <div className="w-full flex my-[20px] border-b overflow-x-scroll">
    {tabs.map((tab) => (
      <button
        key={tab.value}
        onClick={() => setCurrentTab(tab.value)}
        className={`mr-4 p-[16px] rounded-t-lg flex items-center
                  ${
                    tab.value === currentTab
                      ? "bg-brand-heavy-teal-100 text-brand-heavy-warmth-100"
                      : ""
                  }`}
      >
        <Typography type="p" variant="lg" weight="bold">
          {tab.label}
        </Typography>

        <div className="ml-[8px]">
          {tab.count !== undefined && (
            <div
              className={`flex justify-center items-center rounded-full h-[20px] w-[20px]  ${
                tab.value === currentTab
                  ? "bg-brand-heavy-warmth-100 text-brand-heavy-teal-100"
                  : "bg-brand-heavy-teal-100 text-brand-heavy-warmth-100"
              }`}
            >
              <Typography type="p" variant="xs" weight="bold" className="m-0">
                {tab.count}
              </Typography>
            </div>
          )}
        </div>

        {tab.alert && (
          <div className="ml-[8px]">
            <Icons iconName="Alert" colour="error" size="18" />
          </div>
        )}
      </button>
    ))}
  </div>
);

const TransactionDetailsContent = () => {
  const { transaction, loading, error } = useTransactionContext();

  const [transactionPaid, setTransactionPaid] = useState(false);
  const [transactionsGroup, setTransactionsGroup] = useState<boolean>(false);

  const [transactionId, setTransactionId] = useState<string>("");
  const [transactionAddress, setTransactionAddress] = useState<string>("");

  const [currentTab, setCurrentTab] = useState<Tabs>("property");
  const [tabs, setTabs] = useState<Tab[]>([]);

  useEffect(() => {
    if (transaction) {
      setTabs([
        { value: "property", label: "Property" },
        {
          value: "sellers",
          label: "Sellers",
          count: transaction?.sellers.length || 0,
        },
        {
          value: "purchaseIntents",
          label: "Purchase Intents",
          count: transaction?.purchase_intent.length || 0,
        },
        { value: "orders", label: "Orders" },
        { value: "thirdfort", label: "Thirdfort" },
        {
          value: "solicitors",
          label: "Solicitors",
          alert: !transaction.lawyer_group,
        },
        {
          value: "estateAgent",
          label: "Estate Agent",
          alert: !transaction.estate_agent,
        },
        {
          value: "searches",
          label: "Searches",
          alert: !transaction.searches,
        },
        { value: "events", label: "Events" },
      ]);

      setTransactionId(transaction._id);
      setTransactionAddress(
        getAddressAsString(transaction.property_id.address, true),
      );
    }
  }, [transaction]);

  useEffect(() => {
    if (!transaction) return;

    setTransactionPaid(getPaymentStatus(transaction?.orders[0]));
    setTransactionsGroup(transaction.group !== undefined);
  }, [transaction]);

  return (
    <>
      <Breadcrumbs
        items={[
          { name: "Transactions", url: "/admin/transactions" },
          { name: transactionId },
        ]}
      />

      <AdminTitle title={transactionAddress} setAsTitleCase={false}>
        {transaction && !loading && !error && (
          <div className="flex items-center justify-end mb-[16px] space-x-3 shrink-0">
            <Lozenge>
              {`Created On:`}
              <span className="pl-[4px] font-medium">
                {format(new Date(transaction.createdAt), "dd/MM/yyyy")}
              </span>
            </Lozenge>

            {transactionPaid !== undefined && (
              <Lozenge
                fontColor="text-ui-white-100"
                backgroundColor={
                  transactionPaid ? "bg-ui-success-100" : "bg-ui-error-100"
                }
              >
                {/* FIXME: Refactor when Orders becomes its own collection */}
                {transactionPaid
                  ? `Paid - ${getOrderType(transaction.orders[transaction.orders.length - 1])}`
                  : `Unpaid - ${getOrderType(transaction.orders[transaction.orders.length - 1])}`}
              </Lozenge>
            )}

            {transactionsGroup && (
              <Lozenge
                fontColor="text-ui-black-100"
                backgroundColor="bg-brand-electric-100"
              >
                Group
              </Lozenge>
            )}
          </div>
        )}
      </AdminTitle>

      {loading && <FullPageLoader />}

      {error && (
        <Typography variant="lg" weight="bold" type="p">
          {error}
        </Typography>
      )}

      {!error && (
        <>
          <TransactionInfo />

          <TransactionDetailsTabs
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />

          <div className="flex flex-col space-y-5">
            <RenderTabContent currentTab={currentTab} />
          </div>
        </>
      )}
    </>
  );
};

export const TransactionDetails = () => {
  return (
    <TransactionProvider>
      <TransactionDetailsContent />
    </TransactionProvider>
  );
};
