/**
 * @file useFetchTransactionOrPurchaseIntent.ts
 * @module useFetchTransactionOrPurchaseIntent
 *
 * @description
 * A custom hook that fetches both the most recent transaction and purchase intent for a user.
 * - It fetches transactions via `findTransactionsByUserId`.
 * - And purchase intents via `findTransactionsByPurchaseIntentUserId`.
 *
 * @param userData - The user data object containing at least the user ID.
 * @param role - The role of the user ("sell" or "buy") (included as a dependency in case it changes).
 * @returns An object containing the most recent TransactionResponse for both transaction and purchaseIntent, or undefined if not available.
 *
 * @example
 * const { transaction, purchaseIntent } = useFetchLatestTransactionAndPurchaseIntent(userData, role);
 */

import { useEffect, useState } from "react";

import { ApiController } from "../service/ApiController";
import { sortMostRecent } from "../utils/sortMostRecent";
import { TransactionResponse } from "@shared/types";
import { UserType } from "../store/auth/auth";

export const useFetchLatestTransactionAndPurchaseIntent = (
  userData: UserType | null,
  role: string,
): {
  transaction?: TransactionResponse;
  purchaseIntent?: TransactionResponse;
} => {
  const [transaction, setTransaction] = useState<TransactionResponse>();
  const [purchaseIntent, setPurchaseIntent] = useState<TransactionResponse>();

  useEffect(() => {
    if (!userData) return;

    const fetchData = async () => {
      try {
        const [transactionResponse, purchaseIntentResponse] = await Promise.all(
          [
            ApiController.findTransactionsByUserId(userData._id),
            ApiController.findTransactionsByPurchaseIntentUserId(userData._id),
          ],
        );

        if (transactionResponse && transactionResponse.length > 0) {
          setTransaction(sortMostRecent(transactionResponse)[0]);
        }
        if (purchaseIntentResponse && purchaseIntentResponse.length > 0) {
          setPurchaseIntent(sortMostRecent(purchaseIntentResponse)[0]);
        }
      } catch (error) {
        console.error("Error fetching transaction and purchase intent:", error);
      }
    };

    fetchData();
  }, [userData, role]);

  return { transaction, purchaseIntent };
};
