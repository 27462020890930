import React, { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Icons } from "@pongo";

import { PropertyCard } from "../../components/PropertyCard/PropertyCard";
import { TransactionResponse, Role } from "@shared/types";
import { useLoginStore } from "../../store/Login/login";
import { usePurchaseIntentFormStore } from "../../store/PurchaseIntentForm/purchaseIntentForm";
import { useTransactionFormStore } from "../../store/TransactionForm/transactionForm";
import { TransactionListTooltip } from "./TransactionListTooltip";

interface TransactionsListProps {
  latestTransactions: TransactionResponse[] | [];
  allTransactions: TransactionResponse[];
  type: Role;
  handleResumeTransaction: (id: string) => void;
}

export const TransactionsList: React.FC<TransactionsListProps> = ({
  latestTransactions,
  allTransactions,
  type,
  handleResumeTransaction,
}) => {
  const navigate = useNavigate();
  const { setRole } = useLoginStore();
  const { resetTransaction } = useTransactionFormStore();
  const { resetPurchaseIntent } = usePurchaseIntentFormStore();
  const [showAllTransactions, setShowAllTransactions] = useState(false);

  // Define labels for consistent use throughout the component.
  const transactionLabel = type === "sell" ? "sale" : "purchase";
  const transactionsLabel = type === "sell" ? "sales" : "purchases";

  const handleAddTransaction = () => {
    if (type === "sell") {
      resetTransaction();
    } else {
      resetPurchaseIntent();
    }
    setRole(type);
    navigate(`/${type}/welcome`);
  };

  // Memoize the render function for a single property card.
  const renderPropertyCard = useCallback(
    (transaction: TransactionResponse) => (
      <div key={transaction._id} className="shrink-0 w-[800px]">
        <PropertyCard
          transaction={transaction}
          type={type}
          handleResumeTransaction={handleResumeTransaction}
        />
      </div>
    ),
    [type, handleResumeTransaction],
  );

  // Render the latest transactions or show an empty state message if none exist.
  const renderLatestTransactions = useMemo(() => {
    if (latestTransactions.length === 0) {
      return (
        <div className="flex items-center shrink-0 w-[800px] h-[260px] relative rounded-2xl border-dashed border-[1px] border-ui-black-100/20 p-[24px] bg-ui-white-100/10">
          <Typography
            type="p"
            variant="base"
            weight="regular"
            className="text-brand-heavy-teal-100/40"
          >
            No {transactionsLabel} found. Add a new {transactionLabel} to get
            started.
          </Typography>
        </div>
      );
    }
    return latestTransactions.map(renderPropertyCard);
  }, [
    latestTransactions,
    transactionsLabel,
    renderPropertyCard,
    transactionLabel,
  ]);

  // Render all transactions (when the user toggles to view the archive).
  const renderAllTransactions = useMemo(() => {
    if (allTransactions.length === 0) {
      return (
        <div className="flex items-center shrink-0 w-[800px] h-[260px] relative rounded-2xl border-dashed border-[1px] border-ui-black-100/20 p-[24px] bg-ui-white-100/10">
          <Typography
            type="p"
            variant="base"
            weight="regular"
            className="text-brand-heavy-teal-100/40"
          >
            No previous {transactionsLabel} found
          </Typography>
        </div>
      );
    }

    return allTransactions.map(renderPropertyCard);
  }, [allTransactions, transactionsLabel, renderPropertyCard]);

  return (
    <div className="w-full rounded-2xl mb-[36px]">
      <div className="flex flex-row space-x-[24px] overflow-scroll">
        {renderLatestTransactions}

        <button
          onClick={handleAddTransaction}
          className="relative flex items-center justify-center shrink-0 w-[80px] rounded-2xl border-[1px] border-ui-black-10/100 bg-ui-white-100/60 opacity-50 hover:opacity-100"
        >
          <div className="group">
            <Icons iconName="AddTransaction" size="36" />
            <TransactionListTooltip label={`Add a new ${transactionLabel}`} />
          </div>
        </button>

        {!showAllTransactions ? (
          <button
            onClick={() => setShowAllTransactions(true)}
            className="relative flex items-center justify-center shrink-0 w-[150px] rounded-2xl border-[1px] border-ui-black-10/100 bg-ui-white-100/60 opacity-50 hover:opacity-100"
          >
            <div className="group">
              <Icons iconName="Archive" size="36" />
              <TransactionListTooltip
                label={`View previous ${transactionsLabel}`}
              />
            </div>
          </button>
        ) : (
          renderAllTransactions
        )}
      </div>
    </div>
  );
};
