import { useEffect, useState } from "react";

import { Table, TableProps } from "../Table/Table";
import { Card } from "@/components/Card/Card";
import { useTransactionContext } from "../../context/TransactionProvider";

export const PropertyTab = () => {
  const { transaction } = useTransactionContext();
  const [addressDetails, setAddressDetails] = useState<TableProps[]>([]);

  useEffect(() => {
    if (transaction) {
      const { has_mortgage: hasMortgage, onward_purchase: onwardPurchase } =
        transaction;

      const { address } = transaction.property_id;

      setAddressDetails([
        {
          label: "Address Line 1",
          value: address.line_1,
        },
        {
          label: "Address Line 2",
          value: address.line_2 || undefined,
        },
        {
          label: "Address Line 3",
          value: address.line_3 || undefined,
        },
        {
          label: "Post Town",
          value: address.post_town,
        },
        {
          label: "Country",
          value: address.country,
        },
        {
          label: "Postcode",
          value: address.postcode || undefined,
        },
        {
          label: "County",
          value: address.county || undefined,
        },
        {
          label: "URPN",
          value: address.uprn || undefined,
        },
        {
          label: "Building Number",
          value: address.building_number || undefined,
        },
        {
          label: "Sub Building Name",
          value: address.sub_building_name || undefined,
        },
        {
          label: "Building Name",
          value: address.building_name || undefined,
        },
        {
          label: "Orgnanisation Name",
          value: address.organisation_name || undefined,
        },
        {
          label: "PO Box",
          value: address.po_box || undefined,
        },
        {
          label: "Has Mortgage",
          value: hasMortgage ? "Yes" : "No",
        },
        {
          label: "Onward Purchase",
          value: onwardPurchase ? "Yes" : "No",
        },
      ]);
    }
  }, [transaction]);

  return (
    <>
      <Card
        title="Property Details"
        titleBackgroundColor="brand-heavy-warmth-25"
        titleColor="brand-heavy-teal-100"
      >
        <>
          {addressDetails.map(({ label, value }) => (
            <Table
              key={`property-details-${label}`}
              label={label}
              value={value}
            />
          ))}
        </>
      </Card>
    </>
  );
};
