import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ErrorText from "@/foundation/Typography/ErrorText";
import { AdminTitle } from "../../components/AdminPageTemplate/AdminTitle";
import { ApiController } from "../../../service/ApiController";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { Table, TableProps } from "../../components/Table/Table";
import { Card } from "@/components/Card/Card";
import { LawyerGroupTable } from "../../components/LawyerGroupTable/LawyerGroupTable";
import { LawFirmModal } from "../../components/Modals/LawFirmModal";
import { LawFirm } from "../../../types";
import { Breadcrumbs } from "../../components/Breadcrumbs";

export const LawFirmDetails = () => {
  const { id } = useParams();

  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [lawFirm, setLawFirm] = useState<LawFirm>();

  const [lawFirmDetails, setLawFirmDetails] = useState<TableProps[]>([]);

  const [modalOpen, setModalOpen] = useState(false);

  const fetchData = useCallback(async (id: string) => {
    try {
      setLoading(true);
      setError("");

      const response = await ApiController.findLawFirm(id);

      setLawFirm(response);

      setLawFirmDetails([
        { label: "Name", value: response.name },
        { label: "Email", value: response.email },
        { label: "Phone number", value: response.phone_number },
        {
          label: "Organisation name",
          value: response.address.organisation_name,
        },
        { label: "Address line 1", value: response.address.line_1 },
        { label: "Address line 2", value: response.address.line_2 },
        { label: "Address line 3", value: response.address.line_3 },
        { label: "Post town", value: response.address.post_town },
        { label: "Country", value: response.address.country },
        { label: "Postcode", value: response.address.postcode },
      ]);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("There was an error fetching the data");
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  return (
    <>
      <Breadcrumbs
        items={[
          { name: "Law Firms", url: "/admin/law-firms" },
          { name: id || "Unknown ID" },
        ]}
      />

      <AdminTitle title={lawFirm?.name} />

      {loading && <FullPageLoader />}

      {error && <ErrorText>{error}</ErrorText>}

      <Card
        title=" "
        titleRightContent={
          <button
            className="text-brand-heavy-warmth-25 underline"
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Edit
          </button>
        }
      >
        <>
          {lawFirmDetails.map(({ label, value }) => (
            <Table key={label} label={label} value={value} />
          ))}
        </>
      </Card>

      <LawyerGroupTable />

      {modalOpen && (
        <LawFirmModal
          onClose={() => setModalOpen(false)}
          onSaved={() => {
            fetchData(id!);
            setModalOpen(false);
          }}
          lawFirm={lawFirm}
        />
      )}
    </>
  );
};
