import { Address } from "../frontend/src/admin/pages/UserDetails/types";
import {
  AdditionalPersonValues,
  FormattedFields,
} from "../frontend/src/components/AdditionalPerson/AdditionalPerson.types";
import { Roles } from "./types";

export const formatCurrency = (
  amount: number,
  isPounds = false,
  excludeZero = false,
): string => {
  const a = isPounds ? amount : amount / 100;
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: excludeZero ? 0 : 2,
  }).format(a);
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const toTitleCase = (value: string): string => {
  return value.replace(/\w\S*/g, (txt: string): string => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
};

export const formatPostcode = (postcode: string, countryInfo: string) => {
  // Convert to uppercase and remove whitespace
  postcode = postcode.toUpperCase().replace(/\s/g, "");

  if (countryInfo !== "GB") return postcode;

  // If the postcode length is longer than 3, insert space
  if (postcode.length > 3) {
    postcode = `${postcode.substring(
      0,
      postcode.length - 3,
    )} ${postcode.substring(postcode.length - 3)}`;
  }

  return postcode;
};

export const formatCamelCaseString = (inputString: string) =>
  inputString
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .split(" ")
    .map(capitalizeFirstLetter)
    .join(" ");

const getQuestionAndAnswer = (values: Partial<AdditionalPersonValues>) => {
  return Object.entries(values).map(([key, value]) => {
    const question = formatCamelCaseString(key);
    const answer = String(value);
    return { question, answer };
  });
};

export const formatFields = (
  values: Partial<AdditionalPersonValues>,
): FormattedFields => {
  const fields = getQuestionAndAnswer(values);

  const initialAccumulator: FormattedFields = {
    legalName: "",
    amount: values.amount ? values.amount : "0",
    relationship: values.relationship || "Other",
    otherFields: [],
  };

  return fields.reduce((acc, field) => {
    const { question, answer } = field;
    const lowerCaseQuestion = question.toLowerCase();

    if (lowerCaseQuestion === "amount") {
      acc.amount = formatCurrency(parseFloat(answer.replace(/,/g, "") + "00"));
    } else if (
      ["email", "first name", "middle name", "last name"].includes(
        lowerCaseQuestion,
      )
    ) {
      if (lowerCaseQuestion === "email") {
        acc.otherFields.push({ question: "Email", answer });
      } else {
        acc.legalName += acc.legalName
          ? ` ${capitalizeFirstLetter(answer)}`
          : capitalizeFirstLetter(answer);
      }
    } else {
      acc.otherFields.push({
        question: capitalizeFirstLetter(question),
        answer: capitalizeFirstLetter(answer),
      });
    }
    return acc;
  }, initialAccumulator);
};

export const formatDate = (dateString: string | Date) => {
  if (!dateString) {
    dateString = new Date().toISOString();
  }
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-GB", {
    month: "long",
  });

  const day = date.getDate() + 1;
  const suffix = getDaySuffix(day);
  return `${day}${suffix} ${formattedDate}`;
};

export const formatLegalStartDate = (dateString: string | Date) => {
  if (!dateString) {
    dateString = new Date().toISOString();
  }
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-GB", {
    month: "long",
    timeZone: "UTC",
  });

  const day = date.getUTCDate();
  const suffix = getDaySuffix(day);
  return `${day}${suffix} ${formattedDate}`;
};

export const formatEventName = (eventName: string): string =>
  eventName
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

export const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const isSeller = (role: Roles) => role === Roles.Seller;
export const isBuyer = (role: Roles) => role === Roles.Buyer;

export const getAddressAsString = (
  address: Address,
  isHeadline = false,
): string => {
  // Helper to decode a field if it exists (handles null or undefined)
  const decode = (field: string | null | undefined): string =>
    field ? preprocessDecodedHTML(field) : "";

  const line1 = decode(address.line_1);
  const line2 = decode(address.line_2);
  const line3 = decode(address.line_3);
  const postTown = decode(address.post_town);
  const county = decode(address.county);
  const postcode = decode(address.postcode).toUpperCase();

  const parts: string[] = [line1];

  const addIf = (value: string): void => {
    if (value) {
      parts.push(value);
    }
  };

  if (isHeadline) {
    addIf(line2);
  } else {
    addIf(line2);
    addIf(line3);
    parts.push(postTown);
    addIf(county);
  }

  parts.push(postcode);
  return parts.join(", ");
};

export const formatDateWithNumbers = (dateString: string) => {
  if (!dateString) {
    dateString = new Date().toISOString();
  }
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const htmlEntities: Record<string, string> = {
  "&amp;": "&",
  "&lt;": "<",
  "&gt;": ">",
  "&quot;": '"',
  "&#39;": "'",
  "&#x27;": "'",
};

export const preprocessDecodedHTML = (val: unknown): string => {
  if (typeof val !== "string") {
    console.warn("preprocessDecodedHTML received a non-string value:", val);
    return String(val);
  }

  return val.replace(
    /&amp;|&lt;|&gt;|&quot;|&#39;|&#x27;/g,
    (match) => htmlEntities[match],
  );
};

export const formatCreatedDate = (createdAt: Date): string => {
  const now = new Date();
  const isToday = createdAt.toDateString() === now.toDateString();

  // If the transaction was created today, display the number of hours elapsed
  if (isToday) {
    const hoursElapsed = Math.floor(
      (now.getTime() - createdAt.getTime()) / (1000 * 60 * 60),
    );
    return `${hoursElapsed} hour${hoursElapsed !== 1 ? "s" : ""} ago`;
  }

  // Otherwise, display the full date
  return createdAt.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};
