import { useState } from "react";
import { Typography, Icons, TextInputIconProps } from "@pongo";

import { CharlieOutput } from "./CharlieOutput";

export const AskCharlie = () => {
  const [query, setQuery] = useState("");
  // messageStep = 0 means no message yet (show title/pills),
  // 1 means first message is shown,
  // 2 means second message is shown.
  const [messageStep, setMessageStep] = useState(0);
  // Only used on the first click to animate fading out the non-input content.
  const [fadeOut, setFadeOut] = useState(false);

  const handleButtonClick = () => {
    // Clear the input field.
    setQuery("");
    if (messageStep === 0) {
      // On first click, fade out the non-input content.
      setFadeOut(true);
      // After 500ms, show the first message.
      setTimeout(() => {
        setMessageStep(1);
      }, 500);
    } else if (messageStep === 1) {
      // On second click, show the second message.
      setMessageStep(2);
    }
  };

  // Animate the input container from center to bottom once any message is shown.
  const inputContainerClasses = `bg-brand-heavy-warmth-75 fixed left-0 right-0 w-full flex flex-col items-center mx-auto transition-all duration-500 ${
    messageStep > 0 ? "bottom-0 translate-y-0" : "translate-y-1/2 bottom-1/2"
  }`;

  return (
    <div className="absolute left-0 right-0 top-[130px] w-full flex flex-col items-center justify-center pb-[200px]">
      {messageStep === 0 && (
        <div
          className={`transition-opacity duration-500 ${
            fadeOut ? "opacity-0" : "opacity-100"
          }`}
        >
          <AskCharlieTitle />
          <div className="flex flex-row space-x-5 mb-[12px]">
            <Pill
              iconName="Document"
              text="Give me a progress update on a transaction"
            />
            <Pill iconName="Alarm" text="Identify problem properties" />
            <Pill
              iconName="Eye"
              text="Show me properties requiring an action from me"
            />
          </div>
        </div>
      )}

      {messageStep >= 1 && (
        <>
          {/* First Message */}
          <UserMessageBubble message="What is this about an engrossment fee on Chetwode Road? The buyer is upset? Do I need to take action or has it been resolved?" />
          <div className="w-full max-w-[750px] flex justify-start mb-[24px]">
            <ThinkingIndicator />
            <div className="w-full">
              <CharlieOutput response={messageStep} />
            </div>
          </div>
        </>
      )}

      {messageStep >= 2 && (
        <>
          {/* Second Message */}
          <UserMessageBubble message="What did the lawyers say that makes you think that the issue is resolved?" />
          <div className="w-full max-w-[750px] flex justify-start mb-[24px]">
            <ThinkingIndicator />
            <div className="w-full">
              <CharlieOutput response={messageStep} />
            </div>
          </div>
        </>
      )}

      {/* Input container and small text – animated from center to bottom */}
      <div className={inputContainerClasses}>
        <MessageCharlie
          query={query}
          handleButtonClick={handleButtonClick}
          setQuery={setQuery}
        />
        <SmallText />
      </div>
    </div>
  );
};

export const ThinkingIndicator = () => (
  <>
    <div
      className="rounded-full relative top-[5px] ml-[-32px] w-[18px] h-[18px] bg-brand-heavy-teal-100/50 mr-[12px]"
      style={{
        animation: "pulseScale 1s ease-in-out 5, fadeOut 1s ease forwards 5s",
      }}
    ></div>
    <style>{`
        @keyframes pulseScale {
          0% { transform: scale(1); }
          55% { transform: scale(0.75); }
          100% { transform: scale(1); }
        }
        @keyframes fadeOut {
          from { opacity: 1; }
          to { opacity: 0; }
        }
      `}</style>
  </>
);

const SmallText = () => (
  <Typography
    variant="xs"
    weight="regular"
    className="text-brand-heavy-teal-100/50 mt-[8px] mb-[32px] text-center"
  >
    Charlie can make mistakes. Double check any transaction details with Propelr
    or the relevant solicitor.
  </Typography>
);

interface MessageCharlieProps {
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  handleButtonClick: () => void;
}

const MessageCharlie = ({
  query,
  setQuery,
  handleButtonClick,
}: MessageCharlieProps) => (
  <div className="relative w-full max-w-[750px] mx-[50px] pt-[10px]">
    <input
      type="text"
      value={query}
      onChange={(e) => setQuery(e.target.value)}
      placeholder="Message Charlie"
      className="transition-colors duration-300 w-full pl-[32px] pr-[60px] py-[24px] rounded-2xl bg-brand-heavy-warmth-25 focus:bg-ui-white-100 border border-brand-heavy-warmth-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent"
    />

    <button
      className="absolute top-[34px] right-[32px]"
      onClick={handleButtonClick}
    >
      <Icons
        iconName="Up"
        size="24"
        className="stroke-brand-heavy-teal-75 fill-none hover:opacity-80"
      />
    </button>
  </div>
);

interface UserMessageBubbleProps {
  message: string;
}

const UserMessageBubble = ({ message }: UserMessageBubbleProps) => (
  <div className="w-full max-w-[750px] flex justify-end">
    <div className="p-[24px] bg-brand-sand-100/50 rounded-2xl w-[500px] mb-[60px]">
      <Typography
        variant="base"
        weight="semi-bold"
        className="flex text-brand-heavy-teal-100/80 leading-[150%]"
      >
        {message}
      </Typography>
    </div>
  </div>
);

const Pill = ({ iconName, text }: { iconName: string; text: string }) => (
  <a
    href=""
    className="cursor flex items-center text-nowrap rounded-full border-2 border-brand-heavy-warmth-100 hover:border-brand-heavy-teal-100/20 w-full px-[18px] py-[12px] mb-[12px]"
  >
    <div className="relative top-[-1px]">
      <Icons
        iconName={iconName as TextInputIconProps}
        size="16"
        colour="#01323c80"
      />
    </div>

    <Typography
      variant="sm"
      weight="regular"
      className="text-brand-heavy-teal-100/50 ml-[8px]"
    >
      {text}
    </Typography>
  </a>
);

const AskCharlieTitle = () => (
  <div className="flex justify-center pt-[300px]">
    <Typography
      variant="h2"
      weight="semi-bold"
      className="flex text-brand-heavy-teal-100/80 mb-[100px] font-display"
    >
      Ask Charlie
      <span className="px-[12px] relative top-[-10px] left-[-5px]">
        <Icons iconName="Chat" size="42" />
      </span>
      about a live transaction
    </Typography>
  </div>
);
